import { createApiMaker } from "@36node/redux";

import * as types from "./types";
import * as sdk from "../sdk";

import { userSchema, livedataSchema, extraSchema } from "../schemas";

export const auth = {
  makeLogin: createApiMaker(types.LOGIN, sdk.auth.session.createSession),
  makeLogout: createApiMaker(types.LOGOUT, sdk.auth.session.deleteSession),
  makeRefresh: createApiMaker(types.REFRESH, sdk.auth.session.getSession),
  makeUnAuth: createApiMaker(types.UN_AUTH, sdk.auth.session.unauth),
  makeCaptcha: createApiMaker(types.CAPTCHA, sdk.getCaptcha),
};

export const core = {
  livedata: {
    makeListLivedata: createApiMaker(
      types.CORE_LIVE_LIST_LIVE,
      sdk.core.livedata.listLivedata,
      [livedataSchema]
    ),
    makeCreateLivedata: createApiMaker(
      types.CORE_LIVE_CREATE_LIVE,
      sdk.core.livedata.createLivedata,
      livedataSchema
    ),
    makeDeleteLivedata: createApiMaker(
      types.CORE_LIVE_DELETE_LIVE,
      sdk.core.livedata.deleteLivedata
    ),
    makeShowLivedata: createApiMaker(
      types.CORE_LIVE_SHOW_LIVE,
      sdk.core.livedata.showLivedataById,
      livedataSchema
    ),
    makeUpdateLivedata: createApiMaker(
      types.CORE_LIVE_UPDATE_LIVE,
      sdk.core.livedata.updateLivedata,
      livedataSchema
    ),
    makeCreateLiveEvent: createApiMaker(
      types.CORE_LIVE_CREATE_LIVEEVENT,
      sdk.core.livedata.createLivedataEvent
    ),
  },
  extra: {
    makeListExtra: createApiMaker(
      types.CORE_LIVE_LIST_EXTRA,
      sdk.core.extra.listExtra,
      [extraSchema]
    ),
    makeCreateExtra: createApiMaker(
      types.CORE_LIVE_CREATE_EXTRA,
      sdk.core.extra.createExtra,
      extraSchema
    ),
    makeShowExtra: createApiMaker(
      types.CORE_LIVE_SHOW_EXTRA,
      sdk.core.extra.showExtraById,
      extraSchema
    ),
    makeUpdateExtra: createApiMaker(
      types.CORE_LIVE_UPDATE_EXTRA,
      sdk.core.extra.updateExtra,
      extraSchema
    ),
    makeDeleteExtra: createApiMaker(
      types.CORE_LIVE_DELETE_EXTRA,
      sdk.core.extra.deleteExtra
    ),
    makeCreateExtraEvent: createApiMaker(
      types.CORE_LIVE_CREATE_EXTRAEVENT,
      sdk.core.extra.createExtraEvent
    ),
  },
  summary: {
    makeGetLiveSummary: createApiMaker(
      types.CORE_LIVE_SUMMARY,
      sdk.core.summary.getLivedataSummary
    ),
    makeGetExtraSummary: createApiMaker(
      types.CORE_EXTRA_SUMMARY,
      sdk.core.summary.getExtraSummary
    ),
  },
  feedback: {
    makeListFeedback: createApiMaker(
      types.CORE_LIVE_LIST_FEEDBACK,
      sdk.core.feedback.listFeedback
    ),
    makeCreateFeedback: createApiMaker(
      types.CORE_LIVE_CREATE_FEEDBACK,
      sdk.core.feedback.createFeedback
    ),
  },
};

export const user = {
  makeListUsers: createApiMaker(
    types.AUTH_USER_LIST_USERS,
    sdk.auth.user.listUsers,
    [userSchema]
  ),
  makeCreateUser: createApiMaker(
    types.AUTH_USER_CREATE_USER,
    sdk.auth.user.createUser,
    userSchema
  ),
  makeUpdateUser: createApiMaker(
    types.AUTH_USER_UPDATE_USER,
    sdk.auth.user.updateUser,
    userSchema
  ),
  makeDeleteUser: createApiMaker(
    types.AUTH_USER_DELETE_USER,
    sdk.auth.user.deleteUser
  ),
  makeGetUser: createApiMaker(
    types.AUTH_USER_GET_USER,
    sdk.auth.user.getUser,
    userSchema
  ),
};

export const session = {
  makeListSession: createApiMaker(
    types.AUTH_SESSION_LIST_SESSIONS,
    sdk.auth.session.listSessions
  ),
  makeListLoginHistory: createApiMaker(
    types.AUTH_USER_LOGIN_HISTORY,
    sdk.auth.session.getLoginHistory
  ),
};

export const operations = {
  makeOperations: createApiMaker(types.CORE_USER_OPERATIONS, sdk.getOperations),
};
