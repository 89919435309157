import React from "react";
import { Form, Row, Col, Button, Card, message } from "antd";
import { find, isEqual, get } from "lodash";

import { Fields } from "../../../../components/fileds";
import {
  UploadMenu,
  FormItemLayout,
  InnerFormItemLayout,
} from "../../../../constants";
import { createForm } from "@36node/redux-antd";
import { renderFile } from "../../../../lib";
import { MAX_FILE_SIZE, SYS_TYPE } from "../../../../config";

const key = "info";

@createForm("upload.info")
export default class InfoForm extends React.PureComponent {
  get menu() {
    return find(UploadMenu, { key });
  }

  handleSave = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        values.step = this.menu.step;
        this.props.saveDraft(values);

        this.initialValues = this.props.form.getFieldsValue();
      }
    });
  };

  check = () => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        message.success(`${this.menu.title}填写正确`);
      }
    });
  };

  componentDidUpdate(prevProps) {
    const prevId = get(prevProps, "data.id");
    const curId = get(this.props, "data.id");

    // id 发生改变时， 重置initialValue
    if (prevId !== curId) {
      this.initialValues = this.props.form.getFieldsValue();
    }
  }

  componentDidMount() {
    this.initialValues = this.props.form.getFieldsValue();
  }

  componentWillUnmount() {
    this.initialValues = undefined;
  }

  render() {
    const {
      form,
      data,
      user,
      showSave = true,
      showCheck = true,
      noRequire = false,
      disabledFields = [],
      formRef,
    } = this.props;

    if (formRef) {
      // 判断form的values是否发生过改变
      form.isDirty = () => {
        const curValues = form.getFieldsValue();

        return !isEqual(curValues, this.initialValues);
      };
      formRef(form);
    }

    return (
      <Card
        title={this.menu.title}
        className="box"
        extra={
          showCheck && (
            <div className="form-check" onClick={this.check}>
              校验
            </div>
          )
        }
      >
        <Form labelAlign="left" {...FormItemLayout}>
          <Fields.Text
            form={form}
            label="项目名称"
            name="title"
            required={!noRequire}
            initialValue={data.title}
            disabled={disabledFields.includes("title")}
            placeholder={
              SYS_TYPE === "LIVEDATA"
                ? "可反映该传统知识或技术的特点，一般小于20字"
                : "能反映项目特点的名称，一般不超过20字"
            }
          />
          <Fields.Type
            form={form}
            label="项目类型"
            name="type"
            required={!noRequire}
            initialValue={data.type}
            disabled={disabledFields.includes("type")}
          />
          <Row gutter={8}>
            <Col span={12}>
              <Fields.Select
                form={form}
                label={SYS_TYPE === "LIVEDATA" ? "所属地区" : "申报地区"}
                name="district"
                required={!noRequire}
                initialValue={data.district || (user && user.province)}
                layout={InnerFormItemLayout}
                disabled={true}
              />
            </Col>
            <Col span={12}>
              <Fields.Text
                form={form}
                label="项目编码"
                name="no"
                initialValue={data.no || "项目提交审核后自动生成"}
                placeholder=""
                layout={InnerFormItemLayout}
                disabled={true}
              />
            </Col>
          </Row>
          {SYS_TYPE === "LIVEDATA" && (
            <Fields.TextArea
              form={form}
              label="方名"
              name="name"
              initialValue={data.name}
              disabled={disabledFields.includes("name")}
            />
          )}
          <Fields.TextArea
            form={form}
            label="适应范围"
            name="indication"
            placeholder={
              SYS_TYPE === "LIVEDATA"
                ? ""
                : "拟申报技术的适应人群、性别、年龄、病种、症状等"
            }
            initialValue={data.indication}
            disabled={disabledFields.includes("indication")}
          />
          <Fields.TextArea
            form={form}
            label="使用禁忌"
            name="contraindication"
            placeholder={
              SYS_TYPE === "LIVEDATA"
                ? "无特殊禁忌填（无）；有禁忌要详述"
                : "拟申报技术的禁忌人群、性别、年龄、病种、症状等"
            }
            initialValue={data.contraindication}
            disabled={disabledFields.includes("contraindication")}
          />
          <Fields.TextArea
            form={form}
            label="应用规范"
            name="applyRule"
            placeholder={
              SYS_TYPE === "LIVEDATA"
                ? "如方剂制作步骤、手法操作流程等"
                : "如手法操作流程，外用药物的使用、制备步骤等"
            }
            initialValue={data.applyRule}
            disabled={disabledFields.includes("applyRule")}
          />
          <Fields.File
            form={form}
            label="应用规范相关证据"
            name="applyRuleAttach"
            title="上传文件"
            initialValue={renderFile(data.applyRuleAttach)}
            maxFileSize={MAX_FILE_SIZE}
            extra="不允许使用压缩文件，单个文件大小不超过1GB。推荐扩展名: .doc, .pdf, .jpg, .xlsx, .jpeg, .png, .mp4, .mp3"
          />
          {SYS_TYPE === "LIVEDATA" && (
            <Fields.Text
              form={form}
              label="应用地区"
              name="area"
              initialValue={data.area}
              disabled={disabledFields.includes("area")}
              placeholder="如国内省、市、县（区）、乡，海外国家、地区等"
            />
          )}
          {SYS_TYPE === "LIVEDATA" && (
            <Row gutter={8}>
              <Col span={12}>
                <Fields.Text
                  form={form}
                  label="应用时间"
                  name="applyAt"
                  initialValue={data.applyAt}
                  disabled={disabledFields.includes("applyAt")}
                  layout={InnerFormItemLayout}
                />
              </Col>
              <Col span={12}>
                <Fields.File
                  form={form}
                  label="相关证据"
                  name="applyAttach"
                  title="上传文件"
                  layout={InnerFormItemLayout}
                  initialValue={renderFile(data.applyAttach)}
                  maxFileSize={MAX_FILE_SIZE}
                  extra="不允许使用压缩文件，单个文件大小不超过1GB。推荐扩展名: .doc, .pdf, .jpg, .xlsx, .jpeg, .png, .mp4, .mp3"
                />
              </Col>
            </Row>
          )}
          <Row gutter={8}>
            <Col span={12}>
              <Fields.Text
                form={form}
                label="传承时间"
                name="inheritTime"
                initialValue={data.inheritTime}
                disabled={disabledFields.includes("inheritTime")}
                layout={InnerFormItemLayout}
                placeholder={SYS_TYPE === "LIVEDATA" ? "50年以上" : ""}
              />
            </Col>
            <Col span={12}>
              <Fields.Text
                form={form}
                label="传承代数"
                name="inheritGeneration"
                initialValue={data.inheritGeneration}
                disabled={disabledFields.includes("inheritGeneration")}
                layout={InnerFormItemLayout}
                placeholder={SYS_TYPE === "LIVEDATA" ? "三代以上" : ""}
              />
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12}>
              <Fields.Text
                form={form}
                label="传承谱系"
                name="inheritSeries"
                initialValue={data.inheritSeries}
                disabled={disabledFields.includes("inheritSeries")}
                layout={InnerFormItemLayout}
              />
            </Col>
            <Col span={12}>
              <Fields.File
                form={form}
                label="相关证据"
                name="inheritAttach"
                title="上传文件"
                layout={InnerFormItemLayout}
                initialValue={renderFile(data.inheritAttach)}
                maxFileSize={MAX_FILE_SIZE}
                extra="不允许使用压缩文件，单个文件不超过1G，证明传承时间、代数、谱系的相关文献、文物、图像、音视频、属地政府证明等。推荐扩展名: .doc, .pdf, .jpg, .xlsx, .jpeg, .png, .mp4, .mp3"
              />
            </Col>
          </Row>
          <Fields.TextArea
            form={form}
            label="内容摘要"
            name="desc"
            placeholder={
              SYS_TYPE === "LIVEDATA"
                ? "400字以内，包括传统知识来源、适应范围、禁忌症、基本应用规范和主要特点等"
                : "400字以内，包括该技术的来源、适应范围、禁忌症、主要特点等"
            }
            initialValue={data.desc}
            maxLength={400}
            disabled={disabledFields.includes("desc")}
          />
          <Fields.TextArea
            form={form}
            label="项目实质内容"
            name="pureContent"
            placeholder={
              SYS_TYPE === "LIVEDATA"
                ? "详细说明该传统知识或技术的传承源流、理论基础、使用方法、关键技术及使用要点、适应症、禁忌症、注意事项，以及其他与本项目密切相关的内容"
                : "1000字以内，详细说明该技术的传承源流、理论基础、使用方法、关键技术及使用要点，适应症、禁忌症、注意事项，以及其他与本项目密切相关的内容"
            }
            initialValue={data.pureContent}
            maxLength={1000}
            disabled={disabledFields.includes("pureContent")}
          />
          {SYS_TYPE === "LIVEDATA" && (
            <Fields.File
              form={form}
              label="相关研究证明资料，如古籍、论文、专著等"
              name="certiAttach"
              extra="如有例证，可参考如下格式和内容，如数量、患者姓名、身份信息、诊断证明（上传图文，什么级别医院提供的）、治疗方法、疗效、辅助检查等信息，推荐扩展名: .doc, .pdf, .jpg, .xlsx, .jpeg, .png, .mp4, .mp3, 不允许使用压缩文件，单个文件大小不超过1GB"
              title="上传文件"
              initialValue={renderFile(data.certiAttach)}
              maxFileSize={MAX_FILE_SIZE}
            />
          )}
          <Fields.TextArea
            form={form}
            label="主要特征及重要价值"
            name="feature"
            placeholder="从以下几个方面撰写： 具有较大开发利用的医学价值, 具有较大开发利用的产业价值, 重要文化价值, 面临失传，确需传承发展, 独特性, 共识性高、代表性强, 其他重要价值"
            initialValue={data.feature}
            required={!noRequire}
            maxLength={1000}
            disabled={disabledFields.includes("feature")}
          />
          {SYS_TYPE === "LIVEDATA" && (
            <Fields.File
              form={form}
              label="病例附件"
              name="caseAttach"
              extra="请上传真实病例附件，推荐扩展名: .doc, .pdf, .jpg, .xlsx, .jpeg, .png, .mp4, .mp3, 不允许使用压缩文件，单个文件大小不超过1GB"
              title="上传文件"
              initialValue={renderFile(data.caseAttach)}
              maxFileSize={MAX_FILE_SIZE}
            />
          )}
          <Fields.File
            form={form}
            label="其他附件"
            name="otherAttach"
            extra="以上内容之外，认为有助于证明项目的材料，不允许使用压缩文件，单个文件大小不超过1GB，推荐扩展名: .doc, .pdf, .jpg, .xlsx, .jpeg, .png, .mp4, .mp3"
            title="上传文件"
            initialValue={renderFile(data.otherAttach)}
            maxFileSize={MAX_FILE_SIZE}
          />
          <Fields.TextArea
            form={form}
            label="备注"
            name="consideration"
            placeholder="请据实填写；没有填（无）"
            initialValue={data.consideration}
            disabled={disabledFields.includes("consideration")}
          />
        </Form>

        {showSave && (
          <div className="btns">
            <Button
              size="large"
              type="primary"
              loading={this.props.loading}
              onClick={this.handleSave}
            >
              保存并填写下一步
            </Button>
          </div>
        )}
      </Card>
    );
  }
}
