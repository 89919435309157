import React from "react";
import { connect } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";
import {
  Button,
  Card,
  Alert,
  message,
  Spin,
  Table,
  Pagination,
  Popconfirm,
} from "antd";
import { isEmpty, isEqual, get } from "lodash";
import qs from "qs";
import {
  makeApiSelector,
  reputApi,
  tapOnLatest,
  withSaga,
} from "@36node/redux";
import { createForm } from "@36node/redux-antd";
import { withSession } from "@36node/redux-session";

import { core } from "../../../actions/api";
import { getTypeName, renderState } from "../../../lib";
import { getSearch } from "../../../lib/history";
import { LivedataStates } from "../../../constants";
import Container from "../../../components/layout/container";
import FilterForm from "../../../components/filter-form";
import { CORE_LIVE_DELETE_EXTRA } from "../../../actions/types";

const LIMIT = 10;
const tabs = ["type", "title", "district", "owner", "id", "publishedAt"];

const listExtra = core.extra.makeListExtra("apply.add.list");
const listExtraSelector = makeApiSelector("apply.add.list");
const deleteExtra = core.extra.makeDeleteExtra("apply.add.delete");

@withSession("session")
@withSaga(
  tapOnLatest(CORE_LIVE_DELETE_EXTRA.SUCCESS, "apply.add.delete", function*(
    action
  ) {
    message.success("删除成功");
    yield reputApi(listExtra());
  })
)
@createForm("add.list")
@connect((state, props) => ({
  loading: listExtraSelector(state).loading || false,
  data: listExtraSelector(state).result || [],
  total: listExtraSelector(state).total || 0,
  user: get(props.session, "result.user") || {},
}))
export default class extends React.PureComponent {
  get columns() {
    return [
      {
        title: "项目名称",
        dataIndex: "title",
        key: "title",
      },
      {
        title: "项目编号",
        dataIndex: "no",
        key: "no",
      },
      {
        title: "项目类型",
        dataIndex: "type",
        key: "type",
        render: getTypeName,
      },
      {
        title: "申请状态",
        dataIndex: "state",
        key: "state",
        render: renderState,
      },
      {
        title: "申报人姓名",
        dataIndex: "owner",
        key: "owner",
      },
      {
        title: "操作",
        key: "action",
        render: record => {
          switch (record.state) {
            case LivedataStates.DRAFT:
            case LivedataStates.RETURNED:
              return (
                <span>
                  <NavLink to={`/apply/add/edit/${record.id}`}>修改</NavLink>
                  <Popconfirm
                    title="确认删除持续添加?"
                    onConfirm={() => this.delete(record.id)}
                    okText="确认"
                    cancelText="取消"
                  >
                    <Button
                      type="link"
                      style={{ marginLeft: "12px", color: "red" }}
                    >
                      删除
                    </Button>
                  </Popconfirm>
                </span>
              );
            default:
              return (
                <NavLink to={`/apply/add/detail/${record.id}`}>
                  查看详情
                </NavLink>
              );
          }
        },
      },
    ];
  }

  componentDidMount() {
    if (isEmpty(this.props.location.search)) {
      this.updateUrl(this.getQueryFromLocation());
    }

    this.fetchList();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(this.props.location, prevProps.location)) {
      this.fetchList();
    }
  }

  getQueryFromLocation = () => {
    let query = getSearch(this.props.location);
    const { offset, limit, state } = query;
    query.offset = offset || 0;
    query.limit = limit || LIMIT;
    query.state = state;
    return query;
  };

  updateUrl = query => {
    this.props.history.push(`/apply/add/list?${qs.stringify(query)}`);
  };

  fetchList = (query = {}) => {
    const q = {
      ...this.getQueryFromLocation(),
      ...query,
      createdBy: this.props.user.id,
    };
    this.props.dispatch(
      listExtra({
        query: q,
      })
    );
  };

  handleSubmit = values => {
    this.updateUrl({ ...this.getQueryFromLocation(), ...values });
  };

  handleReset = () => {
    this.props.form.resetFields();
    const state = this.getQueryFromLocation().state;
    this.updateUrl({
      state,
      offset: 0,
      limit: LIMIT,
    });
  };

  delete = extraId => {
    this.props.dispatch(
      deleteExtra({
        extraId,
      })
    );
  };

  onChangePage = (page, pageSize) => {
    const query = this.getQueryFromLocation();
    query.offset = (page - 1) * pageSize;
    query.limit = pageSize;
    this.updateUrl(query);
  };

  render() {
    const { form, loading, data, total, location } = this.props;
    const { limit, offset } = this.getQueryFromLocation();

    return (
      <Container>
        <Alert
          message="持续添加使用场景：终审通过审核的项目，需要补充病例或申报人信息的时候，可以发起持续添加申请。审核通过后，在原有项目最后会加上持续添加的内容。"
          type="info"
          showIcon
          style={{ marginBottom: 24 }}
        />
        <StyledCard
          title="持续添加申请列表"
          extra={
            <Link
              to={{
                pathname: "/apply/add/new",
                state: { background: location },
              }}
            >
              <Button type="primary" icon="plus">
                发起持续添加申请
              </Button>
            </Link>
          }
        >
          <div className="form">
            <FilterForm
              form={form}
              handleSubmit={this.handleSubmit}
              handleReset={this.handleReset}
              formItems={tabs}
              query={this.getQueryFromLocation()}
            />
          </div>
          <div className="content">
            <Spin spinning={loading}>
              <Table
                columns={this.columns}
                dataSource={data}
                pagination={false}
                rowKey="id"
              />
              {!loading && data.length > 0 && (
                <div className="pagination-container">
                  <Pagination
                    current={parseInt(offset, 10) / LIMIT + 1}
                    pageSize={parseInt(limit, 10)}
                    total={total}
                    onChange={this.onChangePage}
                  />
                </div>
              )}
            </Spin>
          </div>
        </StyledCard>
      </Container>
    );
  }
}

const StyledCard = styled(Card)`
  .content {
    background: white;
  }

  .pagination-container {
    padding: 24px;
    text-align: right;
  }
`;
