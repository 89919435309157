/* eslint-disable no-undef */
import React from "react";
import { connect } from "react-redux";
import { withSaga, tapOnLatest } from "@36node/redux";
import {
  Form,
  Icon,
  Input,
  Button,
  Checkbox,
  Layout,
  message,
  Row,
  Col,
  Tooltip,
} from "antd";
import styled from "styled-components";
import { createForm } from "@36node/redux-antd";

import Center from "../../components/layout/center";
import { auth } from "../../actions/api";
import { AUTH_PROVIDER, TITLE, SYS_TYPE } from "../../config";
import { LOGIN } from "../../actions/types";
import { makeApiSelector } from "@36node/redux";
import moment from "moment";

const FormItem = Form.Item;
const login = auth.makeLogin("session");
const captcha = auth.makeCaptcha("captcha");
const loginSelector = makeApiSelector("session");
const captchaSelector = makeApiSelector("captcha");

// 登录锁定截止时间
const LOGIN_LOCK_DDL_KEY = "login_lock_ddl";
let loginErrorCount = 0;
let countDown = 0;

@createForm("login")
@withSaga(
  tapOnLatest(LOGIN.FAILURE, "session", function(action) {
    if (loginErrorCount + 1 >= 5) {
      message.error(`失败次数太多，请30分钟之后再试`);
      countDown = 1800;
      // 设置登录锁定截止时间
      localStorage.setItem(
        LOGIN_LOCK_DDL_KEY,
        moment()
          .add(30, "minute")
          .toISOString()
      );
    } else {
      // Notify({ type: 'danger', message: err })
      message.error("用户名、密码或验证码错误!");
    }
    loginErrorCount = loginErrorCount >= 4 ? 0 : loginErrorCount + 1;
  })
)
@connect(state => {
  const loginResult = loginSelector(state);
  const { result: captchaResult = {} } = captchaSelector(state) || {};

  return {
    loading: loginResult.loading,
    captchaResult,
  };
})
export default class Login extends React.PureComponent {
  componentDidMount() {
    // if (window.location.search.indexOf("validate") !== -1) {
    //   message.success(
    //     "2021年11月21日后暂停申报人及各省业务管理员使用，如有疑问，请咨询中医药管理局相关管理员"
    //   );
    // }
    this.loadDelayTime();
    this.getCaptcha();
  }

  loadDelayTime = () => {
    // 检查是否有登录锁定时间
    if (localStorage.getItem(LOGIN_LOCK_DDL_KEY)) {
      const ddl = moment(localStorage.getItem(LOGIN_LOCK_DDL_KEY));

      const now = moment();
      // 未到ddl
      if (ddl.isAfter(now)) {
        countDown = ddl.diff(now, "second");
        message.error(`失败次数太多，请${countDown}秒钟之后再试`);
      }
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    const from = "/";
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.dispatch(
          login(
            {
              body: {
                ...values,
                provider: AUTH_PROVIDER,
                captchaKey: this.captchaKey,
              },
            },
            { from, remember: values.remember }
          )
        );
      }
    });
  };

  fileds = {
    Username: ({ initialValue }) =>
      this.props.form.getFieldDecorator("username", {
        initialValue,
        rules: [
          {
            required: true,
            message: "请输入用户名/邮箱!",
          },
        ],
      })(<Input prefix={<Icon type="user" />} placeholder="用户名/邮箱" />),
    Password: ({ initialValue }) =>
      this.props.form.getFieldDecorator("password", {
        initialValue,
        rules: [
          {
            required: true,
            message: "请输入密码!",
          },
        ],
      })(
        <Input
          prefix={<Icon type="lock" />}
          type="password"
          placeholder="密码"
        />
      ),
    CaptchaCode: ({ loading, captchaSvg, onClick }) => {
      return (
        <Row>
          <Col span={16}>
            {this.props.form.getFieldDecorator("captchaCode", {
              rules: [{ required: true, message: "请输入验证码" }],
            })(
              <Input
                prefix={<Icon type="lock" style={inputPrefixIconStyle} />}
                placeholder="验证码"
                disabled={loading}
              />
            )}
          </Col>

          <Col span={8} style={{ marginTop: -10 }}>
            <Tooltip title="点击刷新验证码">
              <div
                dangerouslySetInnerHTML={{ __html: captchaSvg }}
                onClick={onClick}
                style={{ cursor: "pointer" }}
              />
            </Tooltip>
          </Col>
        </Row>
      );
    },
    RememberMe: ({ initialValue }) =>
      this.props.form.getFieldDecorator("remember", {
        initialValue,
        valuePropName: "checked",
      })(<Checkbox>自动登录</Checkbox>),
  };

  getCaptcha = () => {
    this.captchaKey = Date.now();
    this.props.dispatch(captcha({ key: this.captchaKey }));
  };

  render() {
    const { Username, Password, RememberMe, CaptchaCode } = this.fileds;
    const { loading, captchaResult } = this.props;
    const { message: captchaSvg } = captchaResult;

    return (
      <LoginPage>
        <Center>
          <LoginBox>
            <div className="login-title">
              {SYS_TYPE === "LIVEDATA" && (
                <img src="/images/logo1.png" alt="" />
              )}
              {TITLE}
            </div>
            <Form onSubmit={this.handleSubmit} className="login-form">
              <FormItem className="username">
                <Username />
              </FormItem>
              <FormItem className="password">
                <Password />
              </FormItem>
              <FormItem className="captcha">
                <CaptchaCode
                  loading={loading}
                  captchaSvg={captchaSvg}
                  onClick={this.getCaptcha}
                />
              </FormItem>

              <FormItem className="remember">
                <RememberMe initialValue={true} />
              </FormItem>
              <FormItem className="submit">
                <Button
                  disabled={countDown > 0}
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                >
                  登录
                </Button>
              </FormItem>
            </Form>
          </LoginBox>
        </Center>
        <div className="login-footer">
          {SYS_TYPE === "LIVEDATA" && (
            <span style={{ marginRight: 48 }}>
              Copyright© 2020 国家中医药管理局中医药传统知识保护研究中心
            </span>
          )}
          <a
            href="http://www.beian.miit.gov.cn"
            target="_blank"
            rel="noopener noreferrer"
            alt=""
          >
            京ICP备12038572号-3
          </a>
        </div>
      </LoginPage>
    );
  }
}

const LoginPage = styled(Layout)`
  background: url("/images/login-bg.jpg") no-repeat;
  background-size: cover;
  background-position: center;

  .login-footer {
    position: absolute;
    bottom: 24px;
    width: 100%;
    text-align: center;
    color: rgba(255, 255, 255, 0.8);
    font-size: 14px;
  }
`;

const LoginBox = styled("div")`
  background-color: white;
  padding: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -100px;

  .login-title {
    text-align: center;
    color: #1d76de;
    font-size: 30px;
    font-weight: bold;
    width: 100%;
    margin-bottom: 48px;

    img {
      height: 100px;
      display: block;
      margin: 0 auto 24px auto;
    }
  }

  .login-form {
    width: 400px;

    input {
      font-size: 16px;
      border-color: #1d76de;
    }

    .captcha {
      margin-bottom: 0;
    }

    .submit {
      margin-bottom: 0;
    }

    .ant-checkbox-wrapper {
      color: #1d76de;
    }

    .anticon {
      color: rgba(0, 0, 0, 0.25);
      font-size: 16px;
    }

    .ant-input {
      padding-left: 40px;
    }
  }

  .login-form-button {
    width: 100%;
    font-size: 16px;
  }
`;

const inputPrefixIconStyle = {
  fontSize: "14px",
  color: "rgba(0,0,0,.25)",
};
