import React from "react";
import { Input } from "antd";
import "./index.less";

const { TextArea } = Input;

class CountedTextarea extends TextArea {
  valueLength() {
    if (this.state.value && this.state.value.length) {
      return this.state.value.length;
    }
    return 0;
  }
  render() {
    return (
      <div className="countedtextareaWrapper">
        {super.render()}
        <div className="border" />
        <span className="countWrapper">
          <span>{this.valueLength()}</span>/<span>{this.props.maxLength}</span>
        </span>
      </div>
    );
  }
}

export default CountedTextarea;
