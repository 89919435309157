import React from "react";
import { Alert, Form, Button, Card, message } from "antd";
import { createForm } from "@36node/redux-antd";
import { find, isEqual, get, includes } from "lodash";
import moment from "moment";

import { Fields } from "../../../../components/fileds";
import { renderFile, ymdhm } from "../../../../lib";
import { UploadMenu, FormItemLayout } from "../../../../constants";
import { MAX_FILE_SIZE, SYS_TYPE } from "../../../../config";

const key = "host";
const getStep = formKey => find(UploadMenu, { key: formKey }).step;

@createForm("upload.host")
export default class HostForm extends React.PureComponent {
  get menu() {
    return find(UploadMenu, { key });
  }

  handleSave = e => {
    e.preventDefault();

    if (!includes(this.props.data.step, getStep("info"))) {
      message.error("请先完成填写基本信息");
      return;
    }

    if (
      SYS_TYPE === "TREATMENT" &&
      !includes(this.props.data.step, getStep("evaluation"))
    ) {
      message.error("请先完成填写项目评价信息");
      return;
    }

    if (!includes(this.props.data.step, getStep("user"))) {
      message.error("请先完成填写申报人信息");
      return;
    }

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        values.step = this.menu.step;
        values.hostTime = moment();
        this.props.saveDraft(values);

        // 重置 initialValues
        this.initialValues = this.props.form.getFieldsValue();
      }
    });
  };

  check = () => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        message.success(`${find(UploadMenu, { key }).title}填写正确`);
      }
    });
  };

  componentDidUpdate(prevProps) {
    const prevId = get(prevProps, "data.id");
    const curId = get(this.props, "data.id");

    // id 发生改变时， 重置initialValue
    if (prevId !== curId) {
      this.initialValues = this.props.form.getFieldsValue();
    }
  }

  componentDidMount() {
    this.initialValues = this.props.form.getFieldsValue();
  }

  componentWillUnmount() {
    this.initialValues = undefined;
  }

  render() {
    const { form, data, formRef } = this.props;

    if (formRef) {
      // 判断form的values是否发生过改变
      form.isDirty = () => {
        const curValues = form.getFieldsValue();
        return !isEqual(curValues, this.initialValues);
      };
      formRef(form);
    }

    return (
      <Card
        title={this.menu.title}
        className="box"
        extra={
          <div className="form-check" onClick={this.check}>
            校验
          </div>
        }
      >
        {SYS_TYPE === "LIVEDATA" && (
          <p>
            以上信息完整，真实地记录了本单位和个人申报的中医药传统知识，同意将以上信息应用于中医药传统知识保护名录和数据库的编制。
          </p>
        )}
        <Form labelAlign="left" {...FormItemLayout}>
          <Fields.Text
            form={form}
            label="申报人"
            name="hostUser"
            initialValue={data.hostUser}
            required={true}
          />
          <Fields.Text
            form={form}
            label="确认时间"
            name="hostTime"
            placeholder="系统自动生成"
            initialValue={data.hostTime ? ymdhm(data.hostTime) : undefined}
            disabled={true}
          />
          <Fields.File
            form={form}
            label="申请人承诺书"
            name="hostAttach"
            title="上传文件"
            initialValue={renderFile(data.hostAttach)}
            maxFileSize={MAX_FILE_SIZE}
            required={true}
            template={
              SYS_TYPE === "TREATMENT"
                ? `${process.env.PUBLIC_URL}/assets/申报单位或个人诚信承诺书模板.pdf`
                : "https://36node-catcm.oss-cn-beijing.aliyuncs.com/%E7%94%B3%E6%8A%A5%E5%8D%95%E4%BD%8D%E6%88%96%E4%B8%AA%E4%BA%BA%E8%AF%9A%E4%BF%A1%E6%89%BF%E8%AF%BA%E4%B9%A6%E6%A8%A1%E6%9D%BF.docx"
            }
          />
          <Fields.File
            form={form}
            label="知情同意书"
            name="informedAttach"
            title="上传文件"
            initialValue={renderFile(data.informedAttach)}
            maxFileSize={MAX_FILE_SIZE}
            required={true}
            template={
              SYS_TYPE === "TREATMENT"
                ? `${process.env.PUBLIC_URL}/assets/知情同意书模板.pdf`
                : "https://36node-catcm.oss-cn-beijing.aliyuncs.com/%E7%9F%A5%E6%83%85%E5%90%8C%E6%84%8F%E4%B9%A6%E6%A8%A1%E6%9D%BF.docx"
            }
          />
        </Form>
        <Alert
          message="温馨提示"
          description="请确保所填信息真实有效，如有造假将取消所有资格"
          type="info"
          showIcon
          className="tips"
        />
        <div className="btns">
          <Button size="large" onClick={this.props.stepBack}>
            返回上一步
          </Button>
          <Button
            size="large"
            type="primary"
            onClick={this.handleSave}
            style={{ marginLeft: 16 }}
          >
            保存并提交审核
          </Button>
        </div>
      </Card>
    );
  }
}
