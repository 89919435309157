import React, { Component } from "react";
import { Descriptions } from "antd";
import styled from "styled-components";
import ReactWaterMark from "react-watermark-component";
import moment from "moment";

import { ROLES, UploadMenu, FileArray, WaterMarkText } from "../../constants";
import { getTypeName, ymd, ymdhms } from "../../lib";
import FileRender from "../../components/file-render";
import { SYS_TYPE } from "../../config";

const EvaluationGroups = {
  safety: "安全性评价",
  effectiveness: "有效性评价",
  accessibility: "可及性评价",
  ripeness: "成熟度评价",
  originality: "独创性评价",
};

export default class Detail extends Component {
  renderValue = (key, value) => {
    const { data } = this.props;

    const isoPattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;

    const d = key === "couser" ? data.coUser[value] : data[value];

    if (value === "type") {
      return getTypeName(d);
    } else if (value === "birthday") {
      return ymd(d);
    } else if (value === "idImage") {
      if (this.role === ROLES.ADMIN || data.createdBy === this.userId) {
        return this.renderFiles(d);
      } else {
        return null;
      }
    } else if (FileArray.includes(value)) {
      return this.renderFiles(d);
    } else if (isoPattern.test(d)) {
      return ymdhms(moment(d));
    }

    return d;
  };

  renderFiles = values => {
    if (values) {
      return values.filter(Boolean).map(item => {
        return <FileRender showPreview={true} file={item} key={item} />;
      });
    }
    return null;
  };

  render() {
    const { menus = UploadMenu, data } = this.props;

    return (
      <ReactWaterMark
        waterMarkText={SYS_TYPE === "LIVEDATA" ? WaterMarkText : ""}
        options={{
          chunkWidth: 800,
          chunkHeight: 200,
          font: "32px Microsoft Yahei",
          rotateAngle: -0.4,
        }}
      >
        <Content padding={menus.length ? "24px" : 0}>
          {menus.map(item => {
            if (item.key === "couser" && !data.coUser) {
              return null;
            }

            if (SYS_TYPE === "LIVEDATA" && item.key === "evaluation") {
              return null;
            }

            // 评价分组
            if (item.key === "evaluation") {
              return (
                <>
                  <div className="ant-descriptions-title">{item.title}</div>

                  {Object.keys(EvaluationGroups).map((group, index) => {
                    const title = EvaluationGroups[group];
                    return (
                      <Descriptions
                        title={
                          <span
                            style={{
                              fontWeight: "normal",
                              fontSize: 16,
                              paddingLeft: 8,
                            }}
                          >
                            {`${index + 1}.${title}`}
                          </span>
                        }
                        key={group}
                        bordered
                        column={1}
                      >
                        {item.items
                          .filter(i => i.group === group)
                          .map(i => {
                            return (
                              <Descriptions.Item label={i.label} key={i.value}>
                                {this.renderValue(item.key, i.value)}
                              </Descriptions.Item>
                            );
                          })}
                      </Descriptions>
                    );
                  })}
                </>
              );
            }

            return (
              <Descriptions
                title={item.title}
                key={item.key}
                bordered
                column={1}
              >
                {item.items.map(i => {
                  // 过滤掉只在某个系统下显示的项目
                  if (i.system && i.system !== SYS_TYPE) {
                    return null;
                  }

                  return (
                    <Descriptions.Item label={i.label} key={i.value}>
                      {this.renderValue(item.key, i.value)}
                    </Descriptions.Item>
                  );
                })}
              </Descriptions>
            );
          })}
        </Content>
      </ReactWaterMark>
    );
  }
}

const Content = styled.div`
  background: white;
  padding: ${props => props.padding};
  .ant-descriptions {
    margin-bottom: 24px;
  }

  .detail-image {
    height: 100px;
    display: inline-block;
    vertical-align: top;
    margin-right: 20px;
    margin-bottom: 20px;
    cursor: pointer;
  }

  .detail-file,
  .detail-video {
    display: inline-block;
    vertical-align: top;
    margin-right: 20px;
    cursor: pointer;
    color: #1d76de;

    .anticon {
      margin-right: 3px;
    }
  }
`;
