import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Route, Switch, Redirect } from "react-router-dom";

import { ROOT_NS } from "../../config";
import { user } from "../../actions/api";

import Add from "./add";
import Project from "./project";

const listUsers = user.makeListUsers("users", {
  query: {
    limit: 10000,
    filter: {
      ns: { $regex: new RegExp(ROOT_NS) },
    },
  },
});

@connect()
export default class extends React.PureComponent {
  componentDidMount() {
    this.props.dispatch(listUsers());
  }
  render() {
    return (
      <Fragment>
        <Switch>
          <Route path="/apply/project" component={Project} />
          <Route path="/apply/add" component={Add} />
          <Redirect from="/apply" to="/apply/project" />
        </Switch>
      </Fragment>
    );
  }
}
