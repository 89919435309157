import React from "react";
import styled from "styled-components";
import { hot } from "react-hot-loader/root";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import Loadable from "react-loadable";
import { Layout, ConfigProvider, BackTop } from "antd";
import zhCN from "antd/es/locale/zh_CN";
import { history } from "@36node/redux-session";
import ProtectedRoute from "./lib/protected-route";
import DocumentTitle from "react-document-title";

import ScrollToTop from "./scrollTotop";
import Nav from "./containers/nav";
import Loading from "./components/loading";
import Login from "./containers/login";
import ChangePassword from "./containers/login/change-password";
import Home from "./containers/home";
import { TITLE, SYS_TYPE } from "./config";
import Apply from "./containers/apply";

const { Header, Content } = Layout;

// const Apply = Loadable({
//   loader: () => import("./containers/apply"),
//   loading: Loading,
// });

const Review = Loadable({
  loader: () => import("./containers/review"),
  loading: Loading,
});

const Library = Loadable({
  loader: () => import("./containers/library"),
  loading: Loading,
});

const Certificate = Loadable({
  loader: () => import("./containers/certificate"),
  loading: Loading,
});

const Users = Loadable({
  loader: () => import("./containers/users"),
  loading: Loading,
});

const Profile = Loadable({
  loader: () => import("./containers/profile"),
  loading: Loading,
});

const Help = Loadable({
  loader: () => import("./containers/help"),
  loading: Loading,
});

const Feedback = Loadable({
  loader: () => import("./containers/feedback"),
  loading: Loading,
});

const App = () => (
  <DocumentTitle title={TITLE}>
    <ConfigProvider locale={zhCN}>
      <Router history={history}>
        <ScrollToTop>
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/change-password" component={ChangePassword} />
            <ProtectedRoute redirect="/login" component={Main} />
          </Switch>
        </ScrollToTop>
      </Router>
    </ConfigProvider>
  </DocumentTitle>
);

const Main = () => (
  <StyledLayout>
    <StyledHeader>
      <Nav />
    </StyledHeader>
    <Content id="catcm-content">
      <Switch>
        <Route path="/apply" component={Apply} />
        <Route path="/review" component={Review} />
        <Route path="/library" component={Library} />
        <Route path="/users" component={Users} />
        <Route path="/certificate" component={Certificate} />
        <Route path="/profile" exact component={Profile} />
        <Route path="/help" exact component={Help} />
        <Route path="/feedback" exact component={Feedback} />
        <Route path="/home" exact component={Home} />
        <Redirect to="home" />
      </Switch>
      <div style={{ textAlign: "center", padding: "24px 0", fontSize: 14 }}>
        {SYS_TYPE === "LIVEDATA" && (
          <span style={{ marginRight: 48 }}>
            Copyright© 2020 国家中医药管理局中医药传统知识保护研究中心
          </span>
        )}
        <a
          href="http://www.beian.miit.gov.cn"
          target="_blank"
          rel="noopener noreferrer"
          alt=""
        >
          京ICP备12038572号-3
        </a>
      </div>
      <BackTop
        target={() => document.getElementById("catcm-content")}
        visibilityHeight={100}
      />
    </Content>
  </StyledLayout>
);

const HotApp = process.env.NODE_ENV === "development" ? hot(App) : App;

const StyledLayout = styled(Layout)`
  background: none;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;

  #catcm-content {
    overflow: auto;
    padding-top: 24px;
  }

  .ant-back-top {
    bottom: 80px;

    .ant-back-top-content {
      width: 60px;
      height: 60px;
      border-radius: 30px;
    }
    .ant-back-top-icon {
      width: 21px;
      height: 24px;
      margin: 18px auto;
    }
  }
`;

const StyledHeader = styled(Header)`
  width: 100%;
  background: #1d76de;
  box-shadow: 0 2px 8px #f0f1f2;
  display: flex;
  height: 64px;
`;

export default HotApp;
