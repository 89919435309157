import React, { PureComponent } from "react";
import { Result, Button } from "antd";
import { NavLink } from "react-router-dom";

import Container from "../../../components/layout/container";

export default class extends PureComponent {
  render() {
    return (
      <Container style={{ background: "white" }}>
        <Result
          status="success"
          title="提交成功"
          subTitle="您的项目已成功提交审核，请至项目申报页面查看审核进度"
          extra={[
            <NavLink
              to="/apply/project/list/reviewing?offset=0&limit=10"
              key={1}
            >
              <Button type="primary" key="review">
                查看审核进度
              </Button>
            </NavLink>,
          ]}
        ></Result>
      </Container>
    );
  }
}
