import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import ProjectList from "./project-list";
import ProjectEdit from "./edit";
import ProjectDetail from "./project-detail";
import Success from "./success";
import New from "./new";

export default class extends React.PureComponent {
  render() {
    return (
      <Switch>
        <Route path="/apply/project/new" component={New} />
        <Route path="/apply/project/success" component={Success} />
        <Route path="/apply/project/list" component={ProjectList} />
        <Route path="/apply/project/edit/:id" component={ProjectEdit} />
        <Route path="/apply/project/detail/:id" component={ProjectDetail} />
        <Redirect from="/apply/project" to="/apply/project/list" />
      </Switch>
    );
  }
}
