import React, { PureComponent } from "react";
import { Layout, Menu } from "antd";
import styled from "styled-components";
import { Switch, Redirect, withRouter, Route } from "react-router-dom";
import { history } from "@36node/redux-session";

import Container from "../../../../components/layout/container";
import New from "./new";
import Finished from "./finished";
import Reviewing from "./reviewing";

const { Sider, Content } = Layout;

const parentPath = "/apply/project/list";

@withRouter
export default class ProjectList extends PureComponent {
  onMenuChanged = ({ key }) => {
    history.push(key);
  };

  render() {
    const { location = {} } = this.props;
    const { pathname } = location;

    return (
      <Container>
        <StyledLayout>
          <Sider theme="light">
            <Menu
              mode="inline"
              defaultSelectedKeys={["1"]}
              onSelect={this.onMenuChanged}
              selectedKeys={[pathname]}
            >
              <Menu.Item key={parentPath + "/new"}>填写中项目</Menu.Item>
              <Menu.Item key={parentPath + "/reviewing"}>审核中项目</Menu.Item>
              <Menu.Item key={parentPath + "/finished"}>审核完成项目</Menu.Item>
            </Menu>
          </Sider>
          <Content>
            <Switch>
              <Route path={parentPath + "/new"} component={New} />
              <Route path={parentPath + "/reviewing"} component={Reviewing} />
              <Route path={parentPath + "/finished"} component={Finished} />
              <Redirect from={parentPath} to={parentPath + "/new"} />
            </Switch>
          </Content>
        </StyledLayout>
      </Container>
    );
  }
}

const StyledLayout = styled(Layout)`
  background: white;
  margin-bottom: 24px;

  .ant-menu {
    min-height: 100%;
    height: 100%;
  }
`;
