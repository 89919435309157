import { fork, all, takeLatest, call } from "redux-saga/effects";
import { watchApi } from "@36node/redux";
import { watchXlsx } from "@36node/redux-xlsx";
import { makeSessionWatcher, history } from "@36node/redux-session";

import { auth } from "../actions/api";
import { LOGIN, REFRESH, LOGOUT } from "../actions/types";
import { LOGIN_URL } from "../constants";

const refresh = auth.makeRefresh("session");

const watchSession = makeSessionWatcher({
  refresh,
  loginSuccessTypes: [LOGIN.SUCCESS, REFRESH.SUCCESS],
  logoutSuccessTypes: [LOGOUT.SUCCESS],
});

const watchSessionFailure = function*() {
  yield takeLatest("@@api/REFRESH_FAILURE", function*() {
    localStorage.removeItem("session_id");
    sessionStorage.removeItem("session_id");
    sessionStorage.removeItem("token");
    yield call(history.push, LOGIN_URL, { from: history.location });
  });
};

export default function* root() {
  yield all([
    fork(watchApi),
    fork(watchXlsx),
    fork(watchSession),
    fork(watchSessionFailure),
  ]);
}
