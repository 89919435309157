import React from "react";
import { Form, Row, Col, Button, Card, message } from "antd";
import { find, isEqual, get } from "lodash";
import styled from "styled-components";

import { Fields } from "../../../../components/fileds";
import {
  UploadMenu,
  FormItemLayout,
  InnerFormItemLayout,
} from "../../../../constants";
import { createForm } from "@36node/redux-antd";
import { renderFile } from "../../../../lib";
import { MAX_FILE_SIZE } from "../../../../config";

const key = "evaluation";

@createForm("upload.evaluation")
export default class EvaluationForm extends React.PureComponent {
  get menu() {
    return find(UploadMenu, { key });
  }

  handleSave = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        values.step = this.menu.step;
        this.props.saveDraft(values);

        this.initialValues = this.props.form.getFieldsValue();
      }
    });
  };

  check = () => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        message.success(`${this.menu.title}填写正确`);
      }
    });
  };

  componentDidUpdate(prevProps) {
    const prevId = get(prevProps, "data.id");
    const curId = get(this.props, "data.id");

    // id 发生改变时， 重置initialValue
    if (prevId !== curId) {
      this.initialValues = this.props.form.getFieldsValue();
    }
  }

  componentDidMount() {
    this.initialValues = this.props.form.getFieldsValue();
  }

  componentWillUnmount() {
    this.initialValues = undefined;
  }

  render() {
    const {
      form,
      data,
      showSave = true,
      showCheck = true,
      noRequire = false,
      disabledFields = [],
      formRef,
    } = this.props;

    if (formRef) {
      // 判断form的values是否发生过改变
      form.isDirty = () => {
        const curValues = form.getFieldsValue();

        return !isEqual(curValues, this.initialValues);
      };
      formRef(form);
    }

    return (
      <Card
        title={this.menu.title}
        className="box"
        extra={
          showCheck && (
            <div className="form-check" onClick={this.check}>
              校验
            </div>
          )
        }
      >
        <Form labelAlign="left" {...FormItemLayout}>
          <Title>1. 安全性评价</Title>
          <Row gutter={8}>
            <Col span={12}>
              <Fields.TextArea
                form={form}
                required={!noRequire}
                label="是否侵入皮肤"
                name="isSkinIrritation"
                initialValue={data.isSkinIrritation}
                disabled={disabledFields.includes("isSkinIrritation")}
                layout={InnerFormItemLayout}
              />
            </Col>
            <Col span={12}>
              <Fields.File
                form={form}
                required={!noRequire}
                label="上传证据文件"
                name="skinIrritationAttach"
                title="上传文件"
                layout={InnerFormItemLayout}
                initialValue={renderFile(data.skinIrritationAttach)}
                maxFileSize={MAX_FILE_SIZE}
                extra="文件格式应为PDF、JPG、MP4、MP3，不允许使用压缩文件，单个文件大小不超过1GB"
              />
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12}>
              <Fields.TextArea
                form={form}
                required={!noRequire}
                label="是否含有刺激性、腐蚀性药物"
                placeholder="如有，应说明控制皮肤发泡或腐蚀程度的方法"
                name="hasCorrosiveDrug"
                initialValue={data.hasCorrosiveDrug}
                disabled={disabledFields.includes("hasCorrosiveDrug")}
                layout={InnerFormItemLayout}
              />
            </Col>
            <Col span={12}>
              <Fields.File
                form={form}
                required={!noRequire}
                label="上传证据文件"
                name="hasCorrosiveDrugAttach"
                title="上传文件"
                layout={InnerFormItemLayout}
                initialValue={renderFile(data.hasCorrosiveDrugAttach)}
                maxFileSize={MAX_FILE_SIZE}
                extra="文件格式应为PDF、JPG、MP4、MP3，不允许使用压缩文件，单个文件大小不超过1GB"
              />
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12}>
              <Fields.TextArea
                form={form}
                required={!noRequire}
                label="常见的不良反应及应对措施"
                name="adverseReactions"
                initialValue={data.adverseReactions}
                disabled={disabledFields.includes("adverseReactions")}
                layout={InnerFormItemLayout}
              />
            </Col>
            <Col span={12}>
              <Fields.File
                form={form}
                label="上传证据文件"
                required={!noRequire}
                name="adverseReactionsAttach"
                title="上传文件"
                layout={InnerFormItemLayout}
                initialValue={renderFile(data.adverseReactionsAttach)}
                maxFileSize={MAX_FILE_SIZE}
                extra="文件格式应为PDF、JPG、MP4、MP3，不允许使用压缩文件，单个文件大小不超过1GB"
              />
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12}>
              <Fields.TextArea
                form={form}
                required={!noRequire}
                label="不良反应是否可逆"
                placeholder="如可逆，应说明逆转条件"
                name="isAdverseReactionsRecoverable"
                initialValue={data.isAdverseReactionsRecoverable}
                disabled={disabledFields.includes(
                  "isAdverseReactionsRecoverable"
                )}
                layout={InnerFormItemLayout}
              />
            </Col>
            <Col span={12}>
              <Fields.File
                form={form}
                required={!noRequire}
                label="上传证据文件"
                name="isAdverseReactionsRecoverableAttach"
                title="上传文件"
                layout={InnerFormItemLayout}
                initialValue={renderFile(
                  data.isAdverseReactionsRecoverableAttach
                )}
                maxFileSize={MAX_FILE_SIZE}
                extra="文件格式应为PDF、JPG、MP4、MP3，不允许使用压缩文件，单个文件大小不超过1GB"
              />
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12}>
              <Fields.TextArea
                form={form}
                required={!noRequire}
                label="禁忌症说明"
                name="contraindications"
                initialValue={data.contraindications}
                disabled={disabledFields.includes("contraindications")}
                layout={InnerFormItemLayout}
              />
            </Col>
            <Col span={12}>
              <Fields.File
                form={form}
                required={!noRequire}
                label="上传证据文件"
                name="contraindicationsAttach"
                title="上传文件"
                layout={InnerFormItemLayout}
                initialValue={renderFile(data.contraindicationsAttach)}
                maxFileSize={MAX_FILE_SIZE}
                extra="文件格式应为PDF、JPG、MP4、MP3，不允许使用压缩文件，单个文件大小不超过1GB"
              />
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12}>
              <Fields.TextArea
                form={form}
                label="其他安全性说明"
                name="otherSafeInstruction"
                initialValue={data.otherSafeInstruction}
                disabled={disabledFields.includes("otherSafeInstruction")}
                layout={InnerFormItemLayout}
              />
            </Col>
            <Col span={12}>
              <Fields.File
                form={form}
                label="上传证据文件"
                name="otherSafeInstructionAttach"
                title="上传文件"
                layout={InnerFormItemLayout}
                initialValue={renderFile(data.otherSafeInstructionAttach)}
                maxFileSize={MAX_FILE_SIZE}
                extra="文件格式应为PDF、JPG、MP4、MP3，不允许使用压缩文件，单个文件大小不超过1GB"
              />
            </Col>
          </Row>
          <Title>2. 有效性评价</Title>
          <Row gutter={8}>
            <Col span={12}>
              <Fields.TextArea
                form={form}
                required={!noRequire}
                label="相关病例"
                placeholder="不少于30例。提供诊疗前后对照证据，如住院病历、第三方检查报告、音频、视频等"
                name="relatedCases"
                initialValue={data.relatedCases}
                disabled={disabledFields.includes("relatedCases")}
                layout={InnerFormItemLayout}
              />
            </Col>
            <Col span={12}>
              <Fields.File
                form={form}
                required={!noRequire}
                label="上传证据文件"
                name="relatedCasesAttach"
                title="上传文件"
                layout={InnerFormItemLayout}
                initialValue={renderFile(data.relatedCasesAttach)}
                maxFileSize={MAX_FILE_SIZE}
                extra="文件格式应为PDF、JPG、MP4、MP3，不允许使用压缩文件，单个文件大小不超过1GB"
              />
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12}>
              <Fields.TextArea
                form={form}
                required={!noRequire}
                label="患者反馈情况"
                placeholder="患者签名文件，或音视频等能够真实反映患者诊疗情况的文件"
                name="feedback"
                initialValue={data.feedback}
                disabled={disabledFields.includes("feedback")}
                layout={InnerFormItemLayout}
              />
            </Col>
            <Col span={12}>
              <Fields.File
                form={form}
                required={!noRequire}
                label="上传证据文件"
                name="feedbackAttach"
                title="上传文件"
                layout={InnerFormItemLayout}
                initialValue={renderFile(data.feedbackAttach)}
                maxFileSize={MAX_FILE_SIZE}
                extra="文件格式应为PDF、JPG、MP4、MP3，不允许使用压缩文件，单个文件大小不超过1GB"
              />
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12}>
              <Fields.TextArea
                form={form}
                label="临床研究证据"
                placeholder="诊断技术应提供灵敏度、特异度等指标；治疗技术应提供治愈率、有效率、复发率等指标"
                name="researchEvidence"
                initialValue={data.researchEvidence}
                disabled={disabledFields.includes("researchEvidence")}
                layout={InnerFormItemLayout}
              />
            </Col>
            <Col span={12}>
              <Fields.File
                form={form}
                label="上传证据文件"
                name="researchEvidenceAttach"
                title="上传文件"
                layout={InnerFormItemLayout}
                initialValue={renderFile(data.researchEvidenceAttach)}
                maxFileSize={MAX_FILE_SIZE}
                extra="文件格式应为PDF、JPG、MP4、MP3，不允许使用压缩文件，单个文件大小不超过1GB"
              />
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12}>
              <Fields.TextArea
                form={form}
                required={!noRequire}
                label="患者复诊情况"
                placeholder="包括复诊人数/月、单人复诊次数等，并提供复诊病历"
                name="patientRecheck"
                initialValue={data.patientRecheck}
                disabled={disabledFields.includes("patientRecheck")}
                layout={InnerFormItemLayout}
              />
            </Col>
            <Col span={12}>
              <Fields.File
                form={form}
                required={!noRequire}
                label="上传证据文件"
                name="patientRecheckAttach"
                title="上传文件"
                layout={InnerFormItemLayout}
                initialValue={renderFile(data.patientRecheckAttach)}
                maxFileSize={MAX_FILE_SIZE}
                extra="文件格式应为PDF、JPG、MP4、MP3，不允许使用压缩文件，单个文件大小不超过1GB"
              />
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12}>
              <Fields.TextArea
                form={form}
                label="其他有效性说明"
                name="otherEffectiveness"
                initialValue={data.otherEffectiveness}
                disabled={disabledFields.includes("otherEffectiveness")}
                layout={InnerFormItemLayout}
              />
            </Col>
            <Col span={12}>
              <Fields.File
                form={form}
                label="上传证据文件"
                name="otherEffectivenessAttach"
                title="上传文件"
                layout={InnerFormItemLayout}
                initialValue={renderFile(data.otherEffectivenessAttach)}
                maxFileSize={MAX_FILE_SIZE}
                extra="文件格式应为PDF、JPG、MP4、MP3，不允许使用压缩文件，单个文件大小不超过1GB"
              />
            </Col>
          </Row>
          <Title>3. 可及性评价</Title>
          <Row gutter={8}>
            <Col span={12}>
              <Fields.TextArea
                form={form}
                label="针对该技术开展的继续教育情况"
                placeholder="继续教育名称、次数与级别等"
                name="education"
                initialValue={data.education}
                disabled={disabledFields.includes("education")}
                layout={InnerFormItemLayout}
              />
            </Col>
            <Col span={12}>
              <Fields.File
                form={form}
                label="上传证据文件"
                name="educationAttach"
                title="上传文件"
                layout={InnerFormItemLayout}
                initialValue={renderFile(data.educationAttach)}
                maxFileSize={MAX_FILE_SIZE}
                extra="文件格式应为PDF、JPG、MP4、MP3，不允许使用压缩文件，单个文件大小不超过1GB"
              />
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12}>
              <Fields.TextArea
                form={form}
                label="针对该技术开展的培训、授徒活动"
                placeholder="培训、收徒人数、活动次数与规模等"
                name="training"
                initialValue={data.training}
                disabled={disabledFields.includes("training")}
                layout={InnerFormItemLayout}
              />
            </Col>
            <Col span={12}>
              <Fields.File
                form={form}
                label="上传证据文件"
                name="trainingAttach"
                title="上传文件"
                layout={InnerFormItemLayout}
                initialValue={renderFile(data.trainingAttach)}
                maxFileSize={MAX_FILE_SIZE}
                extra="文件格式应为PDF、JPG、MP4、MP3，不允许使用压缩文件，单个文件大小不超过1GB"
              />
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12}>
              <Fields.TextArea
                form={form}
                label="一次完整技术培训所需时间"
                placeholder="掌握该技术所需时间"
                name="trainingTime"
                initialValue={data.trainingTime}
                disabled={disabledFields.includes("trainingTime")}
                layout={InnerFormItemLayout}
              />
            </Col>
            <Col span={12}>
              <Fields.File
                form={form}
                label="上传证据文件"
                name="trainingTimeAttach"
                title="上传文件"
                layout={InnerFormItemLayout}
                initialValue={renderFile(data.trainingTimeAttach)}
                maxFileSize={MAX_FILE_SIZE}
                extra="文件格式应为PDF、JPG、MP4、MP3，不允许使用压缩文件，单个文件大小不超过1GB"
              />
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12}>
              <Fields.TextArea
                form={form}
                label="开展该诊疗技术的经济成本"
                placeholder="如一次治疗花费"
                name="treatmentCost"
                initialValue={data.treatmentCost}
                disabled={disabledFields.includes("treatmentCost")}
                layout={InnerFormItemLayout}
              />
            </Col>
            <Col span={12}>
              <Fields.File
                form={form}
                label="上传证据文件"
                name="treatmentCostAttach"
                title="上传文件"
                layout={InnerFormItemLayout}
                initialValue={renderFile(data.treatmentCostAttach)}
                maxFileSize={MAX_FILE_SIZE}
                extra="文件格式应为PDF、JPG、MP4、MP3，不允许使用压缩文件，单个文件大小不超过1GB"
              />
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12}>
              <Fields.TextArea
                form={form}
                required={!noRequire}
                label="开展该诊疗技术的时间成本"
                placeholder="完成一次诊疗或一个疗程所需时间"
                name="treatmentTimeCost"
                initialValue={data.treatmentTimeCost}
                disabled={disabledFields.includes("treatmentTimeCost")}
                layout={InnerFormItemLayout}
              />
            </Col>
            <Col span={12}>
              <Fields.File
                form={form}
                required={!noRequire}
                label="上传证据文件"
                name="treatmentTimeCostAttach"
                title="上传文件"
                layout={InnerFormItemLayout}
                initialValue={renderFile(data.treatmentTimeCostAttach)}
                maxFileSize={MAX_FILE_SIZE}
                extra="文件格式应为PDF、JPG、MP4、MP3，不允许使用压缩文件，单个文件大小不超过1GB"
              />
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12}>
              <Fields.TextArea
                form={form}
                label="其他说明"
                name="otherAccessibility"
                initialValue={data.otherAccessibility}
                disabled={disabledFields.includes("otherAccessibility")}
                layout={InnerFormItemLayout}
              />
            </Col>
            <Col span={12}>
              <Fields.File
                form={form}
                label="上传证据文件"
                name="otherAccessibilityAttach"
                title="上传文件"
                layout={InnerFormItemLayout}
                initialValue={renderFile(data.otherAccessibilityAttach)}
                maxFileSize={MAX_FILE_SIZE}
                extra="文件格式应为PDF、JPG、MP4、MP3，不允许使用压缩文件，单个文件大小不超过1GB"
              />
            </Col>
          </Row>
          <Title>4. 成熟度评价</Title>
          <Row gutter={8}>
            <Col span={12}>
              <Fields.TextArea
                form={form}
                required={!noRequire}
                label="应用时间"
                placeholder="填报格式：自XXXX年XX月至XXXX年XX月"
                name="implementationTime"
                initialValue={data.implementationTime}
                disabled={disabledFields.includes("implementationTime")}
                layout={InnerFormItemLayout}
              />
            </Col>
            <Col span={12}>
              <Fields.File
                form={form}
                required={!noRequire}
                label="上传证据文件"
                name="implementationTimeAttach"
                title="上传文件"
                layout={InnerFormItemLayout}
                initialValue={renderFile(data.implementationTimeAttach)}
                maxFileSize={MAX_FILE_SIZE}
                extra="文件格式应为PDF、JPG、MP4、MP3，不允许使用压缩文件，单个文件大小不超过1GB"
              />
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12}>
              <Fields.TextArea
                form={form}
                required={!noRequire}
                label="应用地区"
                placeholder="开展该诊疗技术的行政区域"
                name="implementationArea"
                initialValue={data.implementationArea}
                disabled={disabledFields.includes("implementationArea")}
                layout={InnerFormItemLayout}
              />
            </Col>
            <Col span={12}>
              <Fields.File
                form={form}
                required={!noRequire}
                label="上传证据文件"
                name="implementationAreaAttach"
                title="上传文件"
                layout={InnerFormItemLayout}
                initialValue={renderFile(data.implementationAreaAttach)}
                maxFileSize={MAX_FILE_SIZE}
                extra="文件格式应为PDF、JPG、MP4、MP3，不允许使用压缩文件，单个文件大小不超过1GB"
              />
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12}>
              <Fields.TextArea
                form={form}
                label="是否有技术的操作规范"
                placeholder="如有，应说明操作规范级别：企业标准、地方标准、行业标准、团体标准、国家标准等"
                name="hasOperatingSpecifications"
                initialValue={data.hasOperatingSpecifications}
                disabled={disabledFields.includes("hasOperatingSpecifications")}
                layout={InnerFormItemLayout}
              />
            </Col>
            <Col span={12}>
              <Fields.File
                form={form}
                label="上传证据文件"
                name="hasOperatingSpecificationsAttach"
                title="上传文件"
                layout={InnerFormItemLayout}
                initialValue={renderFile(data.hasOperatingSpecificationsAttach)}
                maxFileSize={MAX_FILE_SIZE}
                extra="文件格式应为PDF、JPG、MP4、MP3，不允许使用压缩文件，单个文件大小不超过1GB"
              />
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12}>
              <Fields.TextArea
                form={form}
                label="与该技术相关的研究资料"
                placeholder="古籍、专著、论文、专利等"
                name="researchMaterials"
                initialValue={data.researchMaterials}
                disabled={disabledFields.includes("researchMaterials")}
                layout={InnerFormItemLayout}
              />
            </Col>
            <Col span={12}>
              <Fields.File
                form={form}
                label="上传证据文件"
                name="researchMaterialsAttach"
                title="上传文件"
                layout={InnerFormItemLayout}
                initialValue={renderFile(data.researchMaterialsAttach)}
                maxFileSize={MAX_FILE_SIZE}
                extra="文件格式应为PDF、JPG、MP4、MP3，不允许使用压缩文件，单个文件大小不超过1GB"
              />
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12}>
              <Fields.TextArea
                form={form}
                label="主持、参与相关课题情况"
                placeholder="如有，应说明课题级别、名称、编号、经费、起止时间、申报人负责的工作等"
                name="researchTopics"
                initialValue={data.researchTopics}
                disabled={disabledFields.includes("researchTopics")}
                layout={InnerFormItemLayout}
              />
            </Col>
            <Col span={12}>
              <Fields.File
                form={form}
                label="上传证据文件"
                name="researchTopicsAttach"
                title="上传文件"
                layout={InnerFormItemLayout}
                initialValue={renderFile(data.researchTopicsAttach)}
                maxFileSize={MAX_FILE_SIZE}
                extra="文件格式应为PDF、JPG、MP4、MP3，不允许使用压缩文件，单个文件大小不超过1GB"
              />
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12}>
              <Fields.TextArea
                form={form}
                label="与该技术有关的获奖情况"
                placeholder="如有，应说明奖励级别、奖励名称、获奖时间等"
                name="relatedRewards"
                initialValue={data.relatedRewards}
                disabled={disabledFields.includes("relatedRewards")}
                layout={InnerFormItemLayout}
              />
            </Col>
            <Col span={12}>
              <Fields.File
                form={form}
                label="上传证据文件"
                name="relatedRewardsAttach"
                title="上传文件"
                layout={InnerFormItemLayout}
                initialValue={renderFile(data.relatedRewardsAttach)}
                maxFileSize={MAX_FILE_SIZE}
                extra="文件格式应为PDF、JPG、MP4、MP3，不允许使用压缩文件，单个文件大小不超过1GB"
              />
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12}>
              <Fields.TextArea
                form={form}
                label="诊疗场所规模"
                placeholder="日患者量、分院数量、团队人数等"
                name="clinicsScale"
                initialValue={data.clinicsScale}
                disabled={disabledFields.includes("clinicsScale")}
                layout={InnerFormItemLayout}
              />
            </Col>
            <Col span={12}>
              <Fields.File
                form={form}
                label="上传证据文件"
                name="clinicsScaleAttach"
                title="上传文件"
                layout={InnerFormItemLayout}
                initialValue={renderFile(data.clinicsScaleAttach)}
                maxFileSize={MAX_FILE_SIZE}
                extra="文件格式应为PDF、JPG、MP4、MP3，不允许使用压缩文件，单个文件大小不超过1GB"
              />
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12}>
              <Fields.TextArea
                form={form}
                label="与该技术有关的产品情况"
                placeholder="产品名称、批号等"
                name="releatedProducts"
                initialValue={data.releatedProducts}
                disabled={disabledFields.includes("releatedProducts")}
                layout={InnerFormItemLayout}
              />
            </Col>
            <Col span={12}>
              <Fields.File
                form={form}
                label="上传证据文件"
                name="releatedProductsAttach"
                title="上传文件"
                layout={InnerFormItemLayout}
                initialValue={renderFile(data.releatedProductsAttach)}
                maxFileSize={MAX_FILE_SIZE}
                extra="文件格式应为PDF、JPG、MP4、MP3，不允许使用压缩文件，单个文件大小不超过1GB"
              />
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12}>
              <Fields.TextArea
                form={form}
                label="其他说明"
                name="otherMaturity"
                initialValue={data.otherMaturity}
                disabled={disabledFields.includes("otherMaturity")}
                layout={InnerFormItemLayout}
              />
            </Col>
            <Col span={12}>
              <Fields.File
                form={form}
                label="上传证据文件"
                name="otherMaturityAttach"
                title="上传文件"
                layout={InnerFormItemLayout}
                initialValue={renderFile(data.otherMaturityAttach)}
                maxFileSize={MAX_FILE_SIZE}
                extra="文件格式应为PDF、JPG、MP4、MP3，不允许使用压缩文件，单个文件大小不超过1GB"
              />
            </Col>
          </Row>
          <Title>5. 独特性评价</Title>
          <Row gutter={8}>
            <Col span={12}>
              <Fields.TextArea
                form={form}
                label="技术创新点"
                placeholder="与同类技术相比，在干预方法、选择、器具等方面有何创新"
                name="technologicalInnovation"
                initialValue={data.technologicalInnovation}
                disabled={disabledFields.includes("technologicalInnovation")}
                layout={InnerFormItemLayout}
              />
            </Col>
            <Col span={12}>
              <Fields.File
                form={form}
                label="上传证据文件"
                name="technologicalInnovationAttach"
                title="上传文件"
                layout={InnerFormItemLayout}
                initialValue={renderFile(data.technologicalInnovationAttach)}
                maxFileSize={MAX_FILE_SIZE}
                extra="文件格式应为PDF、JPG、MP4、MP3，不允许使用压缩文件，单个文件大小不超过1GB"
              />
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12}>
              <Fields.TextArea
                form={form}
                label="效果优势"
                placeholder="与同类技术相比，在诊断准确性、特异性；治疗有效率、治愈率等方面有何优势"
                name="effectAdvantage"
                initialValue={data.effectAdvantage}
                disabled={disabledFields.includes("effectAdvantage")}
                layout={InnerFormItemLayout}
              />
            </Col>
            <Col span={12}>
              <Fields.File
                form={form}
                label="上传证据文件"
                name="effectAdvantageAttach"
                title="上传文件"
                layout={InnerFormItemLayout}
                initialValue={renderFile(data.effectAdvantageAttach)}
                maxFileSize={MAX_FILE_SIZE}
                extra="文件格式应为PDF、JPG、MP4、MP3，不允许使用压缩文件，单个文件大小不超过1GB"
              />
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12}>
              <Fields.TextArea
                form={form}
                label="安全性优势"
                placeholder="与同类技术相比，在安全性上有何优势"
                name="securityAdvantage"
                initialValue={data.securityAdvantage}
                disabled={disabledFields.includes("securityAdvantage")}
                layout={InnerFormItemLayout}
              />
            </Col>
            <Col span={12}>
              <Fields.File
                form={form}
                label="上传证据文件"
                name="securityAdvantageAttach"
                title="上传文件"
                layout={InnerFormItemLayout}
                initialValue={renderFile(data.securityAdvantageAttach)}
                maxFileSize={MAX_FILE_SIZE}
                extra="文件格式应为PDF、JPG、MP4、MP3，不允许使用压缩文件，单个文件大小不超过1GB"
              />
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12}>
              <Fields.TextArea
                form={form}
                label="经济性"
                placeholder="与同类技术相比，达到相近效果所花费的经济成本有何优势"
                name="economyAdvantage"
                initialValue={data.economyAdvantage}
                disabled={disabledFields.includes("economyAdvantage")}
                layout={InnerFormItemLayout}
              />
            </Col>
            <Col span={12}>
              <Fields.File
                form={form}
                label="上传证据文件"
                name="economyAdvantageAttach"
                title="上传文件"
                layout={InnerFormItemLayout}
                initialValue={renderFile(data.economyAdvantageAttach)}
                maxFileSize={MAX_FILE_SIZE}
                extra="文件格式应为PDF、JPG、MP4、MP3，不允许使用压缩文件，单个文件大小不超过1GB"
              />
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12}>
              <Fields.TextArea
                form={form}
                label="其他说明"
                name="otherAdvantage"
                initialValue={data.otherAdvantage}
                disabled={disabledFields.includes("otherAdvantage")}
                layout={InnerFormItemLayout}
              />
            </Col>
            <Col span={12}>
              <Fields.File
                form={form}
                label="上传证据文件"
                name="otherAdvantageAttach"
                title="上传文件"
                layout={InnerFormItemLayout}
                initialValue={renderFile(data.otherAdvantageAttach)}
                maxFileSize={MAX_FILE_SIZE}
                extra="文件格式应为PDF、JPG、MP4、MP3，不允许使用压缩文件，单个文件大小不超过1GB"
              />
            </Col>
          </Row>
        </Form>

        {showSave && (
          <div className="btns">
            <Button size="large" onClick={this.props.stepBack}>
              返回上一步
            </Button>
            <Button
              size="large"
              type="primary"
              onClick={this.handleSave}
              style={{ marginLeft: 16 }}
            >
              保存并填写下一步
            </Button>
          </div>
        )}
      </Card>
    );
  }
}

const Title = styled.h2`
  margin-bottom: 24px;
`;
