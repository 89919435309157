/**
 * 新申报的项目列表
 */

import React, { PureComponent, Fragment } from "react";
import { Button, Table, Divider, Popconfirm, message } from "antd";
import styled from "styled-components";
import { NavLink, withRouter } from "react-router-dom";
import { createForm } from "@36node/redux-antd";
import { history, withSession } from "@36node/redux-session";
import qs from "qs";
import { isEqual, isEmpty, get, debounce } from "lodash";
import { connect } from "react-redux";
import { call, select } from "redux-saga/effects";
import {
  makeApiSelector,
  withSaga,
  tapOnLatest,
  reputApi,
} from "@36node/redux";

import { BaseColumns } from "./common";
import { NewLiveDataStates, LivedataStates } from "../../../../constants";
import FilterForm from "../../../../components/filter-form";
import { getSearch } from "../../../../lib/history";
import { core } from "../../../../actions/api";

import {
  CORE_LIVE_CREATE_LIVE,
  CORE_LIVE_DELETE_LIVE,
} from "../../../../actions/types";
import { getUser } from "../../../../lib";
import { SYS_TYPE } from "../../../../config";

const createLive = core.livedata.makeCreateLivedata("live.create");
const createLiveSelector = makeApiSelector("live.create");

const tabs = ["type", "newState", "title", "id", "owner"];

const listLivedata = core.livedata.makeListLivedata("projects.new", {
  query: {
    filter: {
      state: NewLiveDataStates,
    },
  },
});

const deleteLivedata = core.livedata.makeDeleteLivedata("projects.delete");

const listLiveDataSelector = makeApiSelector("projects.new");

const updateUrl = query => {
  history.push(`/apply/project/list/new?${qs.stringify(query)}`);
};

@withRouter
@createForm("projects.new")
class SearchForm extends PureComponent {
  handleReset = () => {
    this.props.form.resetFields();
  };

  handleSubmit = values => {
    updateUrl(values);
  };

  render() {
    const { form, location } = this.props;

    return (
      <FilterForm
        formItemLayout={{
          labelCol: { span: 6 },
          wrapperCol: { span: 18 },
        }}
        layout="horizontal"
        form={form}
        formItems={tabs}
        handleReset={this.handleReset}
        handleSubmit={this.handleSubmit}
        query={getSearch(location)}
      />
    );
  }
}

@withRouter
@withSession("session")
@withSaga(
  tapOnLatest(CORE_LIVE_DELETE_LIVE.SUCCESS, "projects.delete", function*(
    action
  ) {
    message.success("删除成功");
    yield reputApi(listLivedata());
  })
)
@connect((state, props) => {
  const listState = listLiveDataSelector(state);

  return {
    dataSource: listState.result || [],
    total: listState.total || 0,
    loading: listState.loading,
    user: get(props.session, "result.user") || {},
  };
})
class ProjectsTable extends PureComponent {
  getQueryFromLocation = () => {
    let query = getSearch(this.props.location);
    const { offset, limit } = query;
    query.offset = offset || 0;
    query.limit = limit || 10;
    return query;
  };

  componentDidMount() {
    if (isEmpty(this.props.location.search)) {
      updateUrl(this.getQueryFromLocation());
    }
    this.fetchList();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(this.props.location, prevProps.location)) {
      this.fetchList();
    }
  }

  fetchList = (query = {}) => {
    this.props.dispatch(
      listLivedata({
        query: {
          ...this.getQueryFromLocation(),
          ...query,
          createdBy: this.props.user.id,
        },
      })
    );
  };

  onTableChange = pagination => {
    const limit = pagination.pageSize;
    const offset = (pagination.current - 1) * limit;

    updateUrl({
      ...this.getQueryFromLocation(),
      limit,
      offset,
    });
  };

  confirmDelete = id => {
    this.props.dispatch(
      deleteLivedata({
        livedataId: id,
      })
    );
  };

  render() {
    const { total, ...rest } = this.props;
    const urlQuery = this.getQueryFromLocation();

    const limit = Number(urlQuery.limit);
    const offset = Number(urlQuery.offset);

    const finalColumns = [
      ...BaseColumns,
      {
        title: "操作",
        key: "operate",
        width: 200,
        render: record => {
          switch (record.state) {
            case LivedataStates.DRAFT:
              return (
                <div>
                  <NavLink to={`/apply/project/edit/${record.id}`}>
                    继续填写
                  </NavLink>
                  <Popconfirm
                    title="确认删除项目?"
                    onConfirm={() => this.confirmDelete(record.id)}
                    okText="确认"
                    cancelText="取消"
                  >
                    <Button
                      type="link"
                      style={{ marginLeft: "12px", color: "red" }}
                    >
                      删除
                    </Button>
                  </Popconfirm>
                </div>
              );
            case LivedataStates.RETURNED:
              return (
                <NavLink to={`/apply/project/edit/${record.id}`}>
                  点击修改
                </NavLink>
              );
            case LivedataStates.INIT:
              return (
                <NavLink to={`/apply/project/detail/${record.id}`}>
                  查看详情
                </NavLink>
              );
            case LivedataStates.REVIEW_APPROVED:
              return (
                <NavLink to={`/apply/project/detail/${record.id}`}>
                  查看详情
                </NavLink>
              );
            case LivedataStates.REVIEW_REJECTED:
              return (
                <NavLink to={`/apply/project/detail/${record.id}`}>
                  查看详情
                </NavLink>
              );
            default:
              break;
          }
        },
      },
    ];

    return (
      <Fragment>
        <div style={{ width: "100%" }}>
          <div style={{ paddingTop: 12 }}>
            <span style={{ fontSize: "18px", fontWeight: 600 }}>
              填写中项目列表
            </span>
          </div>
          <Divider style={{ margin: "12px 0" }} />
          <SearchForm />
        </div>
        <StyledTable
          rowKey="id"
          {...rest}
          columns={finalColumns}
          onChange={this.onTableChange}
          pagination={{
            total,
            pageSize: limit,
            current: offset / limit + 1,
            showQuickJumper: true,
            showSizeChanger: true,
            showTotal: (total, range) =>
              `共${total}条记录 第${range[0]}-${range[1]}条`,
          }}
        />
      </Fragment>
    );
  }
}

@withSession("session")
@withSaga(
  tapOnLatest(CORE_LIVE_CREATE_LIVE.SUCCESS, "live.create", function*(action) {
    const livedata = yield select(createLiveSelector);
    if (livedata.result)
      yield call(
        history.push,
        `/apply/project/edit/${livedata.result.id}?isNew=true`
      );
  })
)
@connect((state, props) => {
  const listState = listLiveDataSelector(state);
  const data = listState.result || [];
  const t = data.filter(x => x.state === LivedataStates.DRAFT);

  return {
    hasDraft: t.length,
    user: get(props.session, "result.user") || {},
  };
})
@withRouter
export default class New extends PureComponent {
  onCreate = debounce(() => {
    this.props.dispatch(
      createLive({
        body: {
          sysType: SYS_TYPE,
          district: this.props.user.province,
          createdBy: getUser(this.props).id,
        },
      })
    );
  }, 500);

  render() {
    return (
      <Fragment>
        <Top>
          {/* // <Menu defaultSelectedKeys={["1"]} mode="horizontal">
          //   <Menu.Item key="1">如何申报新项目?</Menu.Item>
          //   <Menu.Item key="2">
          //     <NavLink to="/help">项目状态说明</NavLink>
          //   </Menu.Item>
          // </Menu> */}
          <img src="/images/steps.png" alt="" />
        </Top>
        <Main>
          {this.props.hasDraft > 0 && (
            <Popconfirm
              title="已有填写中的项目，是否确认新建项⽬?"
              onConfirm={this.onCreate}
              okText="确认"
              cancelText="取消"
            >
              <Button
                type="primary"
                size="large"
                icon="plus"
                block
                style={{ marginBottom: "12px" }}
              >
                申报新项目
              </Button>
            </Popconfirm>
          )}
          {this.props.hasDraft === 0 && (
            <Button
              onClick={this.onCreate}
              type="primary"
              size="large"
              icon="plus"
              block
              style={{ marginBottom: "12px" }}
            >
              申报新项目
            </Button>
          )}

          <ProjectsTable />
        </Main>
      </Fragment>
    );
  }
}

const StyledTable = styled(Table)`
  .ant-pagination-total-text {
    position: absolute;
    left: 0;
  }

  .ant-table-column-title {
    font-weight: 600;
  }
`;

const Top = styled.div`
  padding: 24px 24px 0 24px;

  img {
    width: 100%;
  }
`;

const Main = styled.div`
  padding: 24px;
`;
