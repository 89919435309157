import React from "react";
import {
  isArray,
  isObjectLike,
  isPlainObject,
  map,
  transform,
  set,
  camelCase,
  get,
  isEmpty,
} from "lodash";
import moment from "moment";
import { ROOT_NS } from "../config";
import history from "./history";
import {
  LiveDataNewTypes,
  LivedataStatesI18N,
  LivedataStatesTagColor,
  LivedataEvents,
  // ROLES,
} from "../constants";

/**
 * add rounding function to Number class
 * @param {Number} number 需要定义精度的数
 * @param {Number} precision 精度，例如 0.1
 */
export function round(number, precision) {
  const multiplier = Math.pow(10, precision || 0);
  return Math.round(number * multiplier) / multiplier;
}

/**
 * A delay promise
 * @param {Number} ms delay miliseconds
 */
export const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

/**
 * Format time
 * @param {Date} date
 */
const formatDate = (date, format) =>
  date ? moment(date).format(format) : "--";
export const ymdhms = date => formatDate(date, "YYYY-MM-DD HH:mm:ss");
export const ymdhm = date => formatDate(date, "YYYY-MM-DD HH:mm");
export const ymd = date => formatDate(date, "YYYY-MM-DD");
export const hms = date => formatDate(date, "HH:mm:ss");

export const sliceVal = val => val.slice(-10);
/**
 * Combine date and time
 * @param {Date} date
 * @param {Date} time
 */
export function combine(date, time) {
  const m = moment(date);
  m.hour(time.hour());
  m.minute(time.minute());
  m.second(time.second());
  return m;
}

function createIteratee(converter, self) {
  return (result, value, key) =>
    set(result, converter(key), isObjectLike(value) ? self(value) : value);
}

function createHumps(keyConverter) {
  return function humps(node) {
    if (isArray(node)) return map(node, humps);
    if (isPlainObject(node))
      return transform(node, createIteratee(keyConverter, humps));
    return node;
  };
}

/**
 * Make object keys camelcase
 */
export const humps = createHumps(camelCase);

/**
 * try to parse json string
 * if error log it
 *
 * @param {string} jsonStr string tobe parsed
 */
export function tryParseJson(jsonStr) {
  let result;
  try {
    if (jsonStr) result = JSON.parse(jsonStr);
  } catch (err) {
    console.error(err);
  } finally {
    return result;
  }
}

export const formatValue = (record, key, render) => {
  const data = get(record, key);
  if (data) {
    if (render) {
      if (typeof render === "function") return render(data);
      return render[data];
    }
    return data;
  }
  return "--";
};

export const getUser = ({ session }) => {
  return get(session, "result.user");
};

const getSessionResult = ({ session }) => {
  const result = session.result;
  return result.user ? result : { user: {} };
};

export const getUserId = ({ session }) => {
  const { user } = getSessionResult({ session });
  return user.id;
};

export const getUserRole = ({ session }) => {
  const { user } = getSessionResult({ session });

  const role = (
    (user.roles || []).find(r => ROOT_NS.indexOf(r.ns) !== -1) || {}
  ).name;

  // 临时新增逻辑，2021年11月21号后暂停登录

  // if (
  //   (role === ROLES.USER || role === ROLES.PROVINCE_VIEW) &&
  //   moment().isSameOrAfter("2021-11-21")
  // ) {
  //   window.location.href = "/login?validate=1";
  // }

  return role;
};

export const renderLivedataType = type => {
  if (type === "medicine" || !type) return "--";
  return type.replace(/-/gi, " | ");
};

export const renderFile = data => {
  if (data) {
    return data.map((item, index) => {
      let u = item;
      if (typeof item === "object") {
        u = item && item.url;
      }
      return {
        uid: index + 1,
        status: "done",
        name: `上传文件-${index + 1}`,
        url: u,
      };
    });
  }
  return undefined;
};

export const getTypeName = (value = "") => {
  const x = LiveDataNewTypes.find(x => x.value === value);
  return x ? x.label : "--";
};

/**
 * 绘制状态
 */
export const renderState = val => {
  if (!LivedataStatesI18N[val]) return "--";
  return (
    <div>
      <span
        style={{
          fontSize: "18px",
          marginRight: 4,
          marginTop: -2,
          color: LivedataStatesTagColor[val],
        }}
      >
        ●
      </span>
      <span>{LivedataStatesI18N[val]}</span>
    </div>
  );
};

// 获取证书图片
export const getCertificateImg = record => {
  if (!isEmpty(record.events)) {
    const event = record.events.find(
      v => v.name === LivedataEvents.CERTIFICATE
    );

    return event && event.file;
  }
  return "";
};

export { history };

// array1 inluces array2
export const includeArr = (array1, array2) => {
  return array2.every(item => array1.includes(item));
};
