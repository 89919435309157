import React, { Fragment } from "react";
import { Icon, Modal } from "antd";
import styled from "styled-components";
import { get } from "lodash";

export default class FileRender extends React.Component {
  state = {
    show: false,
    link: "",
    type: "",
  };

  onShowPreview = (value, type) => {
    this.setState({
      show: true,
      link: value,
      type,
    });
  };

  onHidePreview = () => {
    this.setState({
      show: false,
      link: "",
      type: "",
    });
  };

  renderFile = item => {
    const { showPreview = false, imageStyle } = this.props;
    let tmp = item;
    if (typeof item === "object") {
      // 兼容旧数据, https://github.com/36node-catcm/catcm-livedata/issues/151
      tmp = get(item, "url.url") || get(item, "url");
    }
    if (tmp) {
      if (
        tmp.indexOf(".jpg") !== -1 ||
        tmp.indexOf(".jpeg") !== -1 ||
        tmp.indexOf(".png") !== -1
      ) {
        return (
          <img
            src={tmp}
            className="detail-image"
            style={imageStyle}
            onClick={() => {
              showPreview && this.onShowPreview(tmp, "image");
            }}
            alt=""
          />
        );
      } else if (tmp.indexOf(".mp4") !== -1 || tmp.indexOf(".mp3") !== -1) {
        return (
          <span
            className="detail-video"
            onClick={() => {
              showPreview && this.onShowPreview(tmp, "video");
            }}
            alt=""
          >
            <Icon type="video-camera" />
            音频
          </span>
        );
      }
      const t = decodeURI(tmp).match(/com\/([^\-rc]*)/);
      return (
        <a href={tmp} target="_blank" rel="noopener noreferrer">
          <div className="detail-file" alt="">
            <Icon type="file" />
            {get(t, 1)}
          </div>
        </a>
      );
    }

    return null;
  };

  render() {
    const { link, show, type } = this.state;
    const { file } = this.props;

    return (
      <Fragment>
        {this.renderFile(file)}
        <StyledModal
          title="预览"
          visible={show}
          onCancel={this.onHidePreview}
          footer={null}
          width={1000}
          zIndex={10000} // 水印zIndex为9999
        >
          <div className="modal-main">
            {type === "video" && (
              <video width="400" controls>
                <source src={link} />
                Your browser does not support HTML5 video.
              </video>
            )}
            {type === "image" && (
              <a target="_blank" rel="noopener noreferrer" href={link}>
                <img alt="" src={link} title="点击查看原图" />
              </a>
            )}
          </div>
        </StyledModal>
      </Fragment>
    );
  }
}

const StyledModal = styled(Modal)`
  .modal-main {
    text-align: center;
    max-height: 75vh;
    overflow: auto;

    a {
      cursor: zoom-in;
    }

    img {
      padding: 12px;
      max-width: 100%;
      cursor: zoom-in;
    }
  }
`;
