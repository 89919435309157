import React from "react";
import { Spin } from "antd";
import styled from "styled-components";
import { withSession } from "@36node/redux-session";
import { withRouter } from "react-router-dom";
import { find } from "lodash";
import qs from "qs";

import { getUserRole, getUser } from "../lib";
import { MENU, ROLES } from "../constants";

@withSession("session")
@withRouter
export default class extends React.Component {
  get role() {
    return getUserRole(this.props);
  }

  get user() {
    return getUser({ session: this.props.session });
  }

  componentDidMount() {
    if (!this.role) return;

    if (
      this.role === ROLES.PROVINCE_ADMIN ||
      this.role === ROLES.PROVINCE_VIEW
    ) {
      localStorage.setItem("DISTRICT", this.user.province);
    } else {
      localStorage.removeItem("DISTRICT");
    }
    const menu = find(MENU, { role: this.role });
    if (menu) {
      const item = menu.data[0];
      const path = item.defaultSearch
        ? `${item.path}?${qs.stringify(item.defaultSearch)}`
        : item.path;
      this.props.history.push(path);
    }
  }

  render() {
    return (
      <ContentBox id="catcm-content">
        <Spin size="large" />
      </ContentBox>
    );
  }
}

const ContentBox = styled.div`
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;
