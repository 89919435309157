import React from "react";
import ReactDOM from "react-dom";
import { configureStore } from "@36node/redux";
import { Provider } from "react-redux";
import { ConfigProvider } from "antd";
import zh_CN from "antd/lib/locale-provider/zh_CN";
import moment from "moment";
import "moment/locale/zh-cn";

import "./index.css";
import "antd/dist/antd.css";
import App from "./app";
import rootSaga from "./sagas";
import reducer from "./reducers";

moment.locale("zh-cn");

const store = configureStore(reducer);
store.runSaga(rootSaga);

ReactDOM.render(
  <ConfigProvider locale={zh_CN}>
    <Provider store={store}>
      <App />
    </Provider>
  </ConfigProvider>,
  document.getElementById("root")
);
