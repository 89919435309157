/**
 * 公用
 */

import { getTypeName, renderState } from "../../../../lib";

export const BaseColumns = [
  {
    title: "项目名称",
    key: "title",
    dataIndex: "title",
    render: x => x || "--",
  },
  {
    title: "项目编号",
    key: "no",
    dataIndex: "no",
    width: "60px",
    render: x => x || "--",
  },
  {
    title: "项目类型",
    key: "type",
    dataIndex: "type",
    width: 250,
    render: getTypeName,
  },
  {
    title: "项目状态",
    key: "state",
    dataIndex: "state",
    width: 150,
    render: renderState,
  },
  {
    title: "申报人姓名",
    key: "owner",
    dataIndex: "owner",
    width: "80px",
    render: x => x || "--",
  },
];
