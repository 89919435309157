import React from "react";
import {
  Form,
  Row,
  Col,
  Button,
  Card,
  message,
  Divider,
  Popconfirm,
} from "antd";
import { createForm } from "@36node/redux-antd";
import { find, isEqual, get } from "lodash";
import moment from "moment";

import { Fields } from "../../../../components/fileds";
import { renderFile } from "../../../../lib";
import {
  UploadMenu,
  FormItemLayout,
  InnerFormItemLayout,
} from "../../../../constants";
import { MAX_FILE_SIZE, MAX_IMAGE_SIZE, SYS_TYPE } from "../../../../config";

const key = "user";

@createForm("upload.user")
export default class UserForm extends React.PureComponent {
  state = {
    show: !!get(this.props, "data.coUser"),
  };

  get menu() {
    return find(UploadMenu, { key });
  }

  handleSave = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        values.step = this.menu.step;

        if (!this.state.show) {
          values.coUser = null;
        }

        this.props.saveDraft(values);

        // 重置 initialValues
        this.initialValues = this.props.form.getFieldsValue();
      }
    });
  };

  check = () => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        message.success(`${this.menu.title}填写正确`);
      }
    });
  };

  componentDidUpdate(prevProps) {
    const prevId = get(prevProps, "data.id");
    const curId = get(this.props, "data.id");

    const prevCoUser = get(prevProps, "data.coUser");
    const coUser = get(this.props, "data.coUser");

    // id 发生改变时， 重置initialValue
    if (prevId !== curId) {
      this.initialValues = this.props.form.getFieldsValue();
    }

    // 当coUser发生改变时
    if (!prevCoUser && coUser) {
      this.setState({
        show: true,
      });
    }
  }
  componentDidMount() {
    this.initialValues = this.props.form.getFieldsValue();
  }

  componentWillUnmount() {
    this.initialValues = undefined;
  }

  showCoUser = () => {
    this.setState({
      show: true,
    });
  };

  render() {
    const {
      form,
      data,
      showSave = true,
      showCheck = true,
      noRequire = false,
      formRef,
      disabledFields = [],
    } = this.props;

    const { show } = this.state;

    if (formRef) {
      // 判断form的values是否发生过改变
      form.isDirty = () => {
        const curValues = form.getFieldsValue();
        return !isEqual(curValues, this.initialValues);
      };
      formRef(form);
    }

    return (
      <Card
        title={this.menu.title}
        className="box"
        extra={
          showCheck && (
            <div className="form-check" onClick={this.check}>
              校验
            </div>
          )
        }
      >
        <div className="box">
          <div className="form">
            <Form labelAlign="left" {...FormItemLayout}>
              <Row gutter={8}>
                <Col span={12}>
                  <Fields.Text
                    form={form}
                    label="姓名"
                    name="owner"
                    initialValue={data.owner}
                    required={!noRequire}
                    layout={InnerFormItemLayout}
                    disabled={disabledFields.includes("owner")}
                  />
                </Col>
                <Col span={12}>
                  <Fields.Date
                    form={form}
                    label="出生日期"
                    name="birthday"
                    initialValue={
                      data.birthday ? moment(data.birthday) : undefined
                    }
                    disabledDate={current =>
                      current && current > moment().endOf("day")
                    }
                    placeholder="请选择或填写，例如2020-01-01"
                    layout={InnerFormItemLayout}
                    disabled={disabledFields.includes("birthday")}
                    format="YYYY-MM-DD"
                  />
                </Col>
              </Row>
              <Row gutter={8}>
                <Col span={12}>
                  <Fields.Select
                    form={form}
                    label="性别"
                    name="sex"
                    initialValue={data.sex}
                    layout={InnerFormItemLayout}
                    disabled={disabledFields.includes("sex")}
                    data={[
                      { title: "男", value: "男" },
                      { title: "女", value: "女" },
                    ]}
                  />
                </Col>
                <Col span={12}>
                  <Fields.Text
                    form={form}
                    label="民族"
                    name="nation"
                    initialValue={data.nation}
                    layout={InnerFormItemLayout}
                    disabled={disabledFields.includes("nation")}
                  />
                </Col>
              </Row>
              <Row gutter={8}>
                <Col span={12}>
                  <Fields.Text
                    form={form}
                    label="手机号码"
                    name="phone"
                    initialValue={data.phone}
                    layout={InnerFormItemLayout}
                    disabled={disabledFields.includes("phone")}
                    pattern={/^1\d{10}$/gi}
                    message="请填写正确的手机号"
                  />
                </Col>
                <Col span={12}>
                  <Fields.Text
                    form={form}
                    label="固话号码"
                    name="landline"
                    initialValue={data.landline}
                    layout={InnerFormItemLayout}
                    disabled={disabledFields.includes("landline")}
                    pattern={/(\(\d{3,4}\)|\d{3,4}-|\s)?\d{7,8}/gi}
                    message="请输入正确的固话号码"
                  />
                </Col>
              </Row>
              <Row gutter={8}>
                <Col span={12}>
                  <Fields.Text
                    form={form}
                    label="邮箱"
                    name="email"
                    initialValue={data.email}
                    layout={InnerFormItemLayout}
                    disabled={disabledFields.includes("email")}
                    rules={[
                      {
                        type: "email",
                        message: "请输入正确的邮箱",
                      },
                    ]}
                  />
                </Col>
                <Col span={12}>
                  <Fields.Text
                    form={form}
                    label="邮编"
                    name="postcode"
                    initialValue={data.postcode}
                    required={!noRequire}
                    layout={InnerFormItemLayout}
                    disabled={disabledFields.includes("postcode")}
                    pattern={/^[0-9]{6}$/}
                    message="请输入正确的邮编"
                  />
                </Col>
              </Row>
              <Fields.Text
                form={form}
                label="籍贯"
                name="birthplace"
                initialValue={data.birthplace}
                disabled={disabledFields.includes("birthplace")}
              />
              <Fields.Text
                form={form}
                label="通讯地址"
                name="address"
                initialValue={data.address}
                disabled={disabledFields.includes("address")}
              />
              <Row gutter={8}>
                <Col span={12}>
                  <Fields.Text
                    form={form}
                    label="身份证号"
                    name="idNumber"
                    message="请填写正确身份证号"
                    initialValue={data.idNumber}
                    layout={InnerFormItemLayout}
                    disabled={disabledFields.includes("idNumber")}
                    pattern={
                      /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/
                    }
                  />
                </Col>
                <Col span={12}>
                  <Fields.File
                    form={form}
                    label="身份证照片"
                    name="idImage"
                    extra="请上传有效身份证正反面照片，支持jpeg/jpg/png格式，单个图片大小不超过10MB"
                    title={
                      form.getFieldValue("idImage") &&
                      form.getFieldValue("idImage").length === 0
                        ? "请上传正面"
                        : "请上传反面"
                    }
                    initialValue={renderFile(data.idImage)}
                    maxFileSize={MAX_IMAGE_SIZE}
                    listType="picture-card"
                    className="uploadImg"
                    maxFileNumber="2"
                    layout={InnerFormItemLayout}
                    disabled={disabledFields.includes("idImage")}
                  />
                </Col>
              </Row>
              <Row gutter={8}>
                <Col span={12}>
                  <Fields.File
                    form={form}
                    label="手持身份证照片"
                    name="idPersonImage"
                    title="上传文件"
                    layout={InnerFormItemLayout}
                    initialValue={renderFile(data.idPersonImage)}
                    maxFileSize={MAX_IMAGE_SIZE}
                    disabled={disabledFields.includes("idPersonImage")}
                    extra="支持jpeg/jpg/png格式，单个图片大小不超过10MB"
                  />
                </Col>
                {/* <Col span={12}>
                  <Fields.Text
                    form={form}
                    label="共同申报人"
                    name="coowner"
                    initialValue={data.coowner}
                    disabled={disabledFields.includes("coowner")}
                  />
                </Col> */}
              </Row>
              <Row gutter={8}>
                <Col span={12}>
                  <Fields.Text
                    form={form}
                    label={SYS_TYPE === "LIVEDATA" ? "从医资质" : "执业资格"}
                    name="doctorYear"
                    initialValue={data.doctorYear}
                    layout={InnerFormItemLayout}
                    disabled={disabledFields.includes("doctorYear")}
                    placeholder="执业医师/执业药师/确有专长等"
                  />
                </Col>
                <Col span={12}>
                  <Fields.File
                    form={form}
                    label="执业证书"
                    name="doctorAttach"
                    title="上传文件"
                    layout={InnerFormItemLayout}
                    initialValue={renderFile(data.doctorAttach)}
                    maxFileSize={MAX_FILE_SIZE}
                    disabled={disabledFields.includes("doctorAttach")}
                  />
                </Col>
              </Row>
              <Row gutter={8}>
                <Col span={12}>
                  <Fields.Text
                    form={form}
                    label="职称"
                    name="job"
                    initialValue={data.job}
                    layout={InnerFormItemLayout}
                    disabled={disabledFields.includes("job")}
                  />
                </Col>
                <Col span={12}>
                  <Fields.File
                    form={form}
                    label="职称证书"
                    name="jobAttach"
                    title="上传文件"
                    layout={InnerFormItemLayout}
                    initialValue={renderFile(data.jobAttach)}
                    maxFileSize={MAX_FILE_SIZE}
                    disabled={disabledFields.includes("jobAttach")}
                  />
                </Col>
              </Row>
              <Row gutter={8}>
                <Col span={12}>
                  <Fields.Text
                    form={form}
                    label="职务"
                    name="position"
                    initialValue={data.position}
                    layout={InnerFormItemLayout}
                    disabled={disabledFields.includes("position")}
                  />
                </Col>
                <Col span={12}>
                  <Fields.File
                    form={form}
                    label="职务证书"
                    name="positionAttach"
                    title="上传文件"
                    layout={InnerFormItemLayout}
                    initialValue={renderFile(data.positionAttach)}
                    maxFileSize={MAX_FILE_SIZE}
                    disabled={disabledFields.includes("positionAttach")}
                  />
                </Col>
              </Row>
              <Row gutter={8}>
                <Col span={12}>
                  <Fields.Text
                    form={form}
                    label="最高学历"
                    name="degree"
                    initialValue={data.degree}
                    layout={InnerFormItemLayout}
                    disabled={disabledFields.includes("degree")}
                  />
                </Col>
                <Col span={12}>
                  <Fields.File
                    form={form}
                    label="学历证书"
                    name="degreeAttach"
                    title="上传文件"
                    layout={InnerFormItemLayout}
                    initialValue={renderFile(data.degreeAttach)}
                    maxFileSize={MAX_FILE_SIZE}
                    disabled={disabledFields.includes("degreeAttach")}
                  />
                </Col>
              </Row>
              {SYS_TYPE === "LIVEDATA" && (
                <Row gutter={8}>
                  <Col span={12}>
                    <Fields.Text
                      form={form}
                      label="曾获奖励"
                      name="award"
                      initialValue={data.award}
                      layout={InnerFormItemLayout}
                      disabled={disabledFields.includes("award")}
                    />
                  </Col>
                  <Col span={12}>
                    <Fields.File
                      form={form}
                      label="奖励附件"
                      name="awardAttach"
                      title="上传文件"
                      layout={InnerFormItemLayout}
                      initialValue={renderFile(data.awardAttach)}
                      maxFileSize={MAX_FILE_SIZE}
                      disabled={disabledFields.includes("awardAttach")}
                    />
                  </Col>
                </Row>
              )}
              {SYS_TYPE === "LIVEDATA" && (
                <Row gutter={8}>
                  <Col span={12}>
                    <Fields.Text
                      form={form}
                      label="批准文号"
                      name="medicine"
                      initialValue={data.medicine}
                      layout={InnerFormItemLayout}
                      disabled={disabledFields.includes("medicine")}
                      placeholder="1.药 2.保健食品 3.医疗器械 4.消字号 5.妆字号 6.其他"
                    />
                  </Col>
                  <Col span={12}>
                    <Fields.File
                      form={form}
                      label="批准文号附件"
                      name="medicineAttach"
                      title="上传文件"
                      layout={InnerFormItemLayout}
                      initialValue={renderFile(data.medicineAttach)}
                      maxFileSize={MAX_FILE_SIZE}
                      disabled={disabledFields.includes("medicineAttach")}
                    />
                  </Col>
                </Row>
              )}
              {SYS_TYPE === "LIVEDATA" && (
                <Row gutter={8}>
                  <Col span={12}>
                    <Fields.Text
                      form={form}
                      label="商标"
                      name="brand"
                      initialValue={data.brand}
                      layout={InnerFormItemLayout}
                      disabled={disabledFields.includes("brand")}
                    />
                  </Col>
                  <Col span={12}>
                    <Fields.File
                      form={form}
                      label="商标附件"
                      name="brandAttach"
                      title="上传文件"
                      layout={InnerFormItemLayout}
                      initialValue={renderFile(data.brandAttach)}
                      maxFileSize={MAX_FILE_SIZE}
                      disabled={disabledFields.includes("brandAttach")}
                    />
                  </Col>
                </Row>
              )}
              {SYS_TYPE === "LIVEDATA" && (
                <Row gutter={8}>
                  <Col span={12}>
                    <Fields.Text
                      form={form}
                      label="专利"
                      name="patent"
                      initialValue={data.patent}
                      layout={InnerFormItemLayout}
                      disabled={disabledFields.includes("patent")}
                    />
                  </Col>
                  <Col span={12}>
                    <Fields.File
                      form={form}
                      label="专利附件"
                      name="patentAttach"
                      title="上传文件"
                      layout={InnerFormItemLayout}
                      initialValue={renderFile(data.patentAttach)}
                      maxFileSize={MAX_FILE_SIZE}
                      disabled={disabledFields.includes("patentAttach")}
                    />
                  </Col>
                </Row>
              )}
              {SYS_TYPE === "LIVEDATA" && (
                <Row gutter={8}>
                  <Col span={12}>
                    <Fields.Text
                      form={form}
                      label="著作权"
                      name="book"
                      initialValue={data.book}
                      layout={InnerFormItemLayout}
                      disabled={disabledFields.includes("book")}
                    />
                  </Col>
                  <Col span={12}>
                    <Fields.File
                      form={form}
                      label="著作权附件"
                      name="bookAttach"
                      title="上传文件"
                      layout={InnerFormItemLayout}
                      initialValue={renderFile(data.bookAttach)}
                      maxFileSize={MAX_FILE_SIZE}
                      disabled={disabledFields.includes("bookAttach")}
                    />
                  </Col>
                </Row>
              )}
              {SYS_TYPE === "LIVEDATA" && (
                <Row gutter={8}>
                  <Col span={12}>
                    <Fields.Text
                      form={form}
                      label="论文"
                      name="paper"
                      initialValue={data.paper}
                      layout={InnerFormItemLayout}
                      disabled={disabledFields.includes("paper")}
                    />
                  </Col>
                  <Col span={12}>
                    <Fields.File
                      form={form}
                      label="论文附件"
                      name="paperAttach"
                      title="上传文件"
                      layout={InnerFormItemLayout}
                      initialValue={renderFile(data.paperAttach)}
                      maxFileSize={MAX_FILE_SIZE}
                      disabled={disabledFields.includes("paperAttach")}
                    />
                  </Col>
                </Row>
              )}
              {SYS_TYPE === "LIVEDATA" && (
                <Row gutter={8}>
                  <Col span={12}>
                    <Fields.Text
                      form={form}
                      label="相关产品"
                      name="product"
                      initialValue={data.product}
                      layout={InnerFormItemLayout}
                      disabled={disabledFields.includes("product")}
                    />
                  </Col>
                  <Col span={12}>
                    <Fields.File
                      form={form}
                      label="产品附件"
                      name="productAttach"
                      title="上传文件"
                      layout={InnerFormItemLayout}
                      initialValue={renderFile(data.productAttach)}
                      maxFileSize={MAX_FILE_SIZE}
                      disabled={disabledFields.includes("productAttach")}
                    />
                  </Col>
                </Row>
              )}
              {/* <Row gutter={8}>
                <Col span={12}>
                  <Fields.Text
                    form={form}
                    label="相关器具"
                    name="appliance"
                    initialValue={data.appliance}
                    layout={InnerFormItemLayout}
                    disabled={disabledFields.includes("appliance")}
                  />
                </Col>
                <Col span={12}>
                  <Fields.File
                    form={form}
                    label="器具附件"
                    name="applianceAttach"
                    title="上传文件"
                    layout={InnerFormItemLayout}
                    initialValue={renderFile(data.applianceAttach)}
                    maxFileSize={MAX_FILE_SIZE}
                    disabled={disabledFields.includes("applianceAttach")}
                  />
                </Col>
              </Row>
              <Row gutter={8}>
                <Col span={12}>
                  <Fields.Text
                    form={form}
                    label="地标产品"
                    name="sample"
                    initialValue={data.sample}
                    layout={InnerFormItemLayout}
                    disabled={disabledFields.includes("sample")}
                  />
                </Col>
                <Col span={12}>
                  <Fields.File
                    form={form}
                    label="产品附件"
                    name="sampleAttach"
                    title="上传文件"
                    layout={InnerFormItemLayout}
                    initialValue={renderFile(data.sampleAttach)}
                    maxFileSize={MAX_FILE_SIZE}
                    disabled={disabledFields.includes("sampleAttach")}
                  />
                </Col>
              </Row> */}
              {SYS_TYPE === "LIVEDATA" && (
                <Row gutter={8}>
                  <Col span={12}>
                    <Fields.Text
                      form={form}
                      label="科研项目"
                      name="project"
                      initialValue={data.project}
                      layout={InnerFormItemLayout}
                      disabled={disabledFields.includes("project")}
                    />
                  </Col>
                  <Col span={12}>
                    <Fields.File
                      form={form}
                      label="项目附件"
                      name="projectAttach"
                      title="上传文件"
                      layout={InnerFormItemLayout}
                      initialValue={renderFile(data.projectAttach)}
                      maxFileSize={MAX_FILE_SIZE}
                      disabled={disabledFields.includes("projectAttach")}
                    />
                  </Col>
                </Row>
              )}
              {SYS_TYPE === "LIVEDATA" && (
                <Row gutter={8}>
                  <Col span={12}>
                    <Fields.Text
                      form={form}
                      label="相关机构情况"
                      name="company"
                      initialValue={data.company}
                      layout={InnerFormItemLayout}
                      disabled={disabledFields.includes("company")}
                      placeholder="为应用本技术而成立的医院、诊所、药厂、公司等"
                    />
                  </Col>
                  <Col span={12}>
                    <Fields.File
                      form={form}
                      label="相关机构情况附件"
                      name="companyAttach"
                      title="上传文件"
                      layout={InnerFormItemLayout}
                      initialValue={renderFile(data.companyAttach)}
                      maxFileSize={MAX_FILE_SIZE}
                      disabled={disabledFields.includes("companyAttach")}
                    />
                  </Col>
                </Row>
              )}
              {SYS_TYPE === "LIVEDATA" && (
                <Row gutter={8}>
                  <Col span={12}>
                    <Fields.Text
                      form={form}
                      label="非遗"
                      name="heritage"
                      initialValue={data.heritage}
                      layout={InnerFormItemLayout}
                      disabled={disabledFields.includes("heritage")}
                    />
                  </Col>
                  <Col span={12}>
                    <Fields.File
                      form={form}
                      label="非遗附件"
                      name="heritageAttach"
                      title="上传文件"
                      layout={InnerFormItemLayout}
                      initialValue={renderFile(data.heritageAttach)}
                      maxFileSize={MAX_FILE_SIZE}
                      disabled={disabledFields.includes("heritageAttach")}
                    />
                  </Col>
                </Row>
              )}
              {/* <Row gutter={8}>
                <Col span={12}>
                  <Fields.Text
                    form={form}
                    label="传承代数"
                    name="inherite"
                    initialValue={data.inherite}
                    layout={InnerFormItemLayout}
                    disabled={disabledFields.includes("inherite")}
                  />
                </Col>
                <Col span={12}>
                  <Fields.File
                    form={form}
                    label="传承附件"
                    name="inheriteAttach"
                    title="上传文件"
                    layout={InnerFormItemLayout}
                    initialValue={renderFile(data.inheriteAttach)}
                    maxFileSize={MAX_FILE_SIZE}
                    disabled={disabledFields.includes("inheriteAttach")}
                  />
                </Col>
              </Row> */}
              <Row gutter={8}>
                <Col span={12}>
                  <Fields.Text
                    form={form}
                    label="家庭住址"
                    name="homeaddress"
                    initialValue={data.homeaddress}
                    layout={InnerFormItemLayout}
                    disabled={disabledFields.includes("homeaddress")}
                  />
                </Col>
                <Col span={12}>
                  <Fields.File
                    form={form}
                    label="产权证明"
                    name="homeaddressAttach"
                    title="上传文件"
                    layout={InnerFormItemLayout}
                    initialValue={renderFile(data.homeaddressAttach)}
                    maxFileSize={MAX_FILE_SIZE}
                    disabled={disabledFields.includes("homeaddressAttach")}
                  />
                </Col>
              </Row>
              <Row gutter={8}>
                <Col span={12}>
                  <Fields.Text
                    form={form}
                    label="公司地址"
                    name="companyaddress"
                    initialValue={data.companyaddress}
                    layout={InnerFormItemLayout}
                    disabled={disabledFields.includes("companyaddress")}
                  />
                </Col>
                <Col span={12}>
                  <Fields.File
                    form={form}
                    label="产权证明"
                    name="companyaddressAttach"
                    title="上传文件"
                    layout={InnerFormItemLayout}
                    initialValue={renderFile(data.companyaddressAttach)}
                    maxFileSize={MAX_FILE_SIZE}
                    disabled={disabledFields.includes("companyaddressAttach")}
                  />
                </Col>
              </Row>
              <Fields.File
                form={form}
                label={
                  SYS_TYPE === "LIVEDATA" ? "其他相关证据资料" : "其他附件"
                }
                name="otherUserAttach"
                extra={
                  SYS_TYPE === "LIVEDATA"
                    ? "院士、国医大师、岐黄学者，以及政府或学术机构授予的其他称号等，不允许使用压缩文件，单个文件大小不超过1GB"
                    : "上传证据文件（文件格式应为PDF、JPG、MP4、MP3，不允许使用压缩文件，单个文件大小不超过1GB）"
                }
                title="上传文件"
                initialValue={renderFile(data.otherUserAttach)}
                maxFileSize={MAX_FILE_SIZE}
              />
              <Fields.TextArea
                form={form}
                label="备注"
                name="ownerRemark"
                placeholder="请输入备注事项"
                initialValue={data.ownerRemark}
                disabled={disabledFields.includes("ownerRemark")}
              />
              {!show && (
                <Button type="primary" icon="plus" onClick={this.showCoUser}>
                  添加共同申报人
                </Button>
              )}
              {show && (
                <div>
                  <Divider orientation="left">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      共同申报人
                      <Popconfirm
                        title="确定删除共同申报人吗"
                        onConfirm={() => {
                          this.setState({
                            show: false,
                          });
                        }}
                      >
                        <Button
                          type="danger"
                          ghost
                          style={{ marginLeft: 20 }}
                          size="small"
                        >
                          删除
                        </Button>
                      </Popconfirm>
                    </div>
                  </Divider>
                  <Row gutter={8}>
                    <Col span={12}>
                      <Fields.Text
                        form={form}
                        label="姓名"
                        name="coUser.owner"
                        initialValue={(data.coUser || {}).owner}
                        required={!noRequire}
                        layout={InnerFormItemLayout}
                        disabled={
                          data.coUser && disabledFields.includes("owner")
                        }
                      />
                    </Col>
                    <Col span={12}>
                      <Fields.Date
                        form={form}
                        label="出生日期"
                        name="coUser.birthday"
                        initialValue={
                          data.birthday
                            ? moment((data.coUser || {}).birthday)
                            : undefined
                        }
                        disabledDate={current =>
                          current && current > moment().endOf("day")
                        }
                        placeholder="请选择或填写，例如2020-01-01"
                        layout={InnerFormItemLayout}
                        disabled={
                          data.coUser && disabledFields.includes("birthday")
                        }
                        format="YYYY-MM-DD"
                      />
                    </Col>
                  </Row>
                  <Row gutter={8}>
                    <Col span={12}>
                      <Fields.Select
                        form={form}
                        label="性别"
                        name="coUser.sex"
                        initialValue={(data.coUser || {}).sex}
                        layout={InnerFormItemLayout}
                        disabled={data.coUser && disabledFields.includes("sex")}
                        data={[
                          { title: "男", value: "男" },
                          { title: "女", value: "女" },
                        ]}
                      />
                    </Col>
                    <Col span={12}>
                      <Fields.Text
                        form={form}
                        label="民族"
                        name="coUser.nation"
                        initialValue={(data.coUser || {}).nation}
                        layout={InnerFormItemLayout}
                        disabled={
                          data.coUser && disabledFields.includes("nation")
                        }
                      />
                    </Col>
                  </Row>
                  <Row gutter={8}>
                    <Col span={12}>
                      <Fields.Text
                        form={form}
                        label="手机号码"
                        name="coUser.phone"
                        initialValue={(data.coUser || {}).phone}
                        layout={InnerFormItemLayout}
                        disabled={
                          data.coUser && disabledFields.includes("phone")
                        }
                        pattern={/^1\d{10}$/gi}
                        message="请填写正确的手机号"
                      />
                    </Col>
                    <Col span={12}>
                      <Fields.Text
                        form={form}
                        label="固话号码"
                        name="coUser.landline"
                        initialValue={(data.coUser || {}).landline}
                        layout={InnerFormItemLayout}
                        disabled={
                          data.coUser && disabledFields.includes("landline")
                        }
                        pattern={/(\(\d{3,4}\)|\d{3,4}-|\s)?\d{7,8}/gi}
                        message="请输入正确的固话号码"
                      />
                    </Col>
                  </Row>
                  <Row gutter={8}>
                    <Col span={12}>
                      <Fields.Text
                        form={form}
                        label="邮箱"
                        name="coUser.email"
                        initialValue={(data.coUser || {}).email}
                        layout={InnerFormItemLayout}
                        disabled={
                          data.coUser && disabledFields.includes("email")
                        }
                        rules={[
                          {
                            type: "email",
                            message: "请输入正确的邮箱",
                          },
                        ]}
                      />
                    </Col>
                    <Col span={12}>
                      <Fields.Text
                        form={form}
                        label="邮编"
                        name="coUser.postcode"
                        initialValue={(data.coUser || {}).postcode}
                        required={!noRequire}
                        layout={InnerFormItemLayout}
                        disabled={
                          data.coUser && disabledFields.includes("postcode")
                        }
                        pattern={/^[0-9]{6}$/}
                        message="请输入正确的邮编"
                      />
                    </Col>
                  </Row>
                  <Fields.Text
                    form={form}
                    label="籍贯"
                    name="coUser.birthplace"
                    initialValue={(data.coUser || {}).birthplace}
                    disabled={
                      data.coUser && disabledFields.includes("birthplace")
                    }
                  />
                  <Fields.Text
                    form={form}
                    label="通讯地址"
                    name="coUser.address"
                    initialValue={(data.coUser || {}).address}
                    disabled={data.coUser && disabledFields.includes("address")}
                  />
                  <Row gutter={8}>
                    <Col span={12}>
                      <Fields.Text
                        form={form}
                        label="身份证号"
                        name="coUser.idNumber"
                        message="请填写正确身份证号"
                        initialValue={(data.coUser || {}).idNumber}
                        layout={InnerFormItemLayout}
                        disabled={
                          data.coUser && disabledFields.includes("idNumber")
                        }
                        pattern={
                          /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/
                        }
                      />
                    </Col>
                    <Col span={12}>
                      <Fields.File
                        form={form}
                        label="身份证照片"
                        name="coUser.idImage"
                        extra="请上传有效身份证正反面照片，支持jpeg/jpg/png格式，单个图片大小不超过10MB"
                        title={
                          form.getFieldValue("coUser.idImage") &&
                          form.getFieldValue("coUser.idImage").length === 0
                            ? "请上传正面"
                            : "请上传反面"
                        }
                        initialValue={renderFile((data.coUser || {}).idImage)}
                        maxFileSize={MAX_IMAGE_SIZE}
                        listType="picture-card"
                        className="uploadImg"
                        maxFileNumber="2"
                        layout={InnerFormItemLayout}
                        disabled={
                          data.coUser && disabledFields.includes("idImage")
                        }
                      />
                    </Col>
                  </Row>
                  <Row gutter={8}>
                    <Col span={12}>
                      <Fields.File
                        form={form}
                        label="手持身份证照片"
                        name="coUser.idPersonImage"
                        title="上传文件"
                        layout={InnerFormItemLayout}
                        initialValue={renderFile(
                          (data.coUser || {}).idPersonImage
                        )}
                        maxFileSize={MAX_IMAGE_SIZE}
                        disabled={
                          data.coUser &&
                          disabledFields.includes("idPersonImage")
                        }
                      />
                    </Col>
                  </Row>
                  <Row gutter={8}>
                    <Col span={12}>
                      <Fields.Text
                        form={form}
                        label={
                          SYS_TYPE === "LIVEDATA" ? "从医资质" : "执业资格"
                        }
                        name="coUser.doctorYear"
                        initialValue={(data.coUser || {}).doctorYear}
                        layout={InnerFormItemLayout}
                        disabled={
                          data.coUser && disabledFields.includes("doctorYear")
                        }
                        placeholder="执业医师/执业药师/确有专长等"
                      />
                    </Col>
                    <Col span={12}>
                      <Fields.File
                        form={form}
                        label="执业证书"
                        name="coUser.doctorAttach"
                        title="上传文件"
                        layout={InnerFormItemLayout}
                        initialValue={renderFile(
                          (data.coUser || {}).doctorAttach
                        )}
                        maxFileSize={MAX_FILE_SIZE}
                        disabled={
                          data.coUser && disabledFields.includes("doctorAttach")
                        }
                      />
                    </Col>
                  </Row>
                  <Row gutter={8}>
                    <Col span={12}>
                      <Fields.Text
                        form={form}
                        label="职称"
                        name="coUser.job"
                        initialValue={(data.coUser || {}).job}
                        layout={InnerFormItemLayout}
                        disabled={data.coUser && disabledFields.includes("job")}
                      />
                    </Col>
                    <Col span={12}>
                      <Fields.File
                        form={form}
                        label="职称证书"
                        name="coUser.jobAttach"
                        title="上传文件"
                        layout={InnerFormItemLayout}
                        initialValue={renderFile((data.coUser || {}).jobAttach)}
                        maxFileSize={MAX_FILE_SIZE}
                        disabled={
                          data.coUser && disabledFields.includes("jobAttach")
                        }
                      />
                    </Col>
                  </Row>
                  <Row gutter={8}>
                    <Col span={12}>
                      <Fields.Text
                        form={form}
                        label="职务"
                        name="coUser.position"
                        initialValue={(data.coUser || {}).position}
                        layout={InnerFormItemLayout}
                        disabled={
                          data.coUser && disabledFields.includes("position")
                        }
                      />
                    </Col>
                    <Col span={12}>
                      <Fields.File
                        form={form}
                        label="职务证书"
                        name="coUser.positionAttach"
                        title="上传文件"
                        layout={InnerFormItemLayout}
                        initialValue={renderFile(
                          (data.coUser || {}).positionAttach
                        )}
                        maxFileSize={MAX_FILE_SIZE}
                        disabled={
                          data.coUser &&
                          disabledFields.includes("positionAttach")
                        }
                      />
                    </Col>
                  </Row>
                  <Row gutter={8}>
                    <Col span={12}>
                      <Fields.Text
                        form={form}
                        label="最高学历"
                        name="coUser.degree"
                        initialValue={(data.coUser || {}).degree}
                        layout={InnerFormItemLayout}
                        disabled={
                          data.coUser && disabledFields.includes("degree")
                        }
                      />
                    </Col>
                    <Col span={12}>
                      <Fields.File
                        form={form}
                        label="学历证书"
                        name="coUser.degreeAttach"
                        title="上传文件"
                        layout={InnerFormItemLayout}
                        initialValue={renderFile(
                          (data.coUser || {}).degreeAttach
                        )}
                        maxFileSize={MAX_FILE_SIZE}
                        disabled={
                          data.coUser && disabledFields.includes("degreeAttach")
                        }
                      />
                    </Col>
                  </Row>

                  {SYS_TYPE === "LIVEDATA" && (
                    <Row gutter={8}>
                      <Col span={12}>
                        <Fields.Text
                          form={form}
                          label="曾获奖励"
                          name="coUser.award"
                          initialValue={(data.coUser || {}).award}
                          layout={InnerFormItemLayout}
                          disabled={
                            data.coUser && disabledFields.includes("award")
                          }
                        />
                      </Col>
                      <Col span={12}>
                        <Fields.File
                          form={form}
                          label="奖励附件"
                          name="coUser.awardAttach"
                          title="上传文件"
                          layout={InnerFormItemLayout}
                          initialValue={renderFile(
                            (data.coUser || {}).awardAttach
                          )}
                          maxFileSize={MAX_FILE_SIZE}
                          disabled={
                            data.coUser &&
                            disabledFields.includes("awardAttach")
                          }
                        />
                      </Col>
                    </Row>
                  )}
                  {SYS_TYPE === "LIVEDATA" && (
                    <Row gutter={8}>
                      <Col span={12}>
                        <Fields.Text
                          form={form}
                          label="批准文号"
                          name="coUser.medicine"
                          initialValue={(data.coUser || {}).medicine}
                          layout={InnerFormItemLayout}
                          disabled={
                            data.coUser && disabledFields.includes("medicine")
                          }
                          placeholder="1.药 2.保健食品 3.医疗器械 4.消字号 5.妆字号 6.其他"
                        />
                      </Col>
                      <Col span={12}>
                        <Fields.File
                          form={form}
                          label="批准文号附件"
                          name="coUser.medicineAttach"
                          title="上传文件"
                          layout={InnerFormItemLayout}
                          initialValue={renderFile(
                            (data.coUser || {}).medicineAttach
                          )}
                          maxFileSize={MAX_FILE_SIZE}
                          disabled={
                            data.coUser &&
                            disabledFields.includes("medicineAttach")
                          }
                        />
                      </Col>
                    </Row>
                  )}
                  {SYS_TYPE === "LIVEDATA" && (
                    <Row gutter={8}>
                      <Col span={12}>
                        <Fields.Text
                          form={form}
                          label="商标"
                          name="coUser.brand"
                          initialValue={(data.coUser || {}).brand}
                          layout={InnerFormItemLayout}
                          disabled={
                            data.coUser && disabledFields.includes("brand")
                          }
                        />
                      </Col>
                      <Col span={12}>
                        <Fields.File
                          form={form}
                          label="商标附件"
                          name="coUser.brandAttach"
                          title="上传文件"
                          layout={InnerFormItemLayout}
                          initialValue={renderFile(
                            (data.coUser || {}).brandAttach
                          )}
                          maxFileSize={MAX_FILE_SIZE}
                          disabled={
                            data.coUser &&
                            disabledFields.includes("brandAttach")
                          }
                        />
                      </Col>
                    </Row>
                  )}
                  {SYS_TYPE === "LIVEDATA" && (
                    <Row gutter={8}>
                      <Col span={12}>
                        <Fields.Text
                          form={form}
                          label="专利"
                          name="coUser.patent"
                          initialValue={(data.coUser || {}).patent}
                          layout={InnerFormItemLayout}
                          disabled={
                            data.coUser && disabledFields.includes("patent")
                          }
                        />
                      </Col>
                      <Col span={12}>
                        <Fields.File
                          form={form}
                          label="专利附件"
                          name="coUser.patentAttach"
                          title="上传文件"
                          layout={InnerFormItemLayout}
                          initialValue={renderFile(
                            (data.coUser || {}).patentAttach
                          )}
                          maxFileSize={MAX_FILE_SIZE}
                          disabled={
                            data.coUser &&
                            disabledFields.includes("patentAttach")
                          }
                        />
                      </Col>
                    </Row>
                  )}
                  {SYS_TYPE === "LIVEDATA" && (
                    <Row gutter={8}>
                      <Col span={12}>
                        <Fields.Text
                          form={form}
                          label="著作权"
                          name="coUser.book"
                          initialValue={(data.coUser || {}).book}
                          layout={InnerFormItemLayout}
                          disabled={
                            data.coUser && disabledFields.includes("book")
                          }
                        />
                      </Col>
                      <Col span={12}>
                        <Fields.File
                          form={form}
                          label="著作权附件"
                          name="coUser.bookAttach"
                          title="上传文件"
                          layout={InnerFormItemLayout}
                          initialValue={renderFile(
                            (data.coUser || {}).bookAttach
                          )}
                          maxFileSize={MAX_FILE_SIZE}
                          disabled={
                            data.coUser && disabledFields.includes("bookAttach")
                          }
                        />
                      </Col>
                    </Row>
                  )}
                  {SYS_TYPE === "LIVEDATA" && (
                    <Row gutter={8}>
                      <Col span={12}>
                        <Fields.Text
                          form={form}
                          label="论文"
                          name="coUser.paper"
                          initialValue={(data.coUser || {}).paper}
                          layout={InnerFormItemLayout}
                          disabled={
                            data.coUser && disabledFields.includes("paper")
                          }
                        />
                      </Col>
                      <Col span={12}>
                        <Fields.File
                          form={form}
                          label="论文附件"
                          name="coUser.paperAttach"
                          title="上传文件"
                          layout={InnerFormItemLayout}
                          initialValue={renderFile(
                            (data.coUser || {}).paperAttach
                          )}
                          maxFileSize={MAX_FILE_SIZE}
                          disabled={
                            data.coUser &&
                            disabledFields.includes("paperAttach")
                          }
                        />
                      </Col>
                    </Row>
                  )}
                  {SYS_TYPE === "LIVEDATA" && (
                    <Row gutter={8}>
                      <Col span={12}>
                        <Fields.Text
                          form={form}
                          label="相关产品"
                          name="coUser.product"
                          initialValue={(data.coUser || {}).product}
                          layout={InnerFormItemLayout}
                          disabled={
                            data.coUser && disabledFields.includes("product")
                          }
                        />
                      </Col>
                      <Col span={12}>
                        <Fields.File
                          form={form}
                          label="产品附件"
                          name="coUser.productAttach"
                          title="上传文件"
                          layout={InnerFormItemLayout}
                          initialValue={renderFile(
                            (data.coUser || {}).productAttach
                          )}
                          maxFileSize={MAX_FILE_SIZE}
                          disabled={
                            data.coUser &&
                            disabledFields.includes("productAttach")
                          }
                        />
                      </Col>
                    </Row>
                  )}
                  {SYS_TYPE === "LIVEDATA" && (
                    <Row gutter={8}>
                      <Col span={12}>
                        <Fields.Text
                          form={form}
                          label="科研项目"
                          name="coUser.project"
                          initialValue={(data.coUser || {}).project}
                          layout={InnerFormItemLayout}
                          disabled={
                            data.coUser && disabledFields.includes("project")
                          }
                        />
                      </Col>
                      <Col span={12}>
                        <Fields.File
                          form={form}
                          label="项目附件"
                          name="coUser.projectAttach"
                          title="上传文件"
                          layout={InnerFormItemLayout}
                          initialValue={renderFile(
                            (data.coUser || {}).projectAttach
                          )}
                          maxFileSize={MAX_FILE_SIZE}
                          disabled={
                            data.coUser &&
                            disabledFields.includes("projectAttach")
                          }
                        />
                      </Col>
                    </Row>
                  )}
                  {SYS_TYPE === "LIVEDATA" && (
                    <Row gutter={8}>
                      <Col span={12}>
                        <Fields.Text
                          form={form}
                          label="相关机构情况"
                          name="coUser.company"
                          initialValue={(data.coUser || {}).company}
                          layout={InnerFormItemLayout}
                          disabled={
                            data.coUser && disabledFields.includes("company")
                          }
                          placeholder="为应用本技术而成立的医院、诊所、药厂、公司等"
                        />
                      </Col>
                      <Col span={12}>
                        <Fields.File
                          form={form}
                          label="相关机构情况附件"
                          name="coUser.companyAttach"
                          title="上传文件"
                          layout={InnerFormItemLayout}
                          initialValue={renderFile(
                            (data.coUser || {}).companyAttach
                          )}
                          maxFileSize={MAX_FILE_SIZE}
                          disabled={
                            data.coUser &&
                            disabledFields.includes("companyAttach")
                          }
                        />
                      </Col>
                    </Row>
                  )}
                  {SYS_TYPE === "LIVEDATA" && (
                    <Row gutter={8}>
                      <Col span={12}>
                        <Fields.Text
                          form={form}
                          label="非遗"
                          name="coUser.heritage"
                          initialValue={(data.coUser || {}).heritage}
                          layout={InnerFormItemLayout}
                          disabled={
                            data.coUser && disabledFields.includes("heritage")
                          }
                        />
                      </Col>
                      <Col span={12}>
                        <Fields.File
                          form={form}
                          label="非遗附件"
                          name="coUser.heritageAttach"
                          title="上传文件"
                          layout={InnerFormItemLayout}
                          initialValue={renderFile(
                            (data.coUser || {}).heritageAttach
                          )}
                          maxFileSize={MAX_FILE_SIZE}
                          disabled={
                            data.coUser &&
                            disabledFields.includes("heritageAttach")
                          }
                        />
                      </Col>
                    </Row>
                  )}
                  <Row gutter={8}>
                    <Col span={12}>
                      <Fields.Text
                        form={form}
                        label="家庭住址"
                        name="coUser.homeaddress"
                        initialValue={(data.coUser || {}).homeaddress}
                        layout={InnerFormItemLayout}
                        disabled={
                          data.coUser && disabledFields.includes("homeaddress")
                        }
                      />
                    </Col>
                    <Col span={12}>
                      <Fields.File
                        form={form}
                        label="产权证明"
                        name="coUser.homeaddressAttach"
                        title="上传文件"
                        layout={InnerFormItemLayout}
                        initialValue={renderFile(
                          (data.coUser || {}).homeaddressAttach
                        )}
                        maxFileSize={MAX_FILE_SIZE}
                        disabled={
                          data.coUser &&
                          disabledFields.includes("homeaddressAttach")
                        }
                      />
                    </Col>
                  </Row>
                  <Row gutter={8}>
                    <Col span={12}>
                      <Fields.Text
                        form={form}
                        label="公司地址"
                        name="coUser.companyaddress"
                        initialValue={(data.coUser || {}).companyaddress}
                        layout={InnerFormItemLayout}
                        disabled={
                          data.coUser &&
                          disabledFields.includes("companyaddress")
                        }
                      />
                    </Col>
                    <Col span={12}>
                      <Fields.File
                        form={form}
                        label="产权证明"
                        name="coUser.companyaddressAttach"
                        title="上传文件"
                        layout={InnerFormItemLayout}
                        initialValue={renderFile(
                          (data.coUser || {}).companyaddressAttach
                        )}
                        maxFileSize={MAX_FILE_SIZE}
                        disabled={
                          data.coUser &&
                          disabledFields.includes("companyaddressAttach")
                        }
                      />
                    </Col>
                  </Row>
                  <Fields.File
                    form={form}
                    label={
                      SYS_TYPE === "LIVEDATA" ? "其他相关证据资料" : "其他附件"
                    }
                    name="coUser.otherUserAttach"
                    extra={
                      SYS_TYPE === "LIVEDATA"
                        ? "院士、国医大师、岐黄学者，以及政府或学术机构授予的其他称号等，不允许使用压缩文件，单个文件大小不超过1GB"
                        : "上传证据文件（文件格式应为PDF、JPG、MP4、MP3，不允许使用压缩文件，单个文件大小不超过1GB）"
                    }
                    title="上传文件"
                    initialValue={renderFile(
                      (data.coUser || {}).otherUserAttach
                    )}
                    maxFileSize={MAX_FILE_SIZE}
                  />
                  <Fields.TextArea
                    form={form}
                    label="备注"
                    name="coUser.ownerRemark"
                    placeholder="请输入备注事项"
                    initialValue={(data.coUser || {}).ownerRemark}
                    disabled={
                      data.coUser && disabledFields.includes("ownerRemark")
                    }
                  />
                </div>
              )}
            </Form>
          </div>
        </div>
        {showSave && (
          <div className="btns">
            <Button size="large" onClick={this.props.stepBack}>
              返回上一步
            </Button>
            <Button
              size="large"
              type="primary"
              onClick={this.handleSave}
              style={{ marginLeft: 16 }}
            >
              保存并填写下一步
            </Button>
          </div>
        )}
      </Card>
    );
  }
}
