import { makeApiTypes as mt } from "@36node/redux";

export const LOGIN = mt("LOGIN");
export const LOGOUT = mt("LOGOUT");
export const REFRESH = mt("REFRESH");
export const UN_AUTH = mt("UN_AUTH");
export const CAPTCHA = mt("CAPTCHA");

// auth user
export const AUTH_USER_LIST_USERS = mt("AUTH_USER_LIST_USERS");
export const AUTH_USER_CREATE_USER = mt("AUTH_USER_CREATE_USER");
export const AUTH_USER_UPDATE_USER = mt("AUTH_USER_UPDATE_USER");
export const AUTH_USER_DELETE_USER = mt("AUTH_USER_DELETE_USER");
export const AUTH_USER_GET_USER = mt("AUTH_USER_GET_USER");
export const AUTH_USER_LOGIN_HISTORY = mt("AUTH_USER_LOGIN_HISTORY");

// auth session
export const AUTH_SESSION_LIST_SESSIONS = mt("AUTH_SESSION_LIST_SESSIONS");

// core LivaData
export const CORE_LIVE_CREATE_LIVE = mt("CORE_LIVE_CREATE_LIVE");
export const CORE_LIVE_DELETE_LIVE = mt("CORE_LIVE_DELETE_LIVE");
export const CORE_LIVE_LIST_LIVE = mt("CORE_LIVE_LIST_LIVE");
export const CORE_LIVE_SHOW_LIVE = mt("CORE_LIVE_SHOW_LIVE");
export const CORE_LIVE_UPDATE_LIVE = mt("CORE_LIVE_UPDATE_LIVE");
export const CORE_LIVE_CREATE_LIVEEVENT = mt("CORE_LIVE_CREATE_LIVEEVENT");
export const CORE_LIVE_SUMMARY = mt("CORE_LIVE_SUMMARY");

// core extra
export const CORE_LIVE_CREATE_EXTRA = mt("CORE_LIVE_CREATE_EXTRA");
export const CORE_LIVE_LIST_EXTRA = mt("CORE_LIVE_LIST_EXTRA");
export const CORE_LIVE_SHOW_EXTRA = mt("CORE_LIVE_SHOW_EXTRA");
export const CORE_LIVE_UPDATE_EXTRA = mt("CORE_LIVE_UPDATE_EXTRA");
export const CORE_LIVE_DELETE_EXTRA = mt("CORE_LIVE_DELETE_EXTRA");
export const CORE_LIVE_CREATE_EXTRAEVENT = mt("CORE_LIVE_CREATE_EXTRAEVENT");
export const CORE_EXTRA_SUMMARY = mt("CORE_EXTRA_SUMMARY");

// feedback
export const CORE_LIVE_CREATE_FEEDBACK = mt("CORE_LIVE_CREATE_FEEDBACK");
export const CORE_LIVE_LIST_FEEDBACK = mt("CORE_LIVE_LIST_FEEDBACK");

export const CORE_USER_OPERATIONS = mt("CORE_USER_OPERATIONS");
