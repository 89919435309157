/**
 * 编辑界面发生跳转时弹出modal进行保护
 */

import React, { PureComponent } from "react";
import { Prompt } from "react-router-dom";
import { Modal } from "antd";

export default class EditLeavingGuard extends PureComponent {
  state = {
    visible: false,
    lastLocation: null,
    confirmedNavigation: false,
  };

  showModal = location =>
    this.setState({
      visible: true,
      lastLocation: location,
    });

  closeModal = callback => {
    if (typeof callback === "function") {
      this.setState(
        {
          visible: false,
        },
        callback
      );
    } else {
      this.setState({
        visible: false,
      });
    }
  };

  handleBlockedNavigation = nextLocation => {
    const { confirmedNavigation } = this.state;
    const { shouldBlockNavigation } = this.props;
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      this.showModal(nextLocation);
      return false;
    }

    return true;
  };

  handleConfirmNavigationClick = () =>
    this.closeModal(() => {
      const { navigate } = this.props;
      const { lastLocation } = this.state;
      if (lastLocation) {
        this.setState(
          {
            confirmedNavigation: true,
          },
          () => {
            // Navigate to the previous blocked location with your navigate function
            navigate(lastLocation.pathname);
          }
        );
      }
    });

  render() {
    const { when } = this.props;
    const { visible } = this.state;

    return (
      <>
        <Prompt when={when} message={this.handleBlockedNavigation} />

        <Modal
          visible={visible}
          onOk={this.handleConfirmNavigationClick}
          onCancel={this.closeModal}
          title="温馨提示"
        >
          确定离开页面, 未保存的修改将会丢失？
        </Modal>
      </>
    );
  }
}
