import React, { PureComponent } from "react";
import { Form, Row, Col, Button, Icon } from "antd";
import styled from "styled-components";
import moment from "moment";

import { Fields } from "../fileds";
import { FilterFormOptions } from "../../constants";
import { ymd } from "../../lib";
import { isArray } from "lodash";

export default class FilterForm extends PureComponent {
  state = {
    expand: false,
  };

  toggle = () => {
    this.setState({ expand: !this.state.expand });
  };

  genFilter = (form, item) => {
    const { query = {} } = this.props;
    const { label, formType, initialValue, ...rest } = FilterFormOptions[item];
    const FilterBox = Fields[formType];

    let value = query[initialValue];
    if (value) {
      if (item === "type") {
        value = value.split("-");
      } else if (item === "publishedAt") {
        value = [moment(query.publishedAt_gt), moment(query.publishedAt_gt)];
      } else if (item === "submittedAt") {
        value = [moment(query.submittedAt_gt), moment(query.submittedAt_gt)];
      }
    } else value = undefined;

    return (
      <Col span={8} key={item}>
        <FilterBox
          form={form}
          name={item}
          label={label}
          initialValue={value}
          {...rest}
        />
      </Col>
    );
  };

  onPackFilters = () => {
    const { packFilters } = this.state;
    this.setState({ packFilters: !packFilters });
  };

  renderPackButton = () => {
    const { packFilters } = this.state;

    const content = packFilters ? (
      <span>
        收起筛选条件
        <Icon type="up" />
      </span>
    ) : (
      <span>
        展开筛选条件 <Icon type="down" />
      </span>
    );

    return (
      <Button
        className="pack-filter"
        onClick={this.onPackFilters}
        type="primary"
        ghost
        style={{ marginRight: 12 }}
      >
        {content}
      </Button>
    );
  };

  formatValues = () => {
    const values = this.props.form.getFieldsValue();

    const tmp = {};
    tmp.type_like = values.type
      ? isArray(values.type)
        ? values.type.join("-")
        : values.type
      : undefined;
    tmp.title_like = values.title;
    if (values.newState) tmp.state = values.newState;
    if (values.finishedState) tmp.state = values.finishedState;
    if (values.reviewingState) tmp.state = values.reviewingState;
    tmp.district_like = values.district;
    tmp.owner_like = values.owner;
    tmp.no_like = values.id;
    tmp.publishedAt_gt = values.publishedAt
      ? ymd(values.publishedAt[0])
      : undefined;
    tmp.publishedAt_lt = values.publishedAt
      ? ymd(values.publishedAt[1])
      : undefined;
    tmp.submittedAt_gt = values.submittedAt
      ? ymd(values.submittedAt[0])
      : undefined;
    tmp.submittedAt_lt = values.submittedAt
      ? ymd(values.submittedAt[1])
      : undefined;

    this.props.handleSubmit(tmp);
  };

  render() {
    const {
      form,
      handleReset,
      formItems,
      showExport,
      handleExport,
    } = this.props;
    const { expand } = this.state;

    return (
      <Filter>
        <Form layout="vertical">
          <Row gutter={24}>
            {formItems.map((item, index) =>
              index >= 3 && !expand ? undefined : this.genFilter(form, item)
            )}
          </Row>
          <Row>
            <Col span={24} style={{ textAlign: "right" }}>
              {formItems.length > 3 && (
                <Button
                  style={{ marginLeft: 8, fontSize: 16 }}
                  onClick={this.toggle}
                  type="link"
                >
                  <Icon type={expand ? "up" : "down"} />
                  {expand ? "收起筛选条件" : "展开筛选条件"}
                </Button>
              )}
              <Button type="primary" onClick={this.formatValues}>
                筛选
              </Button>
              <Button style={{ marginLeft: 24 }} onClick={handleReset}>
                重置
              </Button>
              {showExport && (
                <Button
                  type="primary"
                  style={{ marginLeft: 24 }}
                  onClick={handleExport}
                >
                  下载
                </Button>
              )}
            </Col>
          </Row>
        </Form>
      </Filter>
    );
  }
}

const Filter = styled.div`
  padding: 6px;
  background: white;
  margin-bottom: 12px;
`;
