import React, { Fragment } from "react";
import { Route, Switch, useLocation, Redirect } from "react-router-dom";

import AddList from "./add-list";
import AddDetail from "./add-detail";
import AddNew from "./add-new";
import AddEdit from "./add-edit";

const Add = () => {
  const location = useLocation() || {};
  const background = location.state && location.state.background;

  return (
    <Fragment>
      <Switch location={background || location}>
        <Route path="/apply/add/list" component={AddList} />
        <Route path="/apply/add/detail/:id" component={AddDetail} />
        <Route path="/apply/add/edit/:id" component={AddEdit} />
        <Redirect from="/apply/add" to="/apply/add/list" />
      </Switch>
      {background && (
        <Switch>
          <Route path="/apply/add/new" component={AddNew} />
        </Switch>
      )}
    </Fragment>
  );
};

export default Add;
