/**
 * 已申报的项目列表
 */

import React, { PureComponent, Fragment } from "react";
import { Table, Divider, Button, Modal } from "antd";
import styled from "styled-components";
import { createForm } from "@36node/redux-antd";
import { NavLink, withRouter } from "react-router-dom";
import { history } from "@36node/redux-session";
import qs from "qs";
import { isEqual, isEmpty, get } from "lodash";
import { connect } from "react-redux";
import { makeApiSelector, withSaga, tapOnLatest } from "@36node/redux";
import { withSession } from "@36node/redux-session";
import { put } from "redux-saga/effects";

import { BaseColumns } from "./common";
import { FinishedLiveDataStates, LivedataStates } from "../../../../constants";
import FilterForm from "../../../../components/filter-form";
import { getSearch } from "../../../../lib/history";
import { getCertificateImg } from "../../../../lib";
import { core } from "../../../../actions/api";
import CertificateFiles from "../../../../components/certificate-files";
import { CORE_LIVE_LIST_LIVE } from "../../../../actions/types";

const tabs = ["type", "finishedState", "title", "id", "owner"];

const listLivedata = core.livedata.makeListLivedata("projects.finished", {
  query: {
    filter: {
      state: FinishedLiveDataStates,
    },
  },
});

const listExtra = core.extra.makeListExtra("projects.extras", {
  query: {
    select: ["livedata", "state"],
  },
});

const listLiveDataSelector = makeApiSelector("projects.finished");

const listExtraSelector = makeApiSelector("projects.extras");

const updateUrl = query => {
  history.push(`/apply/project/list/finished?${qs.stringify(query)}`);
};

@withRouter
@createForm("projects.finished")
class SearchForm extends PureComponent {
  handleReset = () => {
    this.props.form.resetFields();
  };

  handleSubmit = values => {
    updateUrl(values);
  };

  render() {
    const { form, location } = this.props;
    return (
      <FilterForm
        formItemLayout={{
          labelCol: { span: 6 },
          wrapperCol: { span: 18 },
        }}
        layout="horizontal"
        form={form}
        formItems={tabs}
        handleReset={this.handleReset}
        handleSubmit={this.handleSubmit}
        query={getSearch(location)}
      />
    );
  }
}

@withRouter
@withSession("session")
@connect((state, props) => {
  const listState = listLiveDataSelector(state);
  const listExtraState = listExtraSelector(state);

  return {
    dataSource: listState.result || [],
    total: listState.total || 0,
    loading: listState.loading || listExtraState.loading,
    extras: listExtraState.result || [],
    user: get(props.session, "result.user") || {},
  };
})
class ProjectsTable extends PureComponent {
  state = {
    showImg: false,
    selected: {},
  };

  getQueryFromLocation = () => {
    let query = getSearch(this.props.location);
    const { offset, limit } = query;
    query.offset = offset || 0;
    query.limit = limit || 10;
    return query;
  };

  componentDidMount() {
    if (isEmpty(this.props.location.search)) {
      updateUrl(this.getQueryFromLocation());
    }
    this.fetchList();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(this.props.location, prevProps.location)) {
      this.fetchList();
    }
  }

  fetchList = (query = {}) => {
    this.props.dispatch(
      listLivedata({
        query: {
          ...this.getQueryFromLocation(),
          ...query,
          createdBy: this.props.user.id,
        },
      })
    );
  };

  onTableChange = pagination => {
    const limit = pagination.pageSize;
    const offset = (pagination.current - 1) * limit;

    updateUrl({
      ...this.getQueryFromLocation(),
      limit,
      offset,
    });
  };

  showCertificate = record => {
    this.setState({
      showImg: true,
      selected: record,
    });
  };

  onImgCancel = () => {
    this.setState({ showImg: false });
  };

  render() {
    const { total, extras, ...rest } = this.props;
    const { showImg, selected } = this.state;

    const urlQuery = this.getQueryFromLocation();

    const limit = Number(urlQuery.limit);
    const offset = Number(urlQuery.offset);

    const finalColumns = [
      ...BaseColumns,
      {
        title: "操作",
        key: "operate",
        render: record => {
          // 当前项目中不存在持续添加申请，或有PUBLUSHED或REJECTED 的已添加申请
          const canAddExtra =
            extras.filter(
              e =>
                e.livedata === record.id &&
                ![LivedataStates.PUBLISHED, LivedataStates.REJECTED].includes(
                  e.state
                )
            ).length === 0;

          switch (record.state) {
            case LivedataStates.PUBLISHED:
              return (
                <span>
                  <NavLink
                    to={`/apply/project/detail/${record.id}`}
                    style={{ marginRight: 6 }}
                  >
                    查看详情
                  </NavLink>
                  {canAddExtra && (
                    <NavLink
                      to={{
                        pathname: "/apply/add/new",
                        state: {
                          background: "/apply/add/list",
                          livedataId: record.id,
                        },
                      }}
                      style={{ marginRight: 6 }}
                    >
                      持续添加
                    </NavLink>
                  )}
                  {record.certificate && (
                    <Button
                      type="link"
                      onClick={() => this.showCertificate(record)}
                      style={{ padding: 0 }}
                    >
                      查看证书
                    </Button>
                  )}
                </span>
              );
            case LivedataStates.REVIEW_APPROVED:
            case LivedataStates.NATIONAL_EXPERT_AUDITED:
            case LivedataStates.APPROVED:
              return (
                <span>
                  <NavLink
                    to={`/apply/project/detail/${record.id}`}
                    style={{ marginRight: 6 }}
                  >
                    查看详情
                  </NavLink>
                  {canAddExtra && (
                    <NavLink
                      to={{
                        pathname: "/apply/add/new",
                        state: {
                          background: "/apply/add/list",
                          livedataId: record.id,
                        },
                      }}
                      style={{ marginRight: 6 }}
                    >
                      持续添加
                    </NavLink>
                  )}
                </span>
              );
            default:
              return (
                <NavLink to={`/apply/project/detail/${record.id}`}>
                  查看详情
                </NavLink>
              );
          }
        },
      },
    ];

    return (
      <Fragment>
        <div style={{ width: "100%" }}>
          <div style={{ paddingTop: 12 }}>
            <span style={{ fontSize: "18px", fontWeight: 600 }}>
              审核完成项目列表
            </span>
          </div>
          <Divider style={{ margin: "12px 0" }} />
          <SearchForm />
        </div>
        <StyledTable
          rowKey="id"
          {...rest}
          columns={finalColumns}
          onChange={this.onTableChange}
          pagination={{
            total,
            pageSize: limit,
            current: offset / limit + 1,
            showQuickJumper: true,
            showSizeChanger: true,
            showTotal: (total, range) =>
              `共${total}条记录 第${range[0]}-${range[1]}条`,
          }}
        />
        <StyledImgModal
          title="证书预览"
          visible={showImg}
          onCancel={this.onImgCancel}
          footer={null}
          width={600}
        >
          <p>证书编号: {selected.certificate}</p>
          <CertificateFiles files={getCertificateImg(selected)} />
        </StyledImgModal>
      </Fragment>
    );
  }
}

@withSaga(
  tapOnLatest(CORE_LIVE_LIST_LIVE.SUCCESS, "projects.finished", function*(
    action
  ) {
    const { result = [] } = action.payload;

    yield put(
      listExtra({
        query: {
          livedata: result,
        },
      })
    );
  })
)
export default class Finished extends PureComponent {
  render() {
    return (
      <Fragment>
        <Main>
          <ProjectsTable />
        </Main>
      </Fragment>
    );
  }
}

const StyledTable = styled(Table)`
  .ant-pagination-total-text {
    position: absolute;
    left: 0;
  }

  .ant-table-column-title {
    font-weight: 600;
  }
`;

const Main = styled.div`
  padding: 24px;
`;

const StyledImgModal = styled(Modal)`
  .modal-img {
    text-align: center;

    img {
      width: 100%;
      padding: 12px;
    }
  }
`;
