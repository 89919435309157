import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { Button, Spin, Card } from "antd";
import { get, isEmpty, omit } from "lodash";
import { makeApiSelector } from "@36node/redux";
import { withSession } from "@36node/redux-session";

import { core } from "../../../actions/api";
import { getUserId, getUserRole, ymd } from "../../../lib";
import Container from "../../../components/layout/container";
import Detail from "../../../components/detail";
import LivedataHistory from "../../../components/history";
import {
  LivedataStates,
  DetailfilterKeys,
  UploadMenu,
} from "../../../constants";

export const showLive = core.livedata.makeShowLivedata("library.detail");
const showLiveSelector = makeApiSelector("library.detail");
const listUsersSelector = makeApiSelector("users");

const listExtra = core.extra.makeListExtra("library.add.detail");
const listExtraSelector = makeApiSelector("library.add.detail");

@withRouter
@withSession("session")
@connect(state => ({
  data: showLiveSelector(state).result || {},
  extra: listExtraSelector(state).result || [],
  loading: showLiveSelector(state).loading || false,
  users: listUsersSelector(state).result || [],
}))
export default class extends PureComponent {
  get id() {
    const { match } = this.props;
    return get(match, "params.id");
  }

  get role() {
    return getUserRole(this.props);
  }

  get userId() {
    return getUserId(this.props);
  }

  componentDidMount() {
    this.props.dispatch(showLive({ livedataId: this.id }));
    this.props.dispatch(
      listExtra({
        query: { livedata: this.id, state: LivedataStates.PUBLISHED },
      })
    );
  }

  handleBack = () => {
    const { history } = this.props;
    history.go(-1);
  };

  onPrint = () => {
    window.print();
  };

  getMenus = data => {
    const filterData = omit(data, DetailfilterKeys);

    let result = [];
    Object.keys(filterData).forEach(v => {
      let label = "";
      UploadMenu.forEach(item => {
        const t = item.items.find(x => x.value === v);
        if (t) {
          label = t.label;
        }
      });
      if (!isEmpty(filterData[v])) result.push({ label, value: v });
    });

    if (!isEmpty(result))
      return [
        {
          key: "extra",
          title: "持续添加",
          items: result,
        },
      ];
    return [];
  };

  render() {
    const { data, loading, users, extra } = this.props;

    return (
      <StyledContainer>
        <Spin spinning={loading}>
          <div className="title">
            <Button
              icon="left"
              type="link"
              size="large"
              onClick={this.handleBack}
              className="back-button"
            >
              返回
            </Button>
            {data.title}
            <Button className="print-button" onClick={this.onPrint}>
              打印页面
            </Button>
          </div>
          <Detail data={data} />
          {extra.length > 0 &&
            extra.map(item => {
              return (
                <Card
                  title={ymd(item.publishedAt)}
                  style={{ margin: "24px 0" }}
                >
                  <Detail data={item} menus={this.getMenus(item)} />
                </Card>
              );
            })}
          {data.events && data.events.length > 0 && (
            <LivedataHistory data={data} users={users} />
          )}
        </Spin>

        <img src="/images/logo1.png" className="print-bg" alt="" />
      </StyledContainer>
    );
  }
}

const StyledContainer = styled(Container)`
  .title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 24px;
    text-align: center;
    color: rgba(0, 0, 0, 0.85);
    position: relative;

    .back-button {
      position: absolute;
      top: 0;
      left: 0;
      padding: 0;
    }

    .print-button {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .print-bg {
    position: fixed;
    right: 20px;
    top: 20px;
    display: none;
  }

  @media print {
    * {
      -webkit-print-color-adjust: exact;
      -moz-print-color-adjust: exact;
      -ms-print-color-adjust: exact;
      print-color-adjust: exact;
    }

    .print-button,
    .back-button {
      display: none;
    }

    .print-bg {
      display: block;
    }
  }
`;
