import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter, NavLink } from "react-router-dom";
import styled from "styled-components";
import { Button, Spin } from "antd";
import { get, isEmpty, omit } from "lodash";
import { makeApiSelector } from "@36node/redux";
import { withSession } from "@36node/redux-session";

import { core } from "../../../actions/api";
import Container from "../../../components/layout/container";
import Detail from "../../../components/detail";
import LivedataHistory from "../../../components/history";
import { getUserRole, getUserId } from "../../../lib";
import { UploadMenu, DetailfilterKeys } from "../../../constants";

const showExtra = core.extra.makeShowExtra("apply.add.detail");
const showExtraSelector = makeApiSelector("apply.add.detail");
const listUsersSelector = makeApiSelector("users");

@withRouter
@withSession("session")
@connect(state => ({
  data: showExtraSelector(state).result || {},
  loading: showExtraSelector(state).loading || false,
  users: listUsersSelector(state).result || [],
}))
export default class extends PureComponent {
  get id() {
    const { match } = this.props;
    return get(match, "params.id");
  }

  get role() {
    return getUserRole(this.props);
  }

  get userId() {
    return getUserId(this.props);
  }

  componentDidMount() {
    this.props.dispatch(showExtra({ extraId: this.id }));
  }

  handleBack = () => {
    const { history } = this.props;
    history.go(-1);
  };

  getMenus = () => {
    const { data = {} } = this.props;
    const filterData = omit(data, DetailfilterKeys);

    let result = [];
    Object.keys(filterData).forEach(v => {
      let label = "";
      UploadMenu.forEach(item => {
        const t = item.items.find(x => x.value === v);
        if (t) {
          label = t.label;
        }
      });
      if (!isEmpty(filterData[v])) result.push({ label, value: v });
    });

    if (!isEmpty(result))
      return [
        {
          key: "extra",
          title: "持续添加",
          items: result,
        },
      ];
    return [];
  };

  render() {
    const { data, loading, users } = this.props;
    const menus = this.getMenus();

    return (
      <StyledContainer>
        <Spin spinning={loading}>
          <div className="title">
            <Button
              icon="left"
              type="link"
              size="large"
              onClick={this.handleBack}
              className="back-button"
            >
              返回
            </Button>
            {data.title}
            <NavLink to={`/apply/project/detail/${data.livedata}`}>
              <Button className="p-link" type="primary">
                查看原项目
              </Button>
            </NavLink>
          </div>
          <Detail data={data} menus={menus} />
          {data.events && data.events.length > 0 && (
            <LivedataHistory data={data} users={users} />
          )}
        </Spin>
      </StyledContainer>
    );
  }
}

const StyledContainer = styled(Container)`
  .title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 24px;
    text-align: center;
    color: rgba(0, 0, 0, 0.85);
    position: relative;
    min-height: 30px;

    .back-button {
      position: absolute;
      top: 0;
      left: 0;
      padding: 0;
    }

    .p-link {
      float: right;
    }
  }
`;
