import { SYS_TYPE } from "./config";

/**
 * 常量
 */
export const SESSION_ID = "session_id";
export const TOKEN = "token";
export const LOGIN_URL = "/login";

/**
 * 用户角色
 */
export const ROLES = {
  USER: "USER", // 申报人
  PROVINCE_ADMIN: "PROVINCE_ADMIN", // 省级业务管理员
  PROVINCE_VIEW: "PROVINCE_VIEW", // 省级行政管理员
  ROOT_ADMIN: "ROOT_ADMIN", // 终级管理员
  SECURITY_ADMIN: "SECURITY_ADMIN", // 安全管理员
  AUDIT_ADMIN: "AUDIT_ADMIN", //审计管理员
};

export const ROLESNAME = {
  USER: "申报人",
  PROVINCE_ADMIN: "省级业务管理员",
  PROVINCE_VIEW: "省级行政管理员",
  ROOT_ADMIN: "终级管理员",
  SECURITY_ADMIN: "安全管理员",
  AUDIT_ADMIN: "审计管理员",
};

/**
 * 导航菜单
 */
export const MENU = [
  {
    role: ROLES.USER,
    data: [
      {
        path: "/apply/project/list",
        title: "项目申报",
        icon: "appstore",
        theme: "filled",
        key: "project",
      },
      {
        path: "/apply/add/list",
        title: "持续添加",
        icon: "edit",
        theme: "filled",
        key: "add",
      },
      {
        path: "/certificate/user",
        title: "证书查询",
        icon: "book",
        theme: "filled",
        key: "certificate",
      },
    ],
  },
  {
    role: ROLES.PROVINCE_ADMIN,
    data: [
      {
        path: "/review/project/list",
        title: "项目申报审核",
        icon: "appstore",
        theme: "filled",
        key: "project",
        defaultSearch: { state: "INIT", limit: 10, offset: 0 },
      },
      {
        path: "/review/add/list",
        title: "持续添加审核",
        icon: "edit",
        theme: "filled",
        defaultSearch: { state: "INIT", limit: 10, offset: 0 },
        key: "add",
      },
      {
        path: "/users",
        title: "管理申报人",
        icon: "user",
        theme: "outlined",
        key: "user",
      },
    ],
  },
  {
    role: ROLES.PROVINCE_VIEW,
    data: [
      {
        path: "/review/project/list",
        title: "项目申报审核",
        icon: "appstore",
        theme: "filled",
        key: "project",
        defaultSearch: { state: "INIT", limit: 10, offset: 0 },
      },
      {
        path: "/review/add/list",
        title: "持续添加审核",
        icon: "edit",
        theme: "filled",
        defaultSearch: { state: "INIT", limit: 10, offset: 0 },
        key: "add",
      },
    ],
  },
  {
    role: ROLES.ROOT_ADMIN,
    data: [
      {
        path: "/review/home",
        title: "审核中心",
        icon: "home",
        theme: "filled",
        key: "review",
      },
      {
        path: "/library/list",
        title: "名录库",
        icon: "appstore",
        theme: "filled",
        key: "library",
      },
      {
        path: "/certificate",
        title: "证书查询",
        icon: "book",
        theme: "filled",
        key: "certificate",
      },
      {
        path: "/users",
        title: "用户管理",
        icon: "user",
        theme: "outlined",
        key: "user",
      },
      {
        path: "/feedback",
        title: "问题反馈",
        icon: "question-circle",
        theme: "outlined",
        key: "feedback",
      },
    ],
  },
  {
    role: ROLES.SECURITY_ADMIN,
    data: [
      {
        path: "/users",
        title: "用户管理",
        icon: "user",
        theme: "outlined",
        key: "user",
      },
    ],
  },
  {
    role: ROLES.AUDIT_ADMIN,
    data: [
      {
        path: "/users",
        title: "用户管理",
        icon: "user",
        theme: "outlined",
        key: "user",
      },
    ],
  },
];

/**
 * 上传导航
 */
export const UploadMenu = [
  {
    key: "info",
    title: "项目信息",
    step: 0,
    items: [
      {
        label: "项目名称",
        value: "title",
      },
      {
        label: "项目类型",
        value: "type",
      },
      {
        label: SYS_TYPE === "LIVEDATA" ? "所属地区" : "申报地区",
        value: "district",
      },
      {
        label: "项目编码",
        value: "no",
      },
      {
        label: "方名",
        value: "name",
        system: "LIVEDATA", // 只在 "LIVEDATA" 下展示
      },
      {
        label: "适应范围",
        value: "indication",
      },
      {
        label: "使用禁忌",
        value: "contraindication",
      },
      {
        label: "应用规范",
        value: "applyRule",
      },
      {
        label: "应用规范相关证据",
        value: "applyRuleAttach",
      },
      {
        label: "应用地区",
        value: "area",
        system: "LIVEDATA", // 只在 "LIVEDATA" 下展示
      },
      {
        label: "应用时间",
        value: "applyAt",
        system: "LIVEDATA", // 只在 "LIVEDATA" 下展示
      },
      {
        label: "应用相关证据",
        value: "applyAttach",
        system: "LIVEDATA", // 只在 "LIVEDATA" 下展示
      },
      {
        label: "传承时间",
        value: "inheritTime",
      },
      {
        label: "传承代数",
        value: "inheritGeneration",
      },
      {
        label: "传承谱系",
        value: "inheritSeries",
      },
      {
        label: "传承谱系相关证据",
        value: "inheritAttach",
      },
      {
        label: "内容摘要",
        value: "desc",
      },
      {
        label: "项目实质内容",
        value: "pureContent",
      },
      {
        label: "相关研究证明资料，如古籍、论文、专著等",
        value: "certiAttach",
        system: "LIVEDATA", // 只在 "LIVEDATA" 下展示
      },
      {
        label: "主要特征及重要价值",
        value: "feature",
      },
      {
        label: "病例附件",
        value: "caseAttach",
        system: "LIVEDATA", // 只在 "LIVEDATA" 下展示
      },
      {
        label: "其他附件",
        value: "otherAttach",
      },
      {
        label: "备注",
        value: "consideration",
      },
    ],
  },
  {
    key: "user",
    title: "申报人信息",
    step: 1,
    items: [
      {
        label: "姓名",
        value: "owner",
      },
      {
        label: "出生日期",
        value: "birthday",
      },
      {
        label: "性别",
        value: "sex",
      },
      {
        label: "民族",
        value: "nation",
      },
      {
        label: "手机号码",
        value: "phone",
      },
      {
        label: "固话号码",
        value: "landline",
      },
      {
        label: "邮箱",
        value: "email",
      },
      {
        label: "邮编",
        value: "postcode",
      },
      {
        label: "籍贯",
        value: "birthplace",
      },
      {
        label: "通讯地址",
        value: "address",
      },
      {
        label: "身份证号",
        value: "idNumber",
      },
      {
        label: "身份证照片",
        value: "idImage",
      },
      {
        label: "手持身份证照片",
        value: "idPersonImage",
      },
      {
        label: "从医资质",
        value: "doctorYear",
      },
      {
        label: "职业证书",
        value: "doctorAttach",
      },
      {
        label: "职称",
        value: "job",
      },
      {
        label: "职称证书",
        value: "jobAttach",
      },
      {
        label: "职务",
        value: "position",
      },
      {
        label: "职务证书",
        value: "positionAttach",
      },
      {
        label: "最高学历",
        value: "degree",
      },
      {
        label: "学历证书",
        value: "degreeAttach",
      },
      {
        label: "曾获奖励",
        value: "award",
        system: "LIVEDATA", // 只在 "LIVEDATA" 下展示
      },
      {
        label: "奖励附件",
        value: "awardAttach",
        system: "LIVEDATA", // 只在 "LIVEDATA" 下展示
      },
      {
        label: "批准文号",
        value: "medicine",
        system: "LIVEDATA", // 只在 "LIVEDATA" 下展示
      },
      {
        label: "批准文号附件",
        value: "medicineAttach",
        system: "LIVEDATA", // 只在 "LIVEDATA" 下展示
      },
      {
        label: "商标",
        value: "brand",
        system: "LIVEDATA", // 只在 "LIVEDATA" 下展示
      },
      {
        label: "商标附件",
        value: "brandAttach",
        system: "LIVEDATA", // 只在 "LIVEDATA" 下展示
      },
      {
        label: "专利",
        value: "patent",
        system: "LIVEDATA", // 只在 "LIVEDATA" 下展示
      },
      {
        label: "专利附件",
        value: "patentAttach",
        system: "LIVEDATA", // 只在 "LIVEDATA" 下展示
      },
      {
        label: "著作权",
        value: "book",
        system: "LIVEDATA", // 只在 "LIVEDATA" 下展示
      },
      {
        label: "著作权附件",
        value: "bookAttach",
        system: "LIVEDATA", // 只在 "LIVEDATA" 下展示
      },
      {
        label: "论文",
        value: "paper",
        system: "LIVEDATA", // 只在 "LIVEDATA" 下展示
      },
      {
        label: "论文附件",
        value: "paperAttach",
        system: "LIVEDATA", // 只在 "LIVEDATA" 下展示
      },
      {
        label: "相关产品",
        value: "product",
        system: "LIVEDATA", // 只在 "LIVEDATA" 下展示
      },
      {
        label: "产品附件",
        value: "productAttach",
        system: "LIVEDATA", // 只在 "LIVEDATA" 下展示
      },
      {
        label: "科研项目",
        value: "project",
        system: "LIVEDATA", // 只在 "LIVEDATA" 下展示
      },
      {
        label: "项目附件",
        value: "projectAttach",
        system: "LIVEDATA", // 只在 "LIVEDATA" 下展示
      },
      {
        label: "相关机构情况",
        value: "company",
        system: "LIVEDATA", // 只在 "LIVEDATA" 下展示
      },
      {
        label: "相关机构情况附件",
        value: "companyAttach",
        system: "LIVEDATA", // 只在 "LIVEDATA" 下展示
      },
      {
        label: "非遗",
        value: "heritage",
        system: "LIVEDATA", // 只在 "LIVEDATA" 下展示
      },
      {
        label: "非遗附件",
        value: "heritageAttach",
        system: "LIVEDATA", // 只在 "LIVEDATA" 下展示
      },
      {
        label: "家庭住址",
        value: "homeaddress",
      },
      {
        label: "产权证明",
        value: "homeaddressAttach",
      },
      {
        label: "公司地址",
        value: "companyaddress",
      },
      {
        label: "产权证明",
        value: "companyaddressAttach",
      },
      {
        label: "其他相关证据资料",
        value: "otherUserAttach",
      },
      {
        label: "备注",
        value: "ownerRemark",
      },
    ],
  },
  {
    key: "couser",
    title: "共同申报人",
    items: [
      {
        label: "姓名",
        value: "owner",
      },
      {
        label: "出生日期",
        value: "birthday",
      },
      {
        label: "性别",
        value: "sex",
      },
      {
        label: "民族",
        value: "nation",
      },
      {
        label: "手机号码",
        value: "phone",
      },
      {
        label: "固话号码",
        value: "landline",
      },
      {
        label: "邮箱",
        value: "email",
      },
      {
        label: "邮编",
        value: "postcode",
      },
      {
        label: "籍贯",
        value: "birthplace",
      },
      {
        label: "通讯地址",
        value: "address",
      },
      {
        label: "身份证号",
        value: "idNumber",
      },
      {
        label: "身份证照片",
        value: "idImage",
      },
      {
        label: "手持身份证照片",
        value: "idPersonImage",
      },
      {
        label: SYS_TYPE === "LIVEDATA" ? "从医资质" : "执业资格",
        value: "doctorYear",
      },
      {
        label: "职业证书",
        value: "doctorAttach",
      },
      {
        label: "职称",
        value: "job",
      },
      {
        label: "职称证书",
        value: "jobAttach",
      },
      {
        label: "职务",
        value: "position",
      },
      {
        label: "职务证书",
        value: "positionAttach",
      },
      {
        label: "最高学历",
        value: "degree",
      },
      {
        label: "学历证书",
        value: "degreeAttach",
      },
      {
        label: "曾获奖励",
        value: "award",
        system: "LIVEDATA", // 只在 "LIVEDATA" 下展示
      },
      {
        label: "奖励附件",
        value: "awardAttach",
        system: "LIVEDATA", // 只在 "LIVEDATA" 下展示
      },
      {
        label: "批准文号",
        value: "medicine",
        system: "LIVEDATA", // 只在 "LIVEDATA" 下展示
      },
      {
        label: "批准文号附件",
        value: "medicineAttach",
        system: "LIVEDATA", // 只在 "LIVEDATA" 下展示
      },
      {
        label: "商标",
        value: "brand",
        system: "LIVEDATA", // 只在 "LIVEDATA" 下展示
      },
      {
        label: "商标附件",
        value: "brandAttach",
        system: "LIVEDATA", // 只在 "LIVEDATA" 下展示
      },
      {
        label: "专利",
        value: "patent",
        system: "LIVEDATA", // 只在 "LIVEDATA" 下展示
      },
      {
        label: "专利附件",
        value: "patentAttach",
        system: "LIVEDATA", // 只在 "LIVEDATA" 下展示
      },
      {
        label: "著作权",
        value: "book",
        system: "LIVEDATA", // 只在 "LIVEDATA" 下展示
      },
      {
        label: "著作权附件",
        value: "bookAttach",
        system: "LIVEDATA", // 只在 "LIVEDATA" 下展示
      },
      {
        label: "论文",
        value: "paper",
        system: "LIVEDATA", // 只在 "LIVEDATA" 下展示
      },
      {
        label: "论文附件",
        value: "paperAttach",
        system: "LIVEDATA", // 只在 "LIVEDATA" 下展示
      },
      {
        label: "相关产品",
        value: "product",
        system: "LIVEDATA", // 只在 "LIVEDATA" 下展示
      },
      {
        label: "产品附件",
        value: "productAttach",
        system: "LIVEDATA", // 只在 "LIVEDATA" 下展示
      },
      {
        label: "科研项目",
        value: "project",
        system: "LIVEDATA", // 只在 "LIVEDATA" 下展示
      },
      {
        label: "项目附件",
        value: "projectAttach",
        system: "LIVEDATA", // 只在 "LIVEDATA" 下展示
      },
      {
        label: "相关机构情况",
        value: "company",
        system: "LIVEDATA", // 只在 "LIVEDATA" 下展示
      },
      {
        label: "相关机构情况附件",
        value: "companyAttach",
        system: "LIVEDATA", // 只在 "LIVEDATA" 下展示
      },
      {
        label: "非遗",
        value: "heritage",
        system: "LIVEDATA", // 只在 "LIVEDATA" 下展示
      },
      {
        label: "非遗附件",
        value: "heritageAttach",
        system: "LIVEDATA", // 只在 "LIVEDATA" 下展示
      },
      {
        label: "家庭住址",
        value: "homeaddress",
      },
      {
        label: "产权证明",
        value: "homeaddressAttach",
      },
      {
        label: "公司地址",
        value: "companyaddress",
      },
      {
        label: "产权证明",
        value: "companyaddressAttach",
      },
      {
        label: SYS_TYPE === "LIVEDATA" ? "其他相关证据资料" : "其他附件",
        value: "otherUserAttach",
      },
      {
        label: "备注",
        value: "ownerRemark",
      },
    ],
  },
  {
    key: "evaluation",
    title: "项目评价信息",
    step: SYS_TYPE === "TREATMENT" ? 2 : undefined,
    items: [
      {
        label: "是否侵入皮肤",
        value: "isSkinIrritation",
        group: "safety",
      },
      {
        label: "上传证据文件",
        value: "skinIrritationAttach",
        group: "safety",
      },
      {
        label: "是否含有刺激性、腐蚀性药物",
        value: "hasCorrosiveDrug",
        group: "safety",
      },
      {
        label: "上传证据文件",
        value: "hasCorrosiveDrugAttach",
        group: "safety",
      },
      {
        label: "常见的不良反应及应对措施",
        value: "adverseReactions",
        group: "safety",
      },
      {
        label: "上传证据文件",
        value: "adverseReactionsAttach",
        group: "safety",
      },
      {
        label: "不良反应是否可逆",
        value: "isAdverseReactionsRecoverable",
        group: "safety",
      },
      {
        label: "上传证据文件",
        value: "isAdverseReactionsRecoverableAttach",
        group: "safety",
      },
      {
        label: "禁忌症说明",
        value: "contraindications",
        group: "safety",
      },
      {
        label: "上传证据文件",
        value: "contraindicationsAttach",
        group: "safety",
      },
      {
        label: "其他安全性说明",
        value: "otherSafeInstruction",
        group: "safety",
      },
      {
        label: "上传证据文件",
        value: "otherSafeInstructionAttach",
        group: "safety",
      },
      {
        label: "相关病例",
        value: "relatedCases",
        group: "effectiveness",
      },
      {
        label: "上传证据文件",
        value: "relatedCasesAttach",
        group: "effectiveness",
      },
      {
        label: "患者反馈情况",
        value: "feedback",
        group: "effectiveness",
      },
      {
        label: "上传证据文件",
        value: "feedbackAttach",
        group: "effectiveness",
      },
      {
        label: "临床研究证据",
        value: "researchEvidence",
        group: "effectiveness",
      },
      {
        label: "上传证据文件",
        value: "researchEvidenceAttach",
        group: "effectiveness",
      },
      {
        label: "患者复诊情况",
        value: "patientRecheck",
        group: "effectiveness",
      },
      {
        label: "上传证据文件",
        value: "patientRecheckAttach",
        group: "effectiveness",
      },
      {
        label: "其他有效性说明",
        value: "otherEffectiveness",
        group: "effectiveness",
      },
      {
        label: "上传证据文件",
        value: "otherEffectivenessAttach",
        group: "effectiveness",
      },
      {
        label: "针对该技术开展的继续教育情况",
        value: "education",
        group: "accessibility",
      },
      {
        label: "上传证据文件",
        value: "educationAttach",
        group: "accessibility",
      },
      {
        label: "针对该技术开展的培训、授徒活动",
        value: "training",
        group: "accessibility",
      },
      {
        label: "上传证据文件",
        value: "trainingAttach",
        group: "accessibility",
      },
      {
        label: "一次完整技术培训所需时间",
        value: "trainingTime",
        group: "accessibility",
      },
      {
        label: "上传证据文件",
        value: "trainingTimeAttach",
        group: "accessibility",
      },
      {
        label: "开展该诊疗技术的经济成本",
        value: "treatmentCost",
        group: "accessibility",
      },
      {
        label: "上传证据文件",
        value: "treatmentCostAttach",
        group: "accessibility",
      },
      {
        label: "开展该诊疗技术的时间成本",
        value: "treatmentTimeCost",
        group: "accessibility",
      },
      {
        label: "上传证据文件",
        value: "treatmentTimeCostAttach",
        group: "accessibility",
      },
      {
        label: "其他说明",
        value: "otherAccessibility",
        group: "accessibility",
      },
      {
        label: "上传证据文件",
        value: "otherAccessibilityAttach",
        group: "accessibility",
      },
      {
        label: "应用时间",
        value: "implementationTime",
        group: "ripeness",
      },
      {
        label: "上传证据文件",
        value: "implementationTimeAttach",
        group: "ripeness",
      },
      {
        label: "应用地区",
        value: "implementationArea",
        group: "ripeness",
      },
      {
        label: "上传证据文件",
        value: "implementationAreaAttach",
        group: "ripeness",
      },
      {
        label: "是否有技术的操作规范",
        value: "hasOperatingSpecifications",
        group: "ripeness",
      },
      {
        label: "上传证据文件",
        value: "hasOperatingSpecificationsAttach",
        group: "ripeness",
      },
      {
        label: "与该技术相关的研究资料",
        value: "researchMaterials",
        group: "ripeness",
      },
      {
        label: "上传证据文件",
        value: "researchMaterialsAttach",
        group: "ripeness",
      },
      {
        label: "主持、参与相关课题情况",
        value: "researchTopics",
        group: "ripeness",
      },
      {
        label: "上传证据文件",
        value: "researchTopicsAttach",
        group: "ripeness",
      },
      {
        label: "与该技术有关的获奖情况",
        value: "relatedRewards",
        group: "ripeness",
      },
      {
        label: "上传证据文件",
        value: "relatedRewardsAttach",
        group: "ripeness",
      },
      {
        label: "诊疗场所规模",
        value: "clinicsScale",
        group: "ripeness",
      },
      {
        label: "上传证据文件",
        value: "clinicsScaleAttach",
        group: "ripeness",
      },
      {
        label: "与该技术有关的产品情况",
        value: "releatedProducts",
        group: "ripeness",
      },
      {
        label: "上传证据文件",
        value: "releatedProductsAttach",
        group: "ripeness",
      },
      {
        label: "其他说明",
        value: "otherMaturity",
        group: "ripeness",
      },
      {
        label: "上传证据文件",
        value: "otherMaturityAttach",
        group: "ripeness",
      },
      {
        label: "技术创新点",
        value: "technologicalInnovation",
        group: "originality",
      },
      {
        label: "上传证据文件",
        value: "technologicalInnovationAttach",
        group: "originality",
      },
      {
        label: "效果优势",
        value: "effectAdvantage",
        group: "originality",
      },
      {
        label: "上传证据文件",
        value: "effectAdvantageAttach",
        group: "originality",
      },
      {
        label: "安全性优势",
        value: "securityAdvantage",
        group: "originality",
      },
      {
        label: "上传证据文件",
        value: "securityAdvantageAttach",
        group: "originality",
      },
      {
        label: "经济性",
        value: "economyAdvantage",
        group: "originality",
      },
      {
        label: "上传证据文件",
        value: "economyAdvantageAttach",
        group: "originality",
      },
      {
        label: "其他说明",
        value: "otherAdvantage",
        group: "originality",
      },
      {
        label: "上传证据文件",
        value: "otherAdvantageAttach",
        group: "originality",
      },
    ],
  },
  {
    key: "host",
    title: "申报人确认",
    step: SYS_TYPE === "LIVEDATA" ? 2 : 3,
    items: [
      {
        label: "申报人",
        value: "hostUser",
      },
      {
        label: "确认时间",
        value: "hostTime",
      },
      {
        label: "申请人承诺书",
        value: "hostAttach",
      },
      {
        label: "知情同意书",
        value: "informedAttach",
      },
    ],
  },
  // {
  //   key: "company",
  //   title: "调查单位确认",
  //   step: 4,
  //   items: [
  //     {
  //       label: "调查负责人姓名",
  //       value: "companyUser",
  //     },
  //     {
  //       label: "联系电话",
  //       value: "companyUserPhone",
  //     },
  //     {
  //       label: "调查单位名称",
  //       value: "companyName",
  //     },
  //     {
  //       label: "联系电话",
  //       value: "companyPhone",
  //     },
  //     {
  //       label: "调查时间",
  //       value: "companyDate",
  //     },
  //   ],
  // },
  // {
  //   key: "honesty",
  //   title: "评审情况与状态",
  //   step: 4,
  //   items: [
  //     {
  //       label: "省级专家组意见",
  //       value: "pexpertComment",
  //     },
  //     {
  //       label: "意见附件",
  //       value: "pexpertCommentAttach",
  //     },
  //     {
  //       label: "专家诚信承诺书",
  //       value: "pexpertCommentHonestyAttach",
  //     },
  //     {
  //       label: "国家级专家组意见",
  //       value: "nexpertComment",
  //     },
  //     {
  //       label: "意见附件",
  //       value: "nexpertCommentAttach",
  //     },
  //     {
  //       label: "专家诚信承诺书",
  //       value: "nexpertCommentHonestyAttach",
  //     },
  //   ],
  // },
];

/**
 * 类型
 */
export const LiveDataTypes = [
  {
    label: "制剂方法类",
    value: "a",
    children: [
      {
        label: "验方",
        value: "a1",
        children: [{ label: "验方", value: "a11" }],
      },
      {
        label: "单方",
        value: "a2",
        children: [{ label: "单方", value: "a21" }],
      },
      {
        label: "秘方",
        value: "a3",
        children: [{ label: "秘方", value: "a31" }],
      },
      {
        label: "院内制剂",
        value: "a4",
        children: [{ label: "院内制剂", value: "a41" }],
      },
    ],
  },
  {
    label: "诊断类",
    value: "b",
    children: [
      {
        label: "望诊",
        value: "b1",
        children: [
          {
            label: "舌诊",
            value: "b11",
          },
          {
            label: "手诊",
            value: "b12",
          },
          {
            label: "全息诊法",
            value: "b13",
          },
        ],
      },
      {
        label: "闻诊",
        value: "b2",
        children: [
          {
            label: "闻诊",
            value: "b21",
          },
        ],
      },
      {
        label: "问诊",
        value: "b3",
        children: [
          {
            label: "问诊",
            value: "b31",
          },
        ],
      },
      {
        label: "切诊",
        value: "b4",
        children: [
          {
            label: "切诊",
            value: "b41",
          },
        ],
      },
      {
        label: "罐诊",
        value: "b5",
        children: [
          {
            label: "罐诊",
            value: "b51",
          },
        ],
      },
    ],
  },
  {
    label: "治疗、调理类",
    value: "c",
    children: [
      {
        label: "内服药",
        value: "c1",
        children: [
          { value: "c11", label: "丸剂" },
          { value: "c12", label: "散剂" },
          { value: "c13", label: "丹剂" },
          { value: "c14", label: "汤剂" },
          { value: "c15", label: "其它" },
        ],
      },
      {
        label: "外用药",
        value: "c2",
        children: [
          {
            label: "膏剂",
            value: "c21",
          },
          {
            label: "散剂",
            value: "c22",
          },
          {
            label: "油类",
            value: "c23",
          },
          {
            label: "滴剂",
            value: "c24",
          },
          {
            label: "敷包类",
            value: "c25",
          },
        ],
      },
      {
        label: "外用方法",
        value: "c3",
        children: [
          {
            label: "手法类",
            value: "c31",
          },
          {
            label: "拔罐类",
            value: "c32",
          },
          {
            label: "特殊器具类",
            value: "c33",
          },
          {
            label: "其他",
            value: "c34",
          },
        ],
      },
    ],
  },
  {
    label: "中药类",
    value: "d",
    children: [
      {
        label: "地标产品",
        value: "d1",
        children: [{ label: "地标产品", value: "d11" }],
      },
      {
        label: "中药炮制技艺",
        value: "d2",
        children: [{ label: "中药炮制技艺", value: "d21" }],
      },
      {
        label: "其他",
        value: "d3",
        children: [{ label: "其他", value: "d31" }],
      },
    ],
  },
  {
    label: "养生、调理类",
    value: "e",
    children: [
      {
        label: "养生、调理类",
        value: "e1",
        children: [{ label: "养生、调理类", value: "e11" }],
      },
    ],
  },
  {
    label: "其他类",
    value: "f",
    children: [
      {
        label: "其他类",
        value: "f1",
        children: [{ label: "其他类", value: "f11" }],
      },
    ],
  },
];

export const LiveDataNewTypes =
  SYS_TYPE === "LIVEDATA"
    ? [
        {
          label:
            "中医药传统理论知识，包括生命与健康理论、疾病与防治理论、药物与方剂理论等",
          value: "1",
        },
        {
          label: "传统养生保健、疾病预防知识和方法",
          value: "2",
        },
        {
          label: "传统诊疗技术",
          value: "3",
        },
        {
          label: "传统单验方和制剂",
          value: "4",
        },
        {
          label: "中药材的传统采集、栽培、贮存、加工炮制、鉴别等知识和方法",
          value: "5",
        },
        {
          label: "中医药特有的传统标记、符号、图像和文字等",
          value: "6",
        },
        {
          label: "珍稀、濒危保护动植物及代用品中的中药繁育、配方及生产技术",
          value: "8",
        },
        {
          label:
            "国家名老中医及获省部级以上科技进步一、二等奖的疾病诊疗系统的医理设计及有效方药",
          value: "9",
        },
        {
          label: "其他需要保护的中医药传统知识",
          value: "7",
        },
      ]
    : [
        {
          label: "非药物诊疗技术",
          value: "1",
        },
        {
          label: "外用药物疗法",
          value: "2",
        },
      ];
/**
 * 事件类型
 */
// export const LivedataEvents = {
//   SUBMIT: "SUBMIT",
//   ASSIGN: "ASSIGN",
//   AUDIT: "AUDIT",
//   REJECT: "REJECT",
//   PUBLISH: "PUBLISH",
//   TURN_BACK: "TURN_BACK",
// };

export const LivedataEvents = {
  SUBMIT: "SUBMIT", // 提交审核
  TURN_BACK: "TURN_BACK", // 打回修改
  PROVINCE_EXPERT_AUDIT: "PROVINCE_EXPERT_AUDIT", // 省级专家意见
  PROVINCE_APPROVE: "PROVINCE_APPROVE", // 省级管理员通过
  PROVINCE_REJECT: "PROVINCE_REJECT", // 省级管理员不通过
  NATIONAL_EXPERT_AUDIT: "NATIONAL_EXPERT_AUDIT", // 国家级专家意见
  APPROVE: "APPROVE", // 终级管理员通过
  REJECT: "REJECT", // 终级管理员不通过
  PUBLISH: "PUBLISH", // 发布
  UNPUBLISH: "UNPUBLISH", // 撤销发布
  CERTIFICATE: "CERTIFICATE", // 颁发证书
};

/**
 * 活态数据状态
 */
export const LivedataStates = {
  DRAFT: "DRAFT", //填写中
  INIT: "INIT", // 申报人已提交，省级管理员审核中
  PROVINCE_EXPERT_AUDITED: "PROVINCE_EXPERT_AUDITED", // 省级专家意见提交
  REVIEW_APPROVED: "REVIEW_APPROVED", // 省管理员已通过，终级管理员审核中
  REVIEW_REJECTED: "REVIEW_REJECTED", // 省管理员未通过，终级管理员审核中
  NATIONAL_EXPERT_AUDITED: "NATIONAL_EXPERT_AUDITED", // 国家级专家意见提交
  APPROVED: "APPROVED", // 终级管理员审核通过
  REJECTED: "REJECTED", // 终级管理员审核不通过
  RETURNED: "RETURNED", // 打回修改
  PUBLISHED: "PUBLISHED", // 已发布
};

// 新申报项目包括的状态
export const NewLiveDataStates = [
  LivedataStates.DRAFT,
  LivedataStates.RETURNED,
];

// 申报中项目包括的状态
export const ReviewingLiveDataStates = [
  LivedataStates.INIT,
  LivedataStates.REVIEW_APPROVED,
  LivedataStates.REVIEW_REJECTED,
  LivedataStates.PROVINCE_EXPERT_AUDITED,
  LivedataStates.NATIONAL_EXPERT_AUDITED,
];

// 已完成申报项目包括的状态
export const FinishedLiveDataStates = [
  LivedataStates.REJECTED,
  LivedataStates.APPROVED,
  LivedataStates.PUBLISHED,
];

export const ReviewLivedataStates = [
  "INIT", // 申报人已提交，省级管理员审核中
  "PROVINCE_EXPERT_AUDITED", // 省级专家意见提交
  "REVIEW_APPROVED", // 省管理员已通过，终级管理员审核中
  "REVIEW_REJECTED", // 省管理员未通过，终级管理员审核中
  "NATIONAL_EXPERT_AUDITED", // 国家级专家意见提交
  "APPROVED", // 终级管理员审核通过
  "REJECTED", // 终级管理员审核不通过
  "RETURNED", // 打回修改
  "PUBLISHED", // 已发布
];

export const LivedataStatesI18N = {
  DRAFT: "填写中",
  INIT: "省级审核中",
  PROVINCE_EXPERT_AUDITED: "省级专家意见提交",
  REVIEW_APPROVED: "省级通过，国家级审核中",
  REVIEW_REJECTED: "省级未通过，国家级审核中",
  NATIONAL_EXPERT_AUDITED: "国家级专家意见提交",
  APPROVED: "终审通过, 暂存",
  REJECTED: "终审不通过",
  RETURNED: "打回修改",
  PUBLISHED: "终审通过, 已发布",
};

/**
 * 活态数据Tag
 */
export const LivedataStatesTagColor = {
  DRAFT: "#14C2C3",
  INIT: "rgba(20, 194, 195, 0.509803921568627)",
  REVIEW_APPROVED: "rgba(47, 193, 90, 0.541176470588235)",
  REVIEW_REJECTED: "#EC808D",
  NATIONAL_EXPERT_AUDITED: "#EC808D",
  REJECTED: "#E96060",
  RETURNED: "#F59A23",
  APPROVED: "#0000FF",
  PUBLISHED: "#00A854",
};

export const LivedataReviewTypes = {
  EDIT: "EDIT",
  MANAGE: "MANAGE",
  CHECK: "CHECK",
  AUDIT: "AUDIT",
};

export const AuditValue = {
  PASS: "PASS",
  REJECT: "REJECT",
};

export const FormItemLayout = {
  labelCol: { xs: 12, sm: 3 },
  wrapperCol: { xs: 12, sm: 21 },
};

export const InnerFormItemLayout = {
  labelCol: { xs: 6, sm: 6 },
  wrapperCol: { xs: 6, sm: 18 },
};

export const InputMessage = "请填写";

export const LivedataCategory = [
  {
    title: "单验方",
    value: 1,
  },
  {
    title: "传统制剂方法",
    value: 2,
  },
  {
    title: "中药炮制技艺",
    value: 3,
  },
  {
    title: "养生方法",
    value: 4,
  },
  {
    title: "传统诊疗技术",
    value: 5,
  },
  {
    title: "其他",
    value: 6,
  },
];

export const PROVINCE = [
  "安徽",
  "北京",
  "重庆",
  "福建",
  "甘肃",
  "广东",
  "广西",
  "贵州",
  "海南省",
  "河北",
  "黑龙江",
  "河南",
  "香港",
  "湖北",
  "湖南",
  "江苏",
  "江西",
  "吉林",
  "辽宁",
  "澳门",
  "内蒙古",
  "宁夏",
  "青海-中医",
  "青海-藏医",
  "山东",
  "上海",
  "山西",
  "陕西",
  "四川",
  "台湾",
  "天津",
  "新疆",
  "西藏",
  "云南",
  "浙江",
  "其他国家和地区",
];

// 筛选
export const FilterFormOptions = {
  type: {
    label: "项目类型：",
    formType: "Type",
    initialValue: "type_like",
  },
  newState: {
    label: "状态",
    formType: "Select",
    data: NewLiveDataStates.map(state => ({
      value: state,
      title: LivedataStatesI18N[state],
    })),
    initialValue: "state",
  },
  finishedState: {
    label: "状态",
    formType: "Select",
    data: FinishedLiveDataStates.map(state => ({
      value: state,
      title: LivedataStatesI18N[state],
    })),
    initialValue: "state",
  },
  reviewingState: {
    label: "状态",
    formType: "Select",
    data: ReviewingLiveDataStates.map(state => ({
      value: state,
      title: LivedataStatesI18N[state],
    })),
    initialValue: "state",
  },
  title: {
    label: "项目名称：",
    formType: "Text",
    initialValue: "title_like",
  },
  district: {
    label: "地区选择：",
    formType: "Select",
    data: PROVINCE.map(item => ({
      value: item,
      title: item,
    })),
    initialValue: "district_like",
  },
  owner: {
    label: "申报人：",
    formType: "Text",
    initialValue: "owner_like",
  },
  id: {
    label: "项目编号：",
    formType: "Text",
    initialValue: "no_like",
  },
  publishedAt: {
    label: "发布日期：",
    formType: "DateRange",
    initialValue: "publishedAt_gt",
  },
  submittedAt: {
    label: "提交日期：",
    formType: "DateRange",
    initialValue: "submittedAt_gt",
  },
};

// 审核管理 Tabs
export const ReviewTabs = [
  {
    role: ROLES.ADMIN,
    tabs: [
      LivedataStates.DRAFT,
      LivedataStates.INIT,
      LivedataStates.REVIEWING,
      LivedataStates.RETURNED,
      LivedataStates.REJECTED,
      LivedataStates.PUBLISHED,
    ],
  },
  {
    role: ROLES.UPLOAD,
    tabs: [
      LivedataStates.DRAFT,
      LivedataStates.RETURNED,
      LivedataStates.INIT,
      LivedataStates.REVIEWING,
      LivedataStates.REJECTED,
      LivedataStates.PUBLISHED,
    ],
  },
  {
    role: ROLES.UPLOADWITHOUTDATA,
    tabs: [
      LivedataStates.DRAFT,
      LivedataStates.RETURNED,
      LivedataStates.INIT,
      LivedataStates.REVIEWING,
      LivedataStates.REJECTED,
      LivedataStates.PUBLISHED,
    ],
  },
];

export const FileArray = [
  "caseAttach",
  "idImage",
  "doctorAttach",
  "degreeAttach",
  "awardAttach",
  "medicineAttach",
  "brandAttach",
  "patentAttach",
  "bookAttach",
  "paperAttach",
  "productAttach",
  "applianceAttach",
  "sampleAttach",
  "projectAttach",
  "companyAttach",
  "heritageAttach",
  "inheriteAttach",
  "homeaddressAttach",
  "companyaddressAttach",
  "expertCommentAttach",
  "honestyAttach",
  "owerCertificateAttach",
  "techCertificateAttach",
  "jobAttach",
  "applyAttach",
  "inheritAttach",
  "applyRuleAttach",
  "certiAttach",
  "otherAttach",
  "positionAttach",
  "otherUserAttach",
  "hostAttach",
  "pexpertCommentAttach",
  "pexpertCommentHonestyAttach",
  "nexpertCommentAttach",
  "nexpertCommentHonestyAttach",
  "idPersonImage",
  "informedAttach",

  // treatment
  "skinIrritationAttach",
  "hasCorrosiveDrugAttach",
  "adverseReactionsAttach",
  "isAdverseReactionsRecoverableAttach",
  "contraindicationsAttach",
  "otherSafeInstructionAttach",
  "relatedCasesAttach",
  "feedbackAttach",
  "researchEvidenceAttach",
  "patientRecheckAttach",
  "otherEffectivenessAttach",
  "educationAttach",
  "trainingAttach",
  "trainingTimeAttach",
  "treatmentCostAttach",
  "treatmentTimeCostAttach",
  "otherAccessibilityAttach",
  "implementationTimeAttach",
  "implementationAreaAttach",
  "hasOperatingSpecificationsAttach",
  "researchMaterialsAttach",
  "researchTopicsAttach",
  "relatedRewardsAttach",
  "clinicsScaleAttach",
  "releatedProductsAttach",
  "otherMaturityAttach",
  "technologicalInnovationAttach",
  "effectAdvantageAttach",
  "securityAdvantageAttach",
  "economyAdvantageAttach",
  "otherAdvantageAttach",
];

export const DetailfilterKeys = [
  "id",
  "no",
  "step",
  "type",
  "title",
  "livedata",
  "owner",
  "state",
  "events",
  "district",
  "submittedAt",
  "reviewedAt",
  "approvedAt",
  "updatedAt",
  "createdAt",
  "createdBy",
  "auditedAt",
  "returnedAt",
  "rejectedAt",
  "publishedAt",
  "unpublishedAt",
];

export const Comment = [
  {
    title: "建议发布名录",
    value: "建议发布名录",
  },
  {
    title: "建议入库",
    value: "建议入库",
  },
  {
    title: "不入库",
    value: "不入库",
  },
];

export const WaterMarkText = "国家中医药管理局中医药传统知识保护中心";
