import { schema } from "normalizr";

export const userSchema = new schema.Entity("users");
export const livedataEventSchema = new schema.Entity("livedataEvents", {
  createdBy: userSchema,
});
export const livedataSchema = new schema.Entity("livedata", {
  events: [livedataEventSchema],
});

export const extraEventSchema = new schema.Entity("extraEvents", {
  createdBy: userSchema,
});
export const extraSchema = new schema.Entity("extra", {
  events: [extraEventSchema],
});
