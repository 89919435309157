import React from "react";
import { NavLink, withRouter } from "react-router-dom";
import { Menu, Icon, Dropdown, Modal, Form, Input, message } from "antd";
import { get, find } from "lodash";
import styled from "styled-components";
import { withSession } from "@36node/redux-session";
// import moment from "moment";
import qs from "qs";
import { createForm } from "@36node/redux-antd";

import { connect } from "react-redux";
import { auth, core } from "../actions/api";
import { MENU } from "../constants";
import { TITLE, SYS_TYPE } from "../config";
import { getUserRole } from "../lib";

const { TextArea } = Input;

const logout = auth.makeLogout("session");
const createFeedback = core.feedback.makeCreateFeedback("feedback.create");

const renderMenu = (pathname, role) => {
  const menu = find(MENU, { role: role });
  if (!menu) {
    return null;
  }
  return menu.data.map(item => (
    <Menu.Item
      key={item.path}
      className={pathname.indexOf(item.key) !== -1 && "active"}
    >
      <NavLink
        to={
          item.defaultSearch
            ? `${item.path}?${qs.stringify(item.defaultSearch)}`
            : item.path
        }
      >
        <div className="menu-item">
          {item.icon && <Icon theme={item.theme} type={item.icon} />}
          {item.title}
        </div>
        <div className="bottom-line"></div>
      </NavLink>
    </Menu.Item>
  ));
};

// const weekday = [
//   "星期日",
//   "星期一",
//   "星期二",
//   "星期三",
//   "星期四",
//   "星期五",
//   "星期六",
// ];

let lastOperationTime = new Date().getTime();
let opCurrTime = new Date().getTime();
// 设置超时时间：30分钟
let opTimeOut = 1000 * 60 * 30;
@withRouter
@withSession("session")
@createForm("feedback")
@connect((state, props) => {
  const user = get(props.session, "result.user") || {};
  return {
    user,
  };
})
export default class Nav extends React.Component {
  get role() {
    return getUserRole(this.props);
  }

  state = { visible: false };

  handleLogout = () => {
    this.props.dispatch(
      logout({
        sessionId: get(this.props.session, "result.id"),
      })
    );
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = e => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.props.dispatch(
          createFeedback({
            body: values,
          })
        );
        message.success("反馈成功");
        this.setState({
          visible: false,
        });
      }
    });
  };

  handleCancel = e => {
    this.setState({
      visible: false,
    });
  };

  autoLogout = () => {
    this.interval = setInterval(() => {
      opCurrTime = new Date().getTime();
      if (opCurrTime - lastOperationTime > opTimeOut) {
        console.log("timeout");
        this.handleLogout();
      }
    }, 10000);
  };

  componentDidMount() {
    this.autoLogout();
    document.addEventListener(
      "click",
      () => {
        lastOperationTime = new Date().getTime();
      },
      true
    );
  }

  componentWillUnmount() {
    document.removeEventListener(
      "click",
      () => {
        lastOperationTime = new Date().getTime();
      },
      true
    );
    clearInterval(this.interval);
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { pathname } = this.props.location;
    const { user } = this.props;
    const userDropDown = (
      <Menu>
        <Menu.Item>
          <NavLink to="/profile">个人资料</NavLink>
        </Menu.Item>
        <Menu.Item onClick={this.showModal}>问题反馈</Menu.Item>
        <Menu.Item onClick={this.handleLogout}>退出登录</Menu.Item>
      </Menu>
    );
    return (
      <Content>
        <div className="left">
          <NavLink to="/">
            {SYS_TYPE === "LIVEDATA" && (
              <img src="/images/logo.png" alt="" className="logo" />
            )}
            <div className="title">
              {TITLE}
              {SYS_TYPE === "LIVEDATA" && <span>TCM Live Database System</span>}
            </div>
          </NavLink>
          <StyledMenu theme="dark" mode="horizontal">
            {renderMenu(pathname, this.role)}
          </StyledMenu>
        </div>
        <div className="right">
          {/* <div>
            <Icon type="calendar" />
            {ymd(moment())}&nbsp;|&nbsp;{weekday[moment().day()]}
          </div> */}
          <div className="user">
            <Icon type="user" />
            <Dropdown overlay={userDropDown}>
              <div className="dropdown-link">
                {user.name || ""} <Icon type="down" />
              </div>
            </Dropdown>
          </div>
        </div>
        <Modal
          title="问题反馈"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          okText="提交"
        >
          <Form>
            <Form.Item>
              {getFieldDecorator("text", {
                rules: [
                  {
                    required: true,
                    message: "请输入反馈内容!",
                  },
                ],
              })(<TextArea rows={5} placeholder="请输入" />)}
            </Form.Item>
          </Form>
        </Modal>
      </Content>
    );
  }
}

const Content = styled.div`
  line-height: 64px;
  width: 100%;
  display: flex;
  justify-content: space-between;

  .left {
    font-size: 22px;
    color: #fff;
    display: flex;

    .logo {
      height: 40px;
      margin-right: 12px;
    }

    .title {
      display: inline-block;
      vertical-align: middle;
      line-height: 1;
      color: white;
      font-size: 20px;

      span {
        display: block;
        font-size: 16px;
        margin-top: 3px;
      }
    }
  }

  .right {
    display: flex;
    color: white;
    font-size: 16px;

    > div {
      display: flex;

      i {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .dropdown-link {
      color: white;
      cursor: pointer;
      font-size: 16px;
    }

    .anticon {
      margin-right: 12px;
    }

    .user {
      margin-left: 24px;
    }
  }
`;

const StyledMenu = styled(Menu)`
  background-color: #1d76de;
  line-height: 64px;
  font-size: 16px;
  margin-left: 24px;

  .ant-menu-item {
    &:hover {
      background: #1d61de;
    }
  }

  .active {
    background: #1d61de !important;
  }

  .ant-menu-item > a {
    color: white;
  }
`;
