import React, { PureComponent } from "react";
import { Timeline, Card } from "antd";
import styled from "styled-components";
import { find } from "lodash";

import FileRender from "../../components/file-render";
import i18n from "../../i18n";
import { ymdhms } from "../../lib";

export default class LivedataHistory extends PureComponent {
  renderExpertName(value) {
    const tmp = [];
    value &&
      value.split(",").forEach(item => {
        const user = find(this.props.users, { id: item });
        if (user) {
          tmp.push(user.name);
        }
      });
    return tmp.join(",");
  }

  render() {
    const { data } = this.props;

    if (data.events && data.events.length > 0) {
      return (
        <StyledCard title="审核历史" style={{ margin: "24px 0" }}>
          <Timeline>
            {data.events.map(item => {
              return (
                <Timeline.Item key={item.id}>
                  <p>{`${ymdhms(item.updatedAt)}    ${item.createdBy &&
                    (item.createdBy.name || "")}    ${
                    i18n.LivedataEventsI18N[item.name]
                  } `}</p>
                  {item.comment && <p>{item.comment}</p>}
                  {item.file &&
                    item.file.map(item => {
                      return (
                        <FileRender showPreview={true} file={item} key={item} />
                      );
                    })}
                </Timeline.Item>
              );
            })}
          </Timeline>
        </StyledCard>
      );
    }
    return null;
  }
}

const StyledCard = styled(Card)`
  .detail-file {
    margin-bottom: 12px;
  }

  .detail-image {
    display: block;
    margin-bottom: 12px;
    max-height: 400px;
    cursor: pointer;
  }
`;
