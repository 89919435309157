export default {
  /**
   * 一些页面文字常量
   */
  ROLESI18N: {
    ADMIN: "管理员",
    EXPERT: "专家",
    USER: "浏览用户",
    UPLOAD: "上传用户（可查看名录库）",
    UPLOADWITHOUTDATA: "上传用户（不可查看名录库）",
  },

  LivedataStatesI18N: {
    DRAFT: "草稿箱",
    INIT: "待分配专家",
    PROVINCE_EXPERT_AUDITED: "省级专家意见提交",
    REVIEW_APPROVED: "省管理员已通过，终极管理员审核中",
    REVIEW_REJECTED: "省管理员未通过，终极管理员审核中",
    NATIONAL_EXPERT_AUDITED: "国家级专家意见提交",
    APPROVED: "终极管理员审核通过",
    REJECTED: "终极管理员审核不通过",
    PUBLISHED: "已发布",
    RETURNED: "打回修改",
  },

  LivedataEventsI18N: {
    // SUBMIT: "提交审核",
    // ASSIGN: "分配专家",
    // AUDIT: "专家意见",
    // REJECT: "拒绝",
    // PUBLISH: "发布",
    // TURN_BACK: "打回修改",
    SUBMIT: "提交审核", // 提交审核
    TURN_BACK: "打回修改", // 打回修改
    PROVINCE_EXPERT_AUDIT: "省级专家意见", // 省级专家意见
    PROVINCE_APPROVE: "省级管理员通过", // 省级管理员通过
    PROVINCE_REJECT: "省级管理员不通过", // 省级管理员不通过
    NATIONAL_EXPERT_AUDIT: "国家级专家意见", // 国家级专家意见
    APPROVE: "终极管理员通过", // 终极管理员通过
    REJECT: "终极管理员不通过", // 终极管理员不通过
    PUBLISH: "发布", // 发布
    UNPUBLISH: "撤销发布", // 撤销发布
    CERTIFICATE: "颁发证书", // 颁发证书
  },

  AuditValueI18N: {
    PASS: "评审通过",
    REJECT: "评审不通过",
  },
};
