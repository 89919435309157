import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { Card, Button, message, Select } from "antd";
import { call, select } from "redux-saga/effects";
import { makeApiSelector, withSaga, tapOnLatest } from "@36node/redux";
import { history, withSession } from "@36node/redux-session";
import { debounce } from "lodash";

import { getUser } from "../../../lib";
import { core } from "../../../actions/api";
import { CORE_LIVE_CREATE_LIVE } from "../../../actions/types";
import { LiveDataNewTypes } from "../../../constants";

import Container from "../../../components/layout/container";

const { Option } = Select;

const createLive = core.livedata.makeCreateLivedata("live.create");
const createLiveSelector = makeApiSelector("live.create");

@withSession("session")
@withSaga(
  tapOnLatest(CORE_LIVE_CREATE_LIVE.SUCCESS, "live.create", function*(action) {
    const livedata = yield select(createLiveSelector);
    if (livedata.result)
      yield call(
        history.push,
        `/apply/project/edit/${livedata.result.id}?isNew=true`
      );
  })
)
@connect(state => ({}))
export default class extends React.PureComponent {
  state = {
    value: null,
    loading: false,
  };

  onHadleSelect = type => {
    // 暂时只有验方的表单，只允许用户选择验方
    if (type === "a-a1-a11") {
      this.props.dispatch(
        createLive({
          body: {
            type,
            createdBy: getUser(this.props).id,
          },
        })
      );
    } else {
      message.warning("请选择验方");
    }
  };

  onSub = debounce(() => {
    const { value } = this.state;

    if (value) {
      this.setState({
        loading: true,
      });
      this.props.dispatch(
        createLive({
          body: {
            type: value,
            createdBy: getUser(this.props).id,
          },
        })
      );
    } else {
      message.warning("请选择项目类型");
    }
  }, 500);

  handleChange = value => {
    this.setState({
      value,
    });
  };

  render() {
    return (
      <StyledContainer>
        <Card title="创建申报项目">
          {/* {LiveDataTypes.map(cat1 => (
            <div className="upload-type-block" key={cat1.value}>
              <div className="upload-type-title">{cat1.label}</div>
              {cat1.children &&
                cat1.children.map(cat2 => (
                  <div key={cat2.value}>
                    <span className="upload-type-subtitle">{cat2.label}</span>
                    {cat2.children &&
                      cat2.children.map(cat3 => (
                        <Button
                          className="upload-type-item"
                          type="primary"
                          key={cat3.value}
                          onClick={() => {
                            this.onHadleSelect(
                              `${cat1.value}-${cat2.value}-${cat3.value}`
                            );
                          }}
                        >
                          {cat3.label}
                        </Button>
                      ))}
                  </div>
                ))}
            </div>
          ))} */}
          <div className="type-main">
            <Select
              onChange={this.handleChange}
              style={{ width: 650 }}
              placeholder="请选择项目类型"
            >
              {LiveDataNewTypes.map(x => (
                <Option value={x.value}>{x.label}</Option>
              ))}
            </Select>
            <div>
              <Button
                type="primary"
                onClick={this.onSub}
                loading={this.state.loading}
                disabled={this.state.loading}
              >
                创建申报项目
              </Button>
            </div>
          </div>
        </Card>
      </StyledContainer>
    );
  }
}
const StyledContainer = styled(Container)`
  .ant-card {
    width: 100%;
  }

  .type-main {
    text-align: center;

    .ant-btn {
      margin-top: 24px;
    }
  }

  .ant-card-head-title {
    font-size: 18px;
  }

  .upload-type-title {
    font-size: 17px;
    font-weight: 500;
    padding-bottom: 12px;
    margin-bottom: 24px;
    border-bottom: 1px solid #e8e8e8;
    color: rgba(0, 0, 0, 0.85);
  }

  .upload-type-subtitle {
    font-size: 16px;
    display: inline-block;
    margin-right: 24px;
  }

  .upload-type-item {
    margin: 0 24px 24px 0;
  }
`;
