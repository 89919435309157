/**
 * 持续修改编辑页
 */

import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Button, Spin, Modal, Steps } from "antd";
import { find, get, isEqual, omit } from "lodash";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import {
  makeApiSelector,
  makeAssign,
  makeAssignSelector,
  withSaga,
  tapOnLatest,
} from "@36node/redux";
import { withSession, history } from "@36node/redux-session";
import { call, select, put } from "redux-saga/effects";
import { message } from "antd";

import { core } from "../../../actions/api";
import Container from "../../../components/layout/container";
import InfoForm from "../project/edit/form-info";
import UserForm from "../project/edit/form-user";
import EvaluationForm from "../project/edit/form-evaluation";
import HostForm from "../project/edit/form-host";
import { DisabledFields } from "./common";
import { SYS_TYPE } from "../../../config";
import { FileArray, LivedataEvents, UploadMenu } from "../../../constants";
import {
  CORE_LIVE_CREATE_EXTRAEVENT,
  CORE_LIVE_SHOW_EXTRA,
  CORE_LIVE_UPDATE_EXTRA,
} from "../../../actions/types";
import EditLeavingGuard from "../../../components/edit-leaving-guard";
import { includeArr } from "../../../lib";

const calStepStatus = (finishedSteps = [], currentStep, step) => {
  if (finishedSteps.indexOf(step) >= 0) {
    return "finish";
  }
  if (currentStep === step) {
    return "process";
  }
  return "wait";
};

const getStep = formKey => find(UploadMenu, { key: formKey }).step;
const allSteps = [getStep("info"), getStep("user"), getStep("host")];
let maxStep = 2;
if (SYS_TYPE === "TREATMENT") {
  allSteps.push(getStep("evaluation"));
  maxStep = 3;
}

const showExtra = core.extra.makeShowExtra("apply.add.edit");
const updateExtra = core.extra.makeUpdateExtra("apply.add.edit");
const createExtraEvent = core.extra.makeCreateExtraEvent(
  "apply.add.createExtraEvent"
);
const extraSelector = makeApiSelector("apply.add.edit");
const createExtraEventSelector = makeApiSelector("apply.add.createExtraEvent");
const setAddstep = makeAssign("addstep");
const addstepSelector = makeAssignSelector("addstep", {});
const sessionSelector = makeApiSelector("session");

@withRouter
@withSession("session")
@withSaga(
  tapOnLatest(CORE_LIVE_UPDATE_EXTRA.SUCCESS, "apply.add.edit", function*(
    action
  ) {
    message.success("保存成功");

    // 表单保存后，step + 1, 或者回到第 0 个
    const steps = yield select(addstepSelector);
    const id = get(action, "payload.result");
    const pre = steps[id] || 0;
    yield put(
      setAddstep({
        ...steps,
        [id]: pre + 1 > maxStep ? 0 : pre + 1,
      })
    );

    const extraState = yield select(extraSelector);
    const session = yield select(sessionSelector);
    const extra = extraState.result || {};
    const userId = get(session, "result.user.id");

    // 如果是最后一步提交审核
    if (pre === maxStep && includeArr(extra.step, allSteps)) {
      yield put(
        createExtraEvent({
          extraId: id,
          body: {
            name: LivedataEvents.SUBMIT,
            createdBy: userId,
          },
        })
      );
    }
  }),
  tapOnLatest(CORE_LIVE_SHOW_EXTRA.SUCCESS, "apply.add.edit", function*(
    action
  ) {
    // 获取数据后，根据情况更新当前 step 为初始状态
    const id = get(action, "payload.result");
    const steps = yield select(addstepSelector);
    const pre = steps[id];
    if (pre === undefined) {
      yield put(
        setAddstep({
          ...steps,
          [id]: 0,
        })
      );
    }
  }),
  tapOnLatest(
    CORE_LIVE_CREATE_EXTRAEVENT.SUCCESS,
    "apply.add.createExtraEvent",
    function*() {
      yield call(history.push, `/apply/add/list`, { noBlock: true });
    }
  )
)
@connect((state, props) => ({
  steps: addstepSelector(state, props),
  data: extraSelector(state).result || {},
  loading: extraSelector(state).loading || false,
  createEventLoading: createExtraEventSelector(state).loading || false,
  user: get(props.session, "result.user") || {},
}))
export default class AddEdit extends PureComponent {
  handleBack = () => {
    const { history } = this.props;
    history.go(-1);
  };

  get id() {
    const { match } = this.props;
    return get(match, "params.id");
  }

  get step() {
    return get(this.props.steps, this.id) || 0;
  }

  componentDidMount() {
    this.props.dispatch(showExtra({ extraId: this.id }));
  }

  onStepChange = value => {
    const { dispatch } = this.props;

    if (this.form && this.form.isDirty()) {
      Modal.confirm({
        title: "表单未保存",
        content: "当前表单的修改未提交，是否放弃修改？",
        okText: "确认",
        okType: "danger",
        cancelText: "取消",
        onOk() {
          dispatch(setAddstep({ [this.id]: value }));
        },
        onCancel() {},
      });
    } else {
      dispatch(setAddstep({ [this.id]: value }));
    }
  };

  stepBack = () => {
    this.props.dispatch(setAddstep({ [this.id]: this.step - 1 }));
  };

  saveDraft = values => {
    const { data } = this.props;
    let tmpData = { ...values };

    delete tmpData.id;

    const steps = new Set(data.step);
    steps.add(tmpData.step);
    tmpData.step = [...steps];

    FileArray.forEach(item => {
      if (values[item] && values[item].length > 0)
        tmpData[item] = values[item].map(c => c.url);
    });

    let result = { ...tmpData };
    Object.keys(tmpData).forEach(key => {
      if (isEqual(tmpData[key], data[key])) {
        delete result[key];
      }
    });

    this.props.dispatch(
      updateExtra({ extraId: this.id, body: omit(tmpData, DisabledFields) })
    );
  };

  render() {
    const { data, loading, createEventLoading, history } = this.props;

    const { title } = data;

    return (
      <StyledContainer>
        <div className="title">
          <Button
            icon="left"
            type="link"
            size="large"
            onClick={this.handleBack}
            className="back-button"
          >
            返回
          </Button>
          {`${title}-持续添加`}
        </div>

        <Spin spinning={loading || createEventLoading}>
          <div className="forms">
            <div className="step">
              <Steps
                direction="vertical"
                size="small"
                current={this.step}
                onChange={this.onStepChange}
              >
                {UploadMenu.filter(item => item.step !== undefined).map(
                  (u, index) => (
                    <Steps.Step
                      title={u.title}
                      key={u.key}
                      status={calStepStatus(data.step, this.step, index)}
                      className={this.step === index ? "active" : ""}
                    />
                  )
                )}
              </Steps>
            </div>
            {this.step === getStep("info") && (
              <InfoForm
                noRequire
                data={data}
                loading={loading}
                id="form-info"
                saveDraft={this.saveDraft}
                disabledFields={DisabledFields}
                formRef={form => {
                  this.form = form;
                }}
              />
            )}
            {this.step === getStep("user") && (
              <UserForm
                noRequire
                data={data}
                loading={loading}
                id="form-user"
                saveDraft={this.saveDraft}
                stepBack={this.stepBack}
                disabledFields={DisabledFields}
                formRef={form => {
                  this.form = form;
                }}
              />
            )}
            {this.step === getStep("evaluation") && (
              <EvaluationForm
                noRequire
                data={data}
                loading={loading}
                id="form-evaluation"
                saveDraft={this.saveDraft}
                stepBack={this.stepBack}
                disabledFields={DisabledFields}
                formRef={form => {
                  this.form = form;
                }}
              />
            )}
            {this.step === getStep("host") && (
              <HostForm
                data={data}
                loading={loading}
                id="form-host"
                saveDraft={this.saveDraft}
                stepBack={this.stepBack}
                formRef={form => {
                  this.form = form;
                }}
              />
            )}
          </div>
        </Spin>

        <EditLeavingGuard
          navigate={path => history.push(path)}
          shouldBlockNavigation={location => {
            if (location.state && location.state.noBlock) {
              return false;
            }

            if (this.form && this.form.isDirty()) {
              return true;
            }

            return false;
          }}
        />
      </StyledContainer>
    );
  }
}

const StyledContainer = styled(Container)`
  .title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 24px;
    text-align: center;
    color: rgba(0, 0, 0, 0.85);
    position: relative;

    .ant-btn {
      position: absolute;
      top: 0;
      left: 0;
      padding: 0;
    }
  }

  .forms {
    position: relative;

    .step {
      padding: 18px 0 0 18px;
      position: absolute;
      top: 0px;
      right: -200px;
      z-index: 999;

      .active {
        .ant-steps-item-title {
          color: rgba(0, 0, 0, 0.85);
        }

        .ant-steps-item-icon {
          background: #1d76de;
        }

        .ant-steps-icon {
          color: #fff;
        }
      }
    }

    .box {
      margin-bottom: 24px;
      .form-check {
        color: #1890ff;
        cursor: pointer;

        &:hover {
          color: #40a9ff;
        }
      }
    }

    .tips {
      margin-bottom: 24px;
    }

    .btns {
      text-align: center;
      margin-top: 48px;
      margin-bottom: 36px;
    }
  }
`;
