import React, { PureComponent } from "react";

import styled from "styled-components";
import FileRender from "../file-render";

const Box = styled.div`
  display: flex;
  flex-direction: ${props => (props.layout === "vertical" ? "column" : "row")};
  align-items: ${props =>
    props.layout === "vertical" ? "flex-start" : "center"};

  .file-item:not(:first-child) {
    ${props =>
      props.layout === "vertical" ? "margin-top: 24px;" : "margin-left: 24px;"}
  }
`;

export default class CertificateFiles extends PureComponent {
  renderFile = (item, key) => {
    const { imgWidth, showPreview = false } = this.props;

    if (item) {
      if (
        item.indexOf(".jpg") !== -1 ||
        item.indexOf(".jpeg") !== -1 ||
        item.indexOf(".png") !== -1
      ) {
        return (
          <div key={key} style={{ width: imgWidth }} className="file-item">
            <FileRender
              showPreview={showPreview}
              imageStyle={{ width: "100%", height: "auto" }}
              file={item}
            />
          </div>
        );
      } else {
        return (
          <div className="file-item" key={key}>
            <FileRender showPreview={showPreview} file={item} />
          </div>
        );
      }
    }
  };

  render() {
    const {
      files = [],
      imgWidth = 300,
      layout = "vertical",
      style,
    } = this.props;
    return (
      <Box imgWidth={imgWidth} layout={layout} style={style}>
        {files.map((f, index) => {
          return this.renderFile(f, index);
        })}
      </Box>
    );
  }
}
