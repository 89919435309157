export const DisabledFields = [
  "title",
  "type",
  "category",
  "district",
  "no",
  "owner",
  "birthday",
  "birthplace",
  "idNumber",
];
