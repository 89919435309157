import React from "react";
import styled from "styled-components";
import { Layout, Form, Input, Icon, Button, message, Alert } from "antd";
import { createForm } from "@36node/redux-antd";
import { withSession } from "@36node/redux-session";
import { withSaga, tapOnLatest } from "@36node/redux";
import Center from "../../components/layout/center";
import { user } from "../../actions/api";
import { connect } from "react-redux";
import { get } from "lodash";
import { AUTH_USER_UPDATE_USER } from "../../actions/types";
const FormItem = Form.Item;

const updateUser = user.makeUpdateUser("user.update.password");

@createForm("change-password")
@withSession("session")
@connect((state, props) => {
  const user = get(props.session, "result.user") || {};
  return {
    user,
  };
})
@withSaga(
  tapOnLatest(AUTH_USER_UPDATE_USER.SUCCESS, "user.update.password", function(
    action
  ) {
    message.success("成功更新密码");
    window.location.replace("/");
  })
)
export default class ChangePassword extends React.PureComponent {
  handleSubmit = e => {
    e.preventDefault();
    const { user, form } = this.props;
    form.validateFields((err, values) => {
      if (!err) {
        let dateDay = new Date();
        this.props.dispatch(
          updateUser({
            userId: user.id,
            body: {
              ...values,
              expireAt: new Date(dateDay.getTime() + 90 * 24 * 60 * 60 * 1000),
            },
          })
        );
      }
    });
  };
  confirmPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue("password")) {
      callback("两次输入密码不同!");
    } else {
      callback();
    }
  };
  render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    return (
      <ChangePasswordPage>
        <Center>
          <ChangePasswordBox>
            <Alert
              message="密码已超过90天，请重新设置"
              type="error"
              banner
              style={{ marginBottom: "28px" }}
            />
            <Form
              layout="vertical"
              onSubmit={this.handleSubmit}
              className="login-form"
            >
              <FormItem className="source-password" style={{ width: "400px" }}>
                {getFieldDecorator("password", {
                  validateTrigger: "onBlur",
                  rules: [
                    {
                      required: true,
                      pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{8,16}$/,
                      message:
                        "密码需至少8个字符，至少1个大写字母，1个小写字母，和1个数字",
                    },
                  ],
                })(
                  <Input
                    prefix={<Icon type="lock" />}
                    type="password"
                    placeholder="新密码"
                  />
                )}
              </FormItem>
              <FormItem
                className="new-password-again"
                style={{ width: "400px" }}
              >
                {getFieldDecorator("passwordAgain", {
                  validateTrigger: "onBlur",
                  rules: [
                    {
                      required: true,
                      message: "两次密码不一致",
                    },
                    {
                      validator: this.confirmPassword,
                    },
                  ],
                })(
                  <Input
                    prefix={<Icon type="lock" />}
                    type="password"
                    placeholder="新密码确认"
                  />
                )}
              </FormItem>
              <FormItem className="submit">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                >
                  更新密码
                </Button>
              </FormItem>
            </Form>
          </ChangePasswordBox>
        </Center>
      </ChangePasswordPage>
    );
  }
}

const ChangePasswordPage = styled(Layout)`
  background: url("/images/login-bg.jpg") no-repeat;
  background-size: cover;
  background-position: center;

  .login-footer {
    position: absolute;
    bottom: 24px;
    width: 100%;
    text-align: center;
    color: rgba(255, 255, 255, 0.8);
    font-size: 14px;
  }
`;

const ChangePasswordBox = styled("div")`
  background-color: white;
  padding: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -100px;
`;
