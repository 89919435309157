/**
 * init dotenv
 *
 * .env: Default.
 * .env.local: Local overrides. This file is loaded for all environments except test.
 * .env.development, .env.test, .env.production: Environment-specific settings.
 * .env.development.local, .env.test.local, .env.production.local: Local overrides of environment-specific settings.
 *
 * Available settings
 *
 * APP_PORT=9527
 * APP_BASE_PATH=/v1
 * APP_JWT_PUBLIC_KEY=`a public key string`
 */

/**
 *
 * @param {string} name envrionment name
 * @param {string} init default value
 * @returns {string} value
 */
function env(name, init) {
  const key = `REACT_APP_${name.toUpperCase()}`;
  const buildtimeValue = process?.env?.[key];

  const runtimeValue = window?._runtime_?.[key]; // eslint-disable-line
  const value = runtimeValue || buildtimeValue || init;

  return value;
}

export const VERSION = env("VERSION", "0.0.0");
export const ROOT_NS = env("ROOT_NS", "/catcm_uat");
export const TITLE = env("TITLE", "活态中医药传统知识数据库系统");
export const SYS_TYPE = env("SYS_TYPE", "LIVEDATA"); // LIVEDATA, TREATMENT

export const AUTH_BASE = env(
  "AUTH_BASE",
  "https://api.stargate.36node.com/auth/v0"
);
export const AUTH_PROVIDER = env("AUTH_PROVIDER", "61c174bbc4a169001220e7b9");
export const CORE_BASE = env(
  "CORE_BASE",
  "https://api.catcm-uat.36node.com/livedata/core/v0"
);

export const OSS_REGION = env("REGION", "oss-cn-beijing");
export const OSS_ACCESS_KEY_ID = env(
  "ACCESS_KEY_ID",
  "LTAI4FrtwgtjrNMULgidegmm"
);
export const OSS_ACCESS_KEY_SECRET = env(
  "ACCESS_KEY_SECRET",
  "VeQTPpAffxUy5zqoYNueBb4xcGDEXe"
);
export const OSS_BUCKET = env("OSS_BUCKET", "36node-upload-test");
export const OSS_BASE = {
  region: OSS_REGION,
  accessKeyId: OSS_ACCESS_KEY_ID,
  bucket: OSS_BUCKET,
  accessKeySecret: OSS_ACCESS_KEY_SECRET,
};

export const MAX_FILE_SIZE = parseInt(env("MAX_FILE_SIZE", "1048576"), 10);
export const MAX_IMAGE_SIZE = parseInt(env("MAX_IMAGE_SIZE", "10240"), 10);

export const TOKEN = "token";

/* eslint-disable */
console.log(`VERSION:${VERSION}`);
console.log(`ROOT_NS:${ROOT_NS}`);
console.log(`TITLE:${TITLE}`);
console.log(`SYS_TYPE:${SYS_TYPE}`);
console.log(`AUTH_BASE:${AUTH_BASE}`);
console.log(`CORE_BASE:${CORE_BASE}`);

/* eslint-disable */
