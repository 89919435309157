import React, { PureComponent } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { get } from "lodash";
import { withSession } from "@36node/redux-session";

import { TOKEN } from "../config";

function isAuth(user) {
  if (new Date(user.expireAt) < new Date()) {
    return "CHANGE_PASSWORD";
  } else if (!!sessionStorage.getItem(TOKEN)) {
    return "HAVE_TOKEN";
  } else return "NOTOKEN";
}

@withSession("session")
@connect((state, props) => {
  const user = get(props.session, "result.user") || {};
  return {
    user,
  };
})
export default class ProtectedRoute extends PureComponent {
  render() {
    const { component: Component, redirect, user, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={props => {
          const result = isAuth(user);
          if (result === "NOTOKEN") {
            return (
              <Redirect
                to={{
                  pathname: redirect,
                  state: { from: props.location },
                }}
              />
            );
          } else if (result === "CHANGE_PASSWORD") {
            return (
              <Redirect
                to={{
                  pathname: "/change-password",
                  state: { from: props.location },
                }}
              />
            );
          }
          return <Component {...props} />;
        }}
      />
    );
  }
}
