import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { Button, message, Modal, Steps, Typography } from "antd";
import { find, get, isEqual } from "lodash";
import {
  makeApiSelector,
  makeAssign,
  makeAssignSelector,
  withSaga,
  tapOnLatest,
} from "@36node/redux";
import { withSession, history } from "@36node/redux-session";
import { call, put, select } from "redux-saga/effects";

import {
  CORE_LIVE_UPDATE_LIVE,
  CORE_LIVE_SHOW_LIVE,
} from "../../../../actions/types";
import { core } from "../../../../actions/api";
import { LivedataEvents, FileArray, UploadMenu } from "../../../../constants";
import Container from "../../../../components/layout/container";
import LivedataHistory from "../../../../components/history";
import { getSearch } from "../../../../lib/history";

import InfoForm from "./form-info";
import UserForm from "./form-user";
// import HonestyForm from "./form-honesty";
import EvaluationForm from "./form-evaluation";
import HostForm from "./form-host";
// import CompanyForm from "./form-company";
import EditLeavingGuard from "../../../../components/edit-leaving-guard";
import { SYS_TYPE } from "../../../../config";
import { includeArr } from "../../../../lib";

const showLive = core.livedata.makeShowLivedata("livedata.new");
const updateLive = core.livedata.makeUpdateLivedata("livedata.new");
const createLiveEvent = core.livedata.makeCreateLiveEvent("liveEvent.create");
const listUsersSelector = makeApiSelector("users");
const sessionSelector = makeApiSelector("session");
const newLiveSelector = makeApiSelector("livedata.new");
const setLivestep = makeAssign("livestep");
const livestepSelector = makeAssignSelector("livestep", {});

const { Paragraph, Text } = Typography;

const calStepStatus = (finishedSteps = [], currentStep, step) => {
  if (finishedSteps.indexOf(step) >= 0) {
    return "finish";
  }
  if (currentStep === step) {
    return "process";
  }
  return "wait";
};

const getStep = formKey => find(UploadMenu, { key: formKey }).step;
const allSteps = [getStep("info"), getStep("user"), getStep("host")];
let maxStep = 2;
if (SYS_TYPE === "TREATMENT") {
  allSteps.push(getStep("evaluation"));
  maxStep = 3;
}

@withRouter
@withSaga(
  tapOnLatest(CORE_LIVE_UPDATE_LIVE.SUCCESS, "livedata.new", function*(action) {
    message.success("保存成功");

    // 表单保存后，step + 1, 或者回到第 0 个
    const livestep = yield select(livestepSelector);
    const id = get(action, "payload.result");
    const pre = livestep[id] || 0;
    yield put(
      setLivestep({
        ...livestep,
        [id]: pre + 1 > maxStep ? 0 : pre + 1,
      })
    );

    const livedataState = yield select(newLiveSelector);
    const session = yield select(sessionSelector);
    const livedata = livedataState.result || {};
    const userId = get(session, "result.user.id");

    // 如果是最后一步提交审核
    if (pre === maxStep && includeArr(livedata.step, allSteps)) {
      yield put(
        createLiveEvent({
          livedataId: id,
          body: {
            name: LivedataEvents.SUBMIT,
            createdBy: userId,
          },
        })
      );
      yield call(history.push, "/apply/project/success", { noBlock: true });
    }
  }),
  tapOnLatest(CORE_LIVE_SHOW_LIVE.SUCCESS, "livedata.new", function*(action) {
    // 获取数据后，根据情况更新当前 step 为初始状态
    const id = get(action, "payload.result");
    const livestep = yield select(livestepSelector);
    const pre = livestep[id];
    if (pre === undefined) {
      yield put(
        setLivestep({
          ...livestep,
          [id]: 0,
        })
      );
    }
  }),
  tapOnLatest(CORE_LIVE_UPDATE_LIVE.FAILURE, "livedata.new", function(action) {
    message.error("保存失败");
  })
)
@withSession("session")
@connect((state, props) => ({
  livestep: livestepSelector(state),
  loading: newLiveSelector(state).loading,
  data: newLiveSelector(state).result || {},
  user: get(props.session, "result.user") || {},
  users: listUsersSelector(state).result || [],
}))
export default class extends React.PureComponent {
  get id() {
    const { match } = this.props;
    return get(match, "params.id");
  }

  get step() {
    const { match } = this.props;
    const id = get(match, "params.id");
    return this.props.livestep[id] || 0;
  }

  state = {
    inform: false,
  };

  componentDidMount() {
    this.props.dispatch(showLive({ livedataId: this.id }));

    const query = getSearch(this.props.location);

    if (query.isNew) {
      this.setState({
        inform: true,
      });
    }
  }

  saveDraft = values => {
    const { data } = this.props;
    let tmpData = { ...values };

    delete tmpData.id;

    const steps = new Set(data.step);
    steps.add(tmpData.step);
    tmpData.step = [...steps];

    FileArray.forEach(item => {
      if (values[item] && values[item].length > 0)
        tmpData[item] = values[item].map(c => c.url);
    });

    let result = { ...tmpData };
    Object.keys(tmpData).forEach(key => {
      if (isEqual(tmpData[key], data[key])) {
        delete result[key];
      }
    });

    this.props.dispatch(
      updateLive({
        livedataId: this.id,
        body: result,
      })
    );
  };

  handleBack = () => {
    const { history } = this.props;
    history.go(-1);
  };

  onStepChange = value => {
    const { dispatch } = this.props;
    const id = this.id;

    if (this.form && this.form.isDirty()) {
      Modal.confirm({
        title: "表单未保存",
        content: "当前表单的修改未提交，是否放弃修改？",
        okText: "确认",
        okType: "danger",
        cancelText: "取消",
        onOk() {
          dispatch(setLivestep({ [id]: value }));
        },
        onCancel() {},
      });
    } else {
      dispatch(setLivestep({ [id]: value }));
    }
  };

  stepBack = () => {
    this.props.dispatch(setLivestep({ [this.id]: this.step - 1 }));
  };

  render() {
    const { loading, data, user, users } = this.props;

    let containerWidth = window.innerWidth - 400;

    if (containerWidth > 1000) {
      containerWidth = 1000;
    } else if (containerWidth < 800) {
      containerWidth = 800;
    }

    return (
      <StyledContainer
        style={{
          width: containerWidth,
          minWidth: containerWidth,
        }}
      >
        <div className="title">
          <Button
            icon="left"
            type="link"
            size="large"
            onClick={this.handleBack}
          >
            返回
          </Button>
          项目申请
        </div>
        <div className="forms">
          <div className="step">
            <Steps
              direction="vertical"
              size="small"
              current={this.step}
              onChange={this.onStepChange}
            >
              {UploadMenu.filter(item => item.step !== undefined).map(
                (u, index) => (
                  <Steps.Step
                    title={u.title}
                    key={u.key}
                    status={calStepStatus(data.step, this.step, index)}
                    className={this.step === index ? "active" : ""}
                  />
                )
              )}
            </Steps>
          </div>
          {this.step === getStep("info") && (
            <InfoForm
              data={data}
              loading={loading}
              user={user}
              id="form-info"
              saveDraft={this.saveDraft}
              formRef={form => {
                this.form = form;
              }}
            />
          )}
          {this.step === getStep("user") && (
            <UserForm
              data={data}
              loading={loading}
              id="form-user"
              saveDraft={this.saveDraft}
              stepBack={this.stepBack}
              formRef={form => {
                this.form = form;
              }}
            />
          )}
          {this.step === getStep("evaluation") && (
            <EvaluationForm
              data={data}
              loading={loading}
              id="form-evaluation"
              saveDraft={this.saveDraft}
              stepBack={this.stepBack}
              formRef={form => {
                this.form = form;
              }}
            />
          )}
          {this.step === getStep("host") && (
            <HostForm
              data={data}
              loading={loading}
              id="form-host"
              saveDraft={this.saveDraft}
              stepBack={this.stepBack}
              formRef={form => {
                this.form = form;
              }}
            />
          )}
          {/* <Element name="form4">
            <CompanyForm
              data={data}
              id="form-host"
              saveDraft={this.saveDraft}
              formRef={form => {
                this.companyForm = form;
              }}
            />
          </Element> */}
          {/* <Element name="form5">
            <HonestyForm
              data={data}
              id="form-company"
              saveDraft={this.saveDraft}
              formRef={form => {
                this.honestyForm = form;
              }}
            />
          </Element> */}
        </div>

        {data.events && data.events.length > 0 && (
          <LivedataHistory data={data} users={users} />
        )}

        <EditLeavingGuard
          navigate={path => history.push(path)}
          shouldBlockNavigation={location => {
            if (location.state && location.state.noBlock) {
              return false;
            }

            if (this.form && this.form.isDirty()) {
              return true;
            }

            return false;
          }}
        />
        <Modal
          title="知情同意书"
          visible={SYS_TYPE === "LIVEDATA" ? this.state.inform : false}
          closable={false}
          footer={[
            <Button
              key="submit"
              type="primary"
              onClick={() => {
                this.setState({ inform: false });
              }}
            >
              同意
            </Button>,
          ]}
        >
          <Paragraph style={{ textIndent: "32px" }}>
            <Text>
              根据《中华人民共和国中医药法》《中医药传统知识保护条例（草案）》和国家中医药管理局科技司《国家中医药管理局科技司关于做好中医药传统知识收集整理工作的通知》（国中医药科技中药便函〔2019〕174号）等相关法律法规及文件，国家中医药管理局中医药传统知识保护研究中心委托各省（自治区、直辖市）相关部门进行中医药传统知识收集工作，并使用包括但不限于文字、图像、音频、视频的方式收集项目申报人（单位）所申报的项目。
            </Text>
          </Paragraph>
          <Paragraph style={{ textIndent: "32px" }}>
            <Text>
              经省级和国家级专家评审，符合相关技术标准和要求的，按照相关评审程序，认定为中医药传统知识保护项目，登记入库并颁发相应证书。有代表性的，列入保护名录。认定入库的项目，方受《中华人民共和国中医药法》保护，其申报人（单位）享有相应的传承使用、知情同意和惠益分享等权利。项目内容涉及技术机密的，申报人（单位）可选择不申报或部分申报，因申报内容缺失而导致的项目认定失败，后果由申报人（单位）承担。
            </Text>
          </Paragraph>
          <Paragraph style={{ textIndent: "32px" }}>
            <Text>
              本人（单位）已知悉以上内容并承诺如实申报所持有的项目，如弄虚作假，违反《国家中医药传统知识保护项目申报诚信承诺书》，愿取消申报资格并承担相应后果。
            </Text>
          </Paragraph>
        </Modal>
      </StyledContainer>
    );
  }
}

const StyledContainer = styled(Container)`
  .title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 24px;
    text-align: center;
    color: rgba(0, 0, 0, 0.85);
    position: relative;

    .ant-btn {
      position: absolute;
      top: 0;
      left: 0;
      padding: 0;
    }
  }

  .forms {
    position: relative;

    .step {
      padding: 18px 0 0 18px;
      position: absolute;
      top: 0px;
      right: -200px;
      z-index: 999;

      .active {
        .ant-steps-item-title {
          color: rgba(0, 0, 0, 0.85);
        }

        .ant-steps-item-icon {
          background: #1d76de;
        }

        .ant-steps-icon {
          color: #fff;
        }
      }
    }

    .box {
      margin-bottom: 24px;
      .form-check {
        color: #1890ff;
        cursor: pointer;

        &:hover {
          color: #40a9ff;
        }
      }
    }

    .tips {
      margin-bottom: 24px;
    }

    .btns {
      text-align: center;
      margin-top: 48px;
      margin-bottom: 36px;
    }
  }
`;
