import styled from "styled-components";

const Container = styled.div`
  width: 90%;
  max-width: 1200px;
  min-width: 980px;
  margin: 0 auto;
  z-index: 1;
  min-height: calc(100vh - 160px);
`;

/** @component */
export default Container;
