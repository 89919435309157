import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Modal, Spin, Form, Select, message } from "antd";
import { makeApiSelector, withSaga, tapOnLatest } from "@36node/redux";
import { createForm } from "@36node/redux-antd";
import { history } from "@36node/redux-session";
import { withSession } from "@36node/redux-session";
import { get, pick } from "lodash";
import { call } from "redux-saga/effects";

import { DisabledFields } from "./common";
import { core } from "../../../actions/api";
import { LivedataStates } from "../../../constants";
import { CORE_LIVE_CREATE_EXTRA } from "../../../actions/types";

const { Option } = Select;

const listLivedata = core.livedata.makeListLivedata("apply.add.new.list");
const listExtra = core.extra.makeListExtra("apply.add.new.listextra", {
  query: {
    select: ["livedata", "state"],
  },
});

const listLivedataSelector = makeApiSelector("apply.add.new.list");
const listExtraSelector = makeApiSelector("apply.add.new.listextra");
const createExtra = core.extra.makeCreateExtra("apply.add.new.extra");

@withSession("session")
@withSaga(
  tapOnLatest(CORE_LIVE_CREATE_EXTRA.SUCCESS, "apply.add.new.extra", function*(
    action
  ) {
    message.success("创建持续添加成功");
    const id = get(action, "payload.result");
    yield call(history.push, `/apply/add/edit/${id}?isNew=true`);
  })
)
@createForm("apply.add.new.form")
@connect((state, props) => {
  const extras = listExtraSelector(state).result || [];
  const livedatas = listLivedataSelector(state).result || [];

  const loading =
    listExtraSelector(state).loading || listLivedataSelector(state).loading;

  // 对于已有未发布extra的livedata，则在选择列表中不显示
  const filtered = livedatas.filter(
    l =>
      !Boolean(
        extras.find(
          e =>
            e.livedata === l.id &&
            (e.state !== LivedataStates.PUBLISHED ||
              e.state !== LivedataStates.REJECTED)
        )
      )
  );

  return {
    livedatas: filtered,
    loading,
    user: get(props.session, "result.user") || {},
  };
})
export default class extends PureComponent {
  componentDidMount() {
    this.props.dispatch(
      listLivedata({
        query: {
          limit: 10000,
          select: [...DisabledFields, "coUser"],
          filter: {
            state: [
              LivedataStates.PUBLISHED,
              LivedataStates.APPROVED,
              LivedataStates.REVIEW_APPROVED,
              LivedataStates.NATIONAL_EXPERT_AUDITED,
            ],
            createdBy: this.props.user.id,
          },
        },
      })
    );

    // 获取用户下所有的持续添加
    this.props.dispatch(
      listExtra({
        query: {
          limit: 10000,
          select: ["livedata", "state"],
          filter: {
            createdBy: this.props.user.id,
          },
        },
      })
    );
  }

  handleOk = () => {
    const livedataId = this.props.form.getFieldValue("livedataId");
    const livedata = this.props.livedatas.find(item => item.id === livedataId);
    if (!livedata) {
      message.error("请选择要持续添加的项目");
      return;
    }

    const body = pick(livedata, [...DisabledFields, "coUser"]);
    body.createdBy = this.props.user.id;
    body.livedataId = livedata.id;

    this.props.dispatch(
      createExtra({
        body,
      })
    );
  };

  handleCancel = () => {
    const { location, history } = this.props;
    const background = location.state && location.state.background;
    const goBack = location.state && location.state.goBack;

    if (goBack) return history.goBack();

    if (!background) return history.push("/");
    history.push(background);
  };

  render() {
    const { loading, livedatas, form, location } = this.props;
    const { getFieldDecorator } = form;
    const livedataId = location.state && location.state.livedataId;

    return (
      <Modal
        title="创建持续添加"
        width={600}
        visible={true}
        onCancel={this.handleCancel}
        onOk={this.handleOk}
        okText="创建持续添加"
      >
        {loading && <Spin />}
        {!loading && (
          <Form {...FormItemLayout}>
            <Form.Item label="已审核项目">
              {getFieldDecorator("livedataId", {
                initialValue: livedataId,
              })(
                <Select placeholder="请选择需要持续添加的项目" allowClear>
                  {livedatas.map(item => (
                    <Option key={item.id} value={item.id}>
                      {item.title}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Form>
        )}
      </Modal>
    );
  }
}

export const FormItemLayout = {
  labelCol: { xs: 12, sm: 6 },
  wrapperCol: { xs: 12, sm: 18 },
};
